import { apply, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux-ts-simple';
import {
  clearRestaurant,
  getItem,
  getMenu,
  getRestaurant,
  loadMenu,
  loadRestaurant,
  saveItem,
} from './actions';
import { SagaIterator } from 'redux-saga';
import { inject, injectable } from 'inversify';
import { TypesSymbols } from '../../typesSymbols';
import { startAction, stopAction } from '../loader/actions';
import { addError } from '../errors/actions';
import { IAdminMenuService } from '../../types/IAdminMenuService';
import { parseStringToHours } from '../../utils/helper-functions/helperFunctions';
import { IRestaurantService } from '../../types/IRestaurantsService';

@injectable()
export class PagesSaga {
  sagas: SagaIterator[] = [
    this._watchHandleGetMenuItem(),
    this._watchHandleGetMenu(),
    this._watchHandleGetRestaurant(),
  ];

  @inject(TypesSymbols.IAdminMenuService)
  private menuService!: IAdminMenuService;

  @inject(TypesSymbols.IRestaurantService)
  private restaurantService!: IRestaurantService;

  *_handleGetMenuItem(
    action: Action<{ menuId: string; menuItemId: string }>,
  ): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      console.log(' get restaurant saga:', payload);
      const { data } = yield apply(
        this.menuService,
        this.menuService.getMenuItem,
        [payload.menuItemId, payload.menuId],
      );
      console.log(' get menu item saga:', data);
      yield put(saveItem(data));
    } catch (error) {
      yield put(
        addError({
          code: 'UNEXPECTED_ERROR',
          message: 'UnexpectedError',
        }),
      );
      console.error('get menu item saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleGetMenu(action: Action<{ restaurantId: string }>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.menuService,
        this.menuService.getMenuByRestaurant,
        [payload.restaurantId],
      );
      console.log(' get menu saga:', data);
      yield put(loadMenu(data));
    } catch (error) {
      yield put(
        addError({
          code: 'UNEXPECTED_ERROR',
          message: 'UnexpectedError',
        }),
      );
      console.error(' user get menu saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleGetRestaurant(
    action: Action<{ restaurantId: string; city: string }>,
  ): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.restaurantService,
        this.restaurantService.getRestaurantById,
        [payload.restaurantId, payload.city],
      );
      console.log(' get restaurant saga:', data);
      const result = {
        ...data,
        hours: parseStringToHours(data.hours),
      };
      yield put(loadRestaurant(result));
    } catch (error) {
      yield put(
        addError({
          code: 'UNEXPECTED_ERROR',
          message: 'UnexpectedError',
        }),
      );
      console.error('get restaurant saga error:', error);
      yield put(clearRestaurant());
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_watchHandleGetMenu(): SagaIterator {
    yield takeEvery(getMenu.type, this._handleGetMenu.bind(this));
  }

  *_watchHandleGetRestaurant(): SagaIterator {
    yield takeEvery(getRestaurant.type, this._handleGetRestaurant.bind(this));
  }

  *_watchHandleGetMenuItem(): SagaIterator {
    yield takeEvery(getItem.type, this._handleGetMenuItem.bind(this));
  }
}
