import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ApplicationState } from '../../store';

export const AuthRoute = ({ children, ...rest }: RouteProps): JSX.Element => {
  const { email, isAdmin } = useSelector((appState: ApplicationState) => ({
    email: appState.restaurantUser.email ?? appState.user.email,
    isAdmin: !!appState.restaurantUser.email,
  }));

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !email ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: isAdmin ? '/admin/home' : '/home',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
