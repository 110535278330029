/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError, Resolver } from 'react-hook-form';
import validator from 'validator';
import i18n from '../../../../i18n';
import { RestaurantAccountInfo } from '../../../../types/RestaurantAccountInfo';
import {
  validate,
  Validation,
} from '../../../../utils/rhf/validationFunctions';

export const validateEmail = (email: string): FieldError | undefined => {
  if (validate(email, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
  if (!validator.isEmail(email))
    return {
      type: 'invalidEmailFormat',
      message: i18n.t('RestaurantSignUp.errorMessages.invalidEmailFormat'),
    };
  return undefined;
};

export const validatePassword = (password: string): FieldError | undefined => {
  if (validate(password, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
  if (
    !validator.isStrongPassword(password, { minSymbols: 0 }) ||
    !validate(password, Validation.containsSymbol)
  )
    return {
      type: 'weakPassword',
      message: i18n.t('RestaurantSignUp.errorMessages.weakPassword'),
    };
  return undefined;
};

export const validateConfirmPassword = (
  confirmPassword: string,
  password: string,
): FieldError | undefined => {
  if (validate(confirmPassword, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
  if (confirmPassword !== password)
    return {
      type: 'passwordsDontMatch',
      message: i18n.t('RestaurantSignUp.errorMessages.passwordsDontMatch'),
    };
  return undefined;
};

type RestAccountInfoKeys = keyof RestaurantAccountInfo;
export type RestaurantContactErrors = {
  [key in RestAccountInfoKeys]?: FieldError;
};

export const resolver: Resolver<RestaurantAccountInfo, any> = async (
  values: RestaurantAccountInfo,
) => {
  const errors: RestaurantContactErrors = {};
  errors.ownerEmail = validateEmail(values.ownerEmail);
  errors.password = validatePassword(values.password);
  errors.confirmPassword = validateConfirmPassword(
    values.confirmPassword,
    values.password,
  );

  const result = {
    values: {},
    errors: {},
  };

  if (errors.confirmPassword || errors.ownerEmail || errors.password)
    result.errors = errors;
  else result.values = values;

  return new Promise((resolve) => {
    resolve(result);
  });
};
