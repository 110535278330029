import { DevTool } from '@hookform/devtools';
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Fab,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../../../App';
import { MenuItem } from '../../../types/MenuItem';
import { ComboBoxRender } from '../../../utils/rhf/Controllers';
import { OptionListView } from '../option-lists-container/OptionListView';
import { menuSections } from '../../../constants';
import { Option } from '../../../types/Option';
import { AutoCompleteProps } from '../../../types/AutoCompleteProps';
import { OptionsList } from '../../../types/OptionsList';
import {
  getOptionSelected,
  renderInput,
  renderOption,
} from '../../../utils/components/AutoCompleteUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearMenuItemForm,
  getMenu,
  getRestaurant,
  sendDeleteMenuItem,
  sendMenuItem,
} from '../../../store/restaurant/actions';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from '../../../store';
import { MenuItemForm, MenuItemSubmitForm } from '../../../types/MenuItemForm';
import { ImageCardInput } from '../image-card-input/ImageCardInput';
import AlertDialog from '../alert-dialog/AlertDialog';
import { resolver } from './resolver';

const createOptionList = (): OptionsList => ({
  key: uuidv4(),
  isRequired: true,
  maxOptions: 1,
  minOptions: 1,
  name: '',
  options: [],
});

export const MenuItemView = (): JSX.Element => {
  const {
    menu: { id: menuId },
    menuItemForm,
  } = useSelector((appState: ApplicationState) => appState.restaurant);
  const dispatch = useDispatch();
  const history = useHistory();
  const methods = useForm<MenuItemForm>({
    mode: 'onBlur',
    resolver: resolver,
    defaultValues: {
      ...menuItemForm,
      optionLists: menuItemForm.optionLists.map((list) => list.value),
    },
  });
  const { restaurantCity, restaurantId } = useSelector(
    (appState: ApplicationState) => appState.restaurantUser,
  );

  const { control, register, handleSubmit, errors, trigger } = methods;
  const { fields, append, remove } = useFieldArray<OptionsList>({
    control,
    name: 'optionLists',
  });

  useEffect(() => {
    trigger(`optionLists`);
    console.log('errors:', errors);
  }, [trigger, fields]);

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [open, setOpen] = useState(false);
  const handleOpenDialog = () => setOpen(true);

  const handleCloseDialog = () => setOpen(false);

  const handleExpand = (panel: string) => (
    event: ChangeEvent<unknown>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { t } = useTranslation();
  const classes = useGlobalStyles();

  const sectionsOptions: Option[] = menuSections.map((value) => ({
    code: value,
    label: t(`MenuSections.${value}`) as string,
  }));

  const menuSectionProps: AutoCompleteProps<Option> = {
    id: 'state-select',
    options: sectionsOptions,
    classes: {
      option: classes.option,
    },
    defaultValue: sectionsOptions[0],
    autoHighlight: true,
    size: 'small',
    getOptionLabel: (option: Option) => option.label,
    renderOption,
    getOptionSelected,
    renderInput: renderInput(t('MenuItem.menuSection') as string),
  };

  const onSubmit = (data: MenuItemSubmitForm) => {
    console.log('item:', data, 'state', menuItemForm);
    const menuItem: MenuItem = {
      ...data,
      id: menuItemForm.id,
      minPrice: parseFloat(data.minPrice.toString()),
      menuSection: data.menuSection.code,
      optionsLists: data.optionLists?.map((list) => list.value) ?? [],
    };
    dispatch(sendMenuItem(menuItem));
  };

  const handleAddList = () => {
    const newList = createOptionList();
    append(newList);
    setExpanded(newList.key ?? '');
  };

  useEffect(() => {
    dispatch(
      getRestaurant({
        id: restaurantId ?? '',
        city: restaurantCity ?? '',
      }),
    );
    return () => {
      dispatch(clearMenuItemForm());
    };
  }, []);
  useEffect(() => {
    console.log('getting menu from rest:', restaurantId);
    if (restaurantId) dispatch(getMenu({ restaurantId }));
  }, [restaurantId]);
  const handleDeleteItem = () => {
    console.log('dispatching delete');
    const menuItem: MenuItem = {
      ...menuItemForm,
      id: menuItemForm.id,
      menuSection: menuItemForm.menuSection.code,
      optionsLists: menuItemForm.optionLists?.map((list) => list.value) ?? [],
    };
    if (menuItemForm.id)
      dispatch(
        sendDeleteMenuItem({
          menuItem,
          restaurantId: restaurantId ?? '',
          menuId: menuId ?? '',
          restaurantCity: restaurantCity ?? '',
        }),
      );
    else history.push('/admin/menu');
  };

  const src = 'https://loremflickr.com/1328/1328/restaurant,food';
  return (
    <Fragment>
      <FormProvider {...methods}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.container}
          component={'form'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            item
            xs={12}
            md={3}
          >
            <Typography variant="h4">{t('MenuItem.title')}</Typography>
            <ImageCardInput
              name="photo"
              control={control}
              defaultValue={''}
              src={src}
            />
            <Grid item container direction="column">
              <Grid item xs={12} className={classes.verticalSpacing1}>
                <ComboBoxRender
                  {...menuSectionProps}
                  controllerprops={{
                    control,
                    name: 'menuSection',
                    defaultValue: sectionsOptions[0],
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.verticalSpacing1}>
                <TextField
                  fullWidth
                  size="small"
                  id="name"
                  label={t('MenuItem.itemName')}
                  inputRef={register}
                  name="name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item xs={12} className={classes.verticalSpacing1}>
                <TextField
                  fullWidth
                  size="small"
                  id="description"
                  label={t('MenuItem.itemDescription')}
                  inputRef={register}
                  name="description"
                  multiline
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              </Grid>
              <Grid item xs={12} className={classes.verticalSpacing1}>
                <TextField
                  fullWidth
                  size="small"
                  id="minPrice"
                  variant="outlined"
                  name="minPrice"
                  inputProps={{ step: '0.01', min: 0 }}
                  label={t('MenuItem.minPrice')}
                  inputRef={register}
                  error={!!errors.minPrice}
                  helperText={errors.minPrice?.message}
                />
              </Grid>

              <Grid container item xs={12} className={classes.verticalSpacing1}>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name="inStock"
                    render={(props) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                              color="primary"
                            />
                          }
                          label={t('MenuItem.inStock')}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>

              {fields.map((field, index) => (
                <OptionListView
                  expanded={expanded === field.key}
                  handleExpand={handleExpand(field.key ?? '')}
                  key={field.id}
                  removeOptionList={remove}
                  currentIndex={index}
                  control={control}
                  defaultValue={field}
                  name={`optionLists[${index}].value`}
                />
              ))}
              <Grid item xs={12} className={classes.verticalSpacing1}>
                <Fab
                  size="small"
                  color="secondary"
                  aria-label="add list of options"
                  onClick={handleAddList}
                >
                  <Add />
                </Fab>
              </Grid>
              <Grid item container className={classes.verticalSpacing1}>
                <Grid item xs className={classes.horizontalSpacing1}>
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                  >
                    {t('Common.submitButton')}
                  </Button>
                </Grid>
                <Grid item xs className={classes.horizontalSpacing1}>
                  <Button
                    onClick={handleOpenDialog}
                    fullWidth
                    color="secondary"
                    variant="contained"
                  >
                    {t('Common.deleteButton')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <DevTool control={control} />
        </Grid>
      </FormProvider>
      <AlertDialog
        cancelText={t('Common.no') as string}
        confirmText={t('Common.yes') as string}
        title={t('MenuItem.deleteItem?') as string}
        content={t('MenuItem.deleteItemText') as string}
        open={open}
        onClose={handleCloseDialog}
        onCancel={handleCloseDialog}
        onConfirm={handleDeleteItem}
      />
    </Fragment>
  );
};
