import { OpenHours } from './OpenHours';

export enum Days {
  sun = 'sun',
  mon = 'mon',
  tue = 'tue',
  wed = 'wed',
  thu = 'thu',
  fri = 'fri',
  sat = 'sat',
}
export type EnumDays = keyof typeof Days;

export type WeekHours = {
  [key in EnumDays]: OpenHours[];
};

export const WEEK = [
  Days.sun,
  Days.mon,
  Days.tue,
  Days.wed,
  Days.thu,
  Days.fri,
  Days.sat,
];
