import { apply, put, takeEvery } from 'redux-saga/effects';
import {
  emailLogin,
  facebookLogin,
  googleLogin,
  userLogin,
  userSignUp,
} from './actions';
import { UserFormData } from '../../types/UserFormData';
import { Action } from 'redux-ts-simple';
import { LoginFormData } from '../../types/LoginFormData';
import { FBAuthResponse } from '../../types/FBAuthResponse';
import { SagaIterator } from 'redux-saga';
import { inject, injectable } from 'inversify';
import { TypesSymbols } from '../../typesSymbols';
import { IAuthService } from '../../types/IAuthService';
import { startAction, stopAction } from '../loader/actions';
import { push } from 'connected-react-router';
import { AxiosError } from 'axios';
import { addError } from '../errors/actions';
import { saveToken } from '../auth/actions';

@injectable()
export class UserAuthSaga {
  sagas: SagaIterator[] = [
    this.watchHandleEmailLogin(),
    this.watchHandleEmailSignUp(),
    this.watchHandleFacebookLogin(),
    this.watchHandleGoogleLogin(),
  ];

  @inject(TypesSymbols.IAuthService)
  private authService!: IAuthService;

  *handleEmailSignUp(action: Action<UserFormData>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.authService,
        this.authService.sendSignUpPayload,
        [{ ...payload, phoneCountry: `${payload.countryAreaCode?.dial}` }],
      );
      console.log('saga', data);
      yield put(saveToken(data.token));
      yield put(userLogin({ ...data.user }));
      yield put(push('/home'));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 409) {
        yield put(
          addError({ code: 'USER_EMAIL_CONFLICT', message: 'EmailConflict' }),
        );
        // set Wrong credentials error
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error('user email sign up saga error:', error.response);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *handleEmailLogin(action: Action<LoginFormData>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.authService,
        this.authService.sendEmailCredentials,
        [payload],
      );
      console.log('saga', data);
      yield put(saveToken(data.token));
      yield put(userLogin({ ...data.user }));
      yield put(push('/home'));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 400) {
        yield put(
          addError({ code: 'USER_WRONG_CREDS', message: 'BadCredentials' }),
        );
        // set Wrong credentials error
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error('user email login saga error:', error.response);

      // error state to be updated here when implemented.
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *handleGoogleLogin(action: Action<{ tokenId: string }>): SagaIterator {
    try {
      const {
        payload: { tokenId: token },
      } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.authService,
        this.authService.sendGoogleToken,
        [token],
      );
      console.log(data);
      yield put(saveToken(data.token));
      yield put(userLogin({ ...data.user }));
      yield put(push('/home'));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 400) {
        yield put(
          addError({ code: 'USER_WRONG_CREDS', message: 'BadCredentials' }),
        );
        // set Wrong credentials error
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error('google login saga error:', error.response);
      // error state to be updated here when implemented.
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *handleFacebookLogin(action: Action<FBAuthResponse>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.authService,
        this.authService.sendFacebookToken,
        [payload],
      );

      console.log(data);
      yield put(saveToken(data.token));
      yield put(userLogin({ ...data.user }));
      yield put(push('/home'));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 400) {
        yield put(
          addError({ code: 'USER_WRONG_CREDS', message: 'BadCredentials' }),
        );
        // set Wrong credentials error
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error('facebook login saga error:', error);
      // error state to be updated here when implemented.
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *watchHandleEmailSignUp(): SagaIterator {
    yield takeEvery(userSignUp.type, this.handleEmailSignUp.bind(this));
  }

  *watchHandleEmailLogin(): SagaIterator {
    yield takeEvery(emailLogin.type, this.handleEmailLogin.bind(this));
  }

  *watchHandleGoogleLogin(): SagaIterator {
    yield takeEvery(googleLogin.type, this.handleGoogleLogin.bind(this));
  }

  *watchHandleFacebookLogin(): SagaIterator {
    yield takeEvery(facebookLogin.type, this.handleFacebookLogin.bind(this));
  }
}
