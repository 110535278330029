import { createAction } from 'redux-ts-simple';
import { Menu } from '../../types/Menu';
import { Restaurant } from '../../types/Restaurant';
import {
  SAVE_ITEM,
  GET_ITEM,
  ItemViewState,
  GET_RESTAURANT,
  LOAD_RESTAURANT,
  CLEAR_RESTAURANT,
  LOAD_MENU,
  GET_MENU,
} from './types';

export const getItem = createAction<{
  menuId: string;
  menuItemId: string;
}>(GET_ITEM);

export const saveItem = createAction<ItemViewState>(SAVE_ITEM);

export const getRestaurant = createAction<{
  restaurantId: string;
  city: string;
}>(GET_RESTAURANT);

export const loadRestaurant = createAction<Restaurant>(LOAD_RESTAURANT);

export const clearRestaurant = createAction(CLEAR_RESTAURANT);

export const loadMenu = createAction<Menu>(LOAD_MENU);

export const getMenu = createAction<{ restaurantId: string }>(GET_MENU);
