import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../../../App';
import { DayHoursProps } from '../../../types/DayHoursProps';

const formatTime = (date?: Date | null, am?: string, pm?: string) => {
  if (!date) return;
  const amPm = date.getHours() > 11 ? pm : am;
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes() ? date.getMinutes() : '00';
  return `${hours}:${minutes} ${amPm}`;
};

export const DayHours = ({ day, openHours }: DayHoursProps): JSX.Element => {
  const classes = useGlobalStyles();
  const { t } = useTranslation();

  return (
    <Grid item container xs className={classes.verticalSpacing1}>
      <Grid item xs={3} className={classes.horizontalSpacing1}>
        <Typography variant="subtitle2">{day}</Typography>
      </Grid>
      <Grid item xs container direction="column">
        {openHours?.map((hours, index) => (
          <Grid key={`${day}-hours${index}`} item>
            <Typography variant="subtitle2">{`${formatTime(
              hours?.open,
              t('Common.am') as string,
              t('Common.pm') as string,
            )} - ${formatTime(
              hours?.close,
              t('Common.am') as string,
              t('Common.pm') as string,
            )}`}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
