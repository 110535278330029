import { Step, StepLabel, Stepper } from '@material-ui/core';
import React from 'react';
import { StepperProps } from '../../../types/StepperProps';

export const RegistrationStepper = ({
  steps,
  activeStep,
}: StepperProps): JSX.Element => {
  return (
    <Stepper alternativeLabel activeStep={activeStep}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
