import { apply, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux-ts-simple';
import {
  getCategoriesList,
  getRestaurantsByCategory,
  saveCategoriesList,
  saveRestaurantsByCategory,
} from './actions';
import { SagaIterator } from 'redux-saga';
import { inject, injectable } from 'inversify';
import { TypesSymbols } from '../../typesSymbols';
import { IRestaurantService } from '../../types/IRestaurantsService';
import { startAction, stopAction } from '../loader/actions';
import { addError } from '../errors/actions';
import { AxiosError } from 'axios';

@injectable()
export class UserHomeSaga {
  sagas: SagaIterator[] = [
    this._watchHandleGetCategoriesList(),
    this._watchHandleGetRestaurantsByCategory(),
  ];

  @inject(TypesSymbols.IRestaurantService)
  private restaurantService!: IRestaurantService;

  *_handleGetRestaurantsByCategory(
    action: Action<{ category: string; city: string }>,
  ): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      console.log(' get restaurant saga:', payload);
      const { data } = yield apply(
        this.restaurantService,
        this.restaurantService.getRestaurantsByCategoryCity,
        [payload.category ?? '', payload.city ?? ''],
      );
      console.log(' get restaurant saga:', data);
      yield put(
        saveRestaurantsByCategory({
          category: payload.category,
          restaurants: data,
        }),
      );
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else if (error.response?.status === 404)
        yield put(
          saveRestaurantsByCategory({
            category: action.payload.category,
            restaurants: [],
          }),
        );
      else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error('get restaurants saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleGetCategoriesList(action: Action<{ city: string }>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      console.log(' get restaurant saga:', payload);
      const { data } = yield apply(
        this.restaurantService,
        this.restaurantService.getCategoriesListByCity,
        [payload.city ?? ''],
      );
      console.log(' get categories List saga:', data);
      yield put(saveCategoriesList(data));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else if (error.response?.status === 404)
        yield put(
          yield put(
            saveCategoriesList({
              categories: [],
              city: action.payload.city,
              id: '',
            }),
          ),
        );
      else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error('get categories List saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_watchHandleGetRestaurantsByCategory(): SagaIterator {
    yield takeEvery(
      getRestaurantsByCategory.type,
      this._handleGetRestaurantsByCategory.bind(this),
    );
  }

  *_watchHandleGetCategoriesList(): SagaIterator {
    yield takeEvery(
      getCategoriesList.type,
      this._handleGetCategoriesList.bind(this),
    );
  }
}
