import { ReducerBuilder } from 'redux-ts-simple';
import { addError, clearError } from './actions';
import { ErrorsState } from './types';

export const initialLoaderState: ErrorsState = {
  errors: [],
  refreshing: [],
};

export const errorsReducer = new ReducerBuilder(initialLoaderState)
  .on(addError, (state, action) => ({
    ...state,
    errors: [...state.errors, action.payload],
  }))
  .on(clearError, (state, action) => ({
    ...state,
    errors: state.errors.filter((act) => act.code !== action.payload.code),
  }))
  .else((state) => state)
  .build();
