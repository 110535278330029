import { Container } from 'inversify';
import { AdminMenuService } from './services/admin-menu/AdminMenuMockService';
import { AuthService } from './services/AuthService';
import { RestaurantUserService } from './services/restaurant-user/RestaurantUserService';
import { RestaurantService } from './services/restaurants-service/RestaurantMockService';
import { TypesSymbols } from './typesSymbols';

const container = new Container();

container.bind(TypesSymbols.IAuthService).to(AuthService).inSingletonScope();
container
  .bind(TypesSymbols.IAdminMenuService)
  .to(AdminMenuService)
  .inSingletonScope();

container
  .bind(TypesSymbols.IRestaurantService)
  .to(RestaurantService)
  .inSingletonScope();

container
  .bind(TypesSymbols.IRestaurantUserService)
  .to(RestaurantUserService)
  .inSingletonScope();
export { container };
