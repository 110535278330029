import {
  FormHelperText,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useGlobalStyles } from '../../../App';
import { RestaurantScheduleInput } from './RestaurantScheduleInput';
import { Delete } from '@material-ui/icons';
import { StringMap } from 'i18next';
import { ScheduleErrors } from '../../../types/ScheduleErrors';
import { ValueMap } from '../../../types/ValueMap';
import { RestaurantProfileData } from '../../../types/RestaurantProfileData';
import { Days } from '../../../types/WeekHours';
import { ScheduleArrayElementProps } from '../../../types/ScheduleArrayElementProps';

export const getTypes = (
  currentIndex: number,
  errors?: StringMap,
): ScheduleErrors | undefined => {
  if (!errors) return undefined;
  const types = (errors as ValueMap<FieldError | undefined>)[
    `schedules[${currentIndex}].value`
  ]?.types as ScheduleErrors;
  if (types) return types;
  const schedules = errors?.schedules;
  if (!schedules) return undefined;
  return schedules[currentIndex]?.value?.types;
};

export function ScheduleArrayElement<T>({
  controllerProps,
  field,
  handleDelete,
  index,
  canDelete,
}: ScheduleArrayElementProps<T>): JSX.Element {
  const theme = useTheme();
  const classes = useGlobalStyles();
  const { errors: formErrors } = useFormContext<RestaurantProfileData>();

  const errors = getTypes(index, formErrors);

  return (
    <Grid
      item
      container
      xs={12}
      key={field.id}
      justify="flex-end"
      className={`${classes.verticalSpacing1}`}
      style={{
        padding: '12px',
        border: 'solid',
        borderRadius: '4px',
        borderWidth: '1px',
        borderColor: theme.palette.grey[400],
      }}
    >
      <Grid item container xs={12}>
        <Grid item xs>
          <Typography variant="h6" align="center">{`Schedule ${
            index + 1
          }`}</Typography>
        </Grid>
        {canDelete && (
          <Grid item>
            <IconButton onClick={handleDelete}>
              <Delete />
            </IconButton>
          </Grid>
        )}
        {errors && (
          <Grid item xs={12}>
            <FormHelperText
              error
              style={{ textAlign: 'center' }}
              component="div"
            >
              <Typography variant="subtitle1" align="center">
                {errors?.dayRequired}
              </Typography>
              {[
                Days.sun,
                Days.mon,
                Days.tue,
                Days.wed,
                Days.thu,
                Days.fri,
                Days.sat,
              ].map((day) => {
                return (
                  <Typography
                    key={`error-${day}`}
                    variant="subtitle1"
                    align="center"
                  >
                    {errors[`${day}Repeated`] ?? ''}
                  </Typography>
                );
              })}
            </FormHelperText>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <RestaurantScheduleInput
          controllerProps={controllerProps}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
}
