import { useTranslation } from 'react-i18next';
import { areaCodes } from '../../constants';
import { AreaCodeOption } from '../../types/AreaCodeOption';

export interface UseAreaCodesValues {
  areaCodeOptions: AreaCodeOption[];
}

export const useAreaCodes = (): UseAreaCodesValues => {
  const { t } = useTranslation();
  const areaCodeOptions: AreaCodeOption[] = areaCodes.map(({ code, dial }) => ({
    code,
    dial,
    label: t(`CountryAreaCodes.${code}`) as string,
  }));

  return { areaCodeOptions };
};
