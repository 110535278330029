import { ReducerBuilder } from 'redux-ts-simple';
import { clearToken, saveToken } from './actions';
import { AuthState } from './types';

export const initialUserState: AuthState = {
  token: '',
};

export const authReducer = new ReducerBuilder(initialUserState)
  .on(saveToken, (state, action) => ({ ...state, token: action.payload }))
  .on(clearToken, (state) => ({ ...state, token: '' }))
  .else((state) => state)
  .build();
