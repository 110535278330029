import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { useGlobalStyles } from '../../App';
import { ApplicationState, history } from '../../store';
import AdminHome from '../admin/home/AdminHome';
import RegistrationSubmission from '../admin/auth/RegistrationSubmission';
import RestaurantAuth from '../admin/auth/RestaurantAuth';
import { MenuItemView } from '../admin/menu-item/MenuItemView';
import Menu from '../admin/menu/Menu';
import Auth from '../guest/auth/Auth';
import { Home } from '../guest/home/Home';
import { RestaurantCategoryList } from '../guest/restaurant-category-list/RestaurantCategoryList';
import { RestaurantView } from '../guest/restaurant-view/RestaurantView';
import Header from '../header/Header';
import { ItemView } from '../guest/item-view/ItemView';
import { RestaurantProfile } from '../admin/restaurant-profile/RestaurantProfile';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Gallery } from '../admin/gallery/Gallery';
import { Unauthenticated } from '../unauthenticated/Unauthenticated';
import { ProtectedRoute } from '../protected-route/ProtectedRoute';
import { Unauthorized } from '../unauthorized/Unauthorized';
import { AuthRoute } from '../auth-route/AuthRoute';

export function Content(): JSX.Element {
  const isLoading: boolean = useSelector((appState: ApplicationState) => {
    return appState.loader.actions.length > 0;
  });
  const { isAdmin } = useSelector((appState: ApplicationState) => ({
    isAdmin: !!appState.restaurantUser.email,
  }));

  const classes = useGlobalStyles();
  return (
    <Router history={history}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Header />
        <Switch>
          <AuthRoute exact path={['/sign-up', '/sign-in']}>
            <Auth />
          </AuthRoute>
          <Route
            exact
            path={[
              '/restaurant-sign-up',
              '/restaurant-sign-up/*',
              '/restaurant-sign-in',
            ]}
          >
            <RestaurantAuth />
          </Route>

          <ProtectedRoute exact path="/home">
            <Home />
          </ProtectedRoute>
          <ProtectedRoute path="/categories/:city/:category">
            <RestaurantCategoryList />
          </ProtectedRoute>

          <ProtectedRoute path="/restaurant/:city/:restaurantId">
            <RestaurantView />
          </ProtectedRoute>

          <ProtectedRoute path="/item/:menuId/:itemId">
            <ItemView />
          </ProtectedRoute>

          <ProtectedRoute isAuthRequired isAdminPage exact path="/menu-item">
            <MenuItemView />
          </ProtectedRoute>

          <ProtectedRoute
            isAuthRequired
            isAdminPage
            exact
            path="/restaurantsubmission"
          >
            <RegistrationSubmission />
          </ProtectedRoute>
          <ProtectedRoute isAuthRequired isAdminPage exact path="/admin/home">
            <AdminHome />
          </ProtectedRoute>
          <ProtectedRoute isAuthRequired isAdminPage exact path="/admin/menu">
            <Menu />
          </ProtectedRoute>

          <ProtectedRoute
            isAuthRequired
            isAdminPage
            exact
            path="/admin/gallery"
          >
            <Gallery />
          </ProtectedRoute>

          <ProtectedRoute
            isAuthRequired
            isAdminPage
            exact
            path="/admin/profile"
          >
            <RestaurantProfile />
          </ProtectedRoute>

          <Route exact path="/unauthenticated">
            <Unauthenticated />
          </Route>
          <Route exact path="/unauthorized">
            <Unauthorized />
          </Route>
          <Route exact path="/">
            <Redirect to={isAdmin ? '/admin/home' : '/home'} />
          </Route>
          <Route path="*">
            <Redirect to={isAdmin ? '/admin/home' : '/home'} />
          </Route>
        </Switch>
      </MuiPickersUtilsProvider>
    </Router>
  );
}
