/* eslint-disable @typescript-eslint/no-misused-new */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Coords } from 'google-map-react';
import { StringMap } from 'i18next';

export interface GoogleMaps {
  places: {
    PlacesService: PlacesService;
    AutocompleteService: AutocompleteService;
  };
  Marker: Marker;
  Geocoder: Geocoder;
}

export interface Geocoder {
  new (): Geocoder;
  geocode: (
    request: GeocoderRequest,
    callback: (
      results: GeocoderResult[] | null,
      status: PlacesServiceStatus,
    ) => void,
  ) => void;
}

export interface GeocoderRequest {
  address?: string;
  bounds?: LatLngBoundsLiteral;
  location?: Coords;
  placeId?: string;
  region?: string;
  componentRestrictions?: GeocoderComponentRestrictions;
}

export interface GeocoderResult {
  address_components: GeocoderAddressComponent;
  formatted_address: string;
  geometry: GeocoderGeometry;
  place_id: string;
  types: string[];
  partial_match?: boolean;
  postcode_localities?: string[];
}

export interface GeocoderGeometry {
  location: LatLng;
  location_type: GeocoderLocationtype;
  viewport: any;
  bounds?: any;
}

export enum GeocoderLocationtype {
  APPROXIMATE = 'APPROXIMATE',
  GEOMETRIC_CENTER = 'GEOMETRIC_CENTER',
  RANGE_INTERPOLATED = 'RANGE_INTERPOLATED',
  ROOFTOP = 'ROOFTOP',
}

export interface GeocoderComponentRestrictions {
  administrativeArea?: string;
  country?: string;
  locality?: string;
  postalCode?: string;
  route?: string;
}

export interface PredictionSubstring {
  length: number;
  offset: number;
}

export interface StructuredFormatting {
  main_text: string;
  main_text_matched_substrings: PredictionSubstring[];
  secondary_text: string;
}

export interface PredictionTerm {
  offset: number;
  value: string;
}

export interface AutocompletePrediction {
  description: string;
  matched_substrings: PredictionSubstring[];
  place_id: string;
  structured_formatting: StructuredFormatting;
  terms: PredictionTerm[];
  types: string[];
  distance_meters?: number;
}

export interface GeocoderAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface PlaceAspectRating {
  ratiing: number;
  type: string;
}

export interface LatLng {
  lat: () => number;
  lng: () => number;
}

export interface PlaceGeometry {
  location: LatLng;
}

export interface PlaceResult {
  address_components: GeocoderAddressComponent[];
  adr_address: string;
  aspects: PlaceAspectRating[];
  formatted_address: string;
  geometry: PlaceGeometry;
  place_id: string;
}

export enum PlacesServiceStatus {
  INVALID_REQUEST = 'INVALID_REQUEST',
  NOT_FOUND = 'NOT_FOUND',
  OK = 'OK',
  OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
  REQUEST_DENIED = 'REQUEST_DENIED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  ZERO_RESULTS = 'ZERO_RESULTS',
}

export interface LatLngBoundsLiteral {
  east: number;
  north: number;
  south: number;
  west: number;
}
export interface ComponentRestrictions {
  country: string[];
}

export interface AutocompletionRequest {
  input: string;
  bounds?: LatLngBoundsLiteral;
  componentRestrictions?: ComponentRestrictions;
  location?: LatLng;
  offset?: number;
  origin?: LatLng;
  radius?: number;
  sessionToken?: any;
  types?: string[];
}

export interface AutocompleteService {
  new (): AutocompleteService;
  getPlacePredictions: (
    request: AutocompletionRequest,
    callback: (
      predictions: AutocompletePrediction[] | null,
      status: PlacesServiceStatus,
    ) => void,
  ) => void;
}

export interface PlaceDetailsRequest {
  placeId: string;
  fields?: string[];
  sessionToken?: any;
}

export interface Marker {
  new (opts: { position: Coords; map: any }): Marker;
  setPosition: (position: Coords) => void;
}

export interface PlacesService {
  new (map: StringMap): PlacesService;
  getDetails: (
    request: PlaceDetailsRequest,
    callback: (place: PlaceResult | null, status: PlacesServiceStatus) => void,
  ) => void;
}
