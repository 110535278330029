import { apply, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux-ts-simple';
import {
  clearMenuItemForm,
  getMenu,
  getRestaurant,
  saveMenu,
  saveRestaurant,
  sendDeleteMenuItem,
  sendMenuItem,
  submitMenuItem,
  updateItems,
  updatePhotos,
  updateRestaurant,
  updateRestaurantLocation,
  updateRestaurantStatus,
  uploadPhoto,
} from './actions';
import { SagaIterator } from 'redux-saga';
import { inject, injectable } from 'inversify';
import { MenuItem } from '../../types/MenuItem';
import { TypesSymbols } from '../../typesSymbols';
import { IAdminMenuService } from '../../types/IAdminMenuService';
import { ApplicationState } from '..';
import { DeleteMenuItemPayload } from '../../types/DeleteMenuItemPayload';
import { IRestaurantService } from '../../types/IRestaurantsService';
import { RestaurantState } from './types';
import { push } from 'connected-react-router';
import { startAction, stopAction } from '../loader/actions';
import { addError } from '../errors/actions';
import {
  RestaurantProfileData,
  RestaurantProfileDataPayload,
} from '../../types/RestaurantProfileData';
import {
  formatPatch,
  parseNormalCategory,
  parseSchedules,
  parseStringToHours,
} from '../../utils/helper-functions/helperFunctions';
import { StringMap } from 'i18next';
import { AxiosError } from 'axios';

export interface GetRestaurantPayload {
  id: string;
  city: string;
}

@injectable()
export class AdminMenuSaga {
  sagas: SagaIterator[] = [
    this._watchHandleSendMenuItem(),
    this._watchHandleSubmitMenuItem(),
    this._watchHandleGetMenu(),
    this._watchHandleDeleteMenuItem(),
    this._watchHandleGetRestaurant(),
    this._watchHandleUpdateRestaurant(),
    this._watchHandleUpdateRestaurantStatus(),
    this._watchHandleUploadPhoto(),
    this._watchHandleUpdatePhotos(),
    this._watchHandleUpdateRestaurantLocation(),
  ];

  @inject(TypesSymbols.IAdminMenuService)
  private adminMenuService!: IAdminMenuService;

  @inject(TypesSymbols.IRestaurantService)
  private restaurantService!: IRestaurantService;

  *_handleGetMenu(action: Action<{ restaurantId: string }>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.adminMenuService,
        this.adminMenuService.getMenuByRestaurant,
        [payload.restaurantId],
      );
      console.log(' get menu saga:', data);

      yield put(saveMenu(data));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error('rest get menu saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleGetRestaurant(action: Action<GetRestaurantPayload>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.restaurantService,
        this.restaurantService.getRestaurantById,
        [payload.id, payload.city],
      );
      console.log(' get restaurant saga:', data);
      const result = {
        ...data,
        hours: parseStringToHours(data.hours),
      };
      yield put(saveRestaurant(result));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error(' rest get restaurant saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleUpdateRestaurantStatus(
    action: Action<{ status: string }>,
  ): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { id, city }: RestaurantState = yield select(
        (appState: ApplicationState) => appState.restaurant,
      );

      const result: StringMap[] = formatPatch(payload);

      const { data } = yield apply(
        this.restaurantService,
        this.restaurantService.updateRestaurant,
        [id ?? '', city, result],
      );
      console.log(' get restaurant saga:', data);

      yield put(saveRestaurant(data));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error(' rest get restaurant saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleUpdateRestaurantLocation(
    action: Action<{ latLng: string }>,
  ): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { id, city }: RestaurantState = yield select(
        (appState: ApplicationState) => appState.restaurant,
      );

      const result: StringMap[] = formatPatch(payload);

      const { data } = yield apply(
        this.restaurantService,
        this.restaurantService.updateRestaurant,
        [id ?? '', city, result],
      );
      console.log(' get restaurant saga:', data);

      yield put(saveRestaurant(data));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error(' rest get restaurant saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleUpdatePhotos(action: Action<string[]>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { id, city }: RestaurantState = yield select(
        (appState: ApplicationState) => appState.restaurant,
      );

      const result: StringMap[] = formatPatch({ photos: payload });

      const { data } = yield apply(
        this.restaurantService,
        this.restaurantService.updateRestaurant,
        [id ?? '', city, result],
      );
      console.log(' get restaurant saga:', data);

      yield put(saveRestaurant(data));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error(' rest get restaurant saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleUploadPhoto(action: Action<string>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { id, city, photos }: RestaurantState = yield select(
        (appState: ApplicationState) => appState.restaurant,
      );

      const result: StringMap[] = formatPatch({
        photos: photos ? [...photos, payload] : [payload],
      });

      const { data } = yield apply(
        this.restaurantService,
        this.restaurantService.updateRestaurant,
        [id ?? '', city, result],
      );
      console.log(' get restaurant saga:', data);

      yield put(saveRestaurant(data));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error(' rest get restaurant saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleUpdateRestaurant(
    action: Action<RestaurantProfileData>,
  ): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { id, city }: RestaurantState = yield select(
        (appState: ApplicationState) => appState.restaurant,
      );

      const dataPayload: RestaurantProfileDataPayload = {
        ...payload,
        phoneCountry: `${payload.phoneCountry?.dial ?? ''}`,
        categories:
          payload.categories?.map((c) => parseNormalCategory(c.code, city)) ??
          [],
        hours: parseSchedules(payload.hours),
      };
      const result: StringMap[] = formatPatch(dataPayload);

      const { data } = yield apply(
        this.restaurantService,
        this.restaurantService.updateRestaurant,
        [id ?? '', city, result],
      );
      console.log(' get restaurant saga:', data);

      yield put(saveRestaurant(data));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error(' rest get restaurant saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleSendMenuItem(action: Action<MenuItem>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      let response;
      const {
        menu: { id: menuId },
        id: restaurantId,
      }: RestaurantState = yield select(
        (appState: ApplicationState) => appState.restaurant,
      );
      if (!payload.id) {
        response = yield apply(
          this.adminMenuService,
          this.adminMenuService.createMenuItem,
          [payload, restaurantId, menuId],
        );
      } else
        response = yield apply(
          this.adminMenuService,
          this.adminMenuService.updateMenuItem,
          [payload, restaurantId, menuId],
        );
      const { data } = response;
      console.log('send Menu item saga:', data);
      //service api call to be added here.
      yield put(clearMenuItemForm());
      yield put(getMenu({ restaurantId: restaurantId ?? '' }));
      yield put(push('/admin/menu'));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error(' user get menu saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_handleDeleteMenuItem(action: Action<DeleteMenuItemPayload>): SagaIterator {
    try {
      yield put(startAction(action));
      const {
        payload: { menuId, restaurantId, menuItem },
      } = action;
      const { data } = yield apply(
        this.adminMenuService,
        this.adminMenuService.deleteMenuItem,
        [menuItem, menuId, restaurantId],
      );

      console.log('delete item saga:', data);
      //service api call to be added here.
      yield put(clearMenuItemForm());
      yield put(getMenu({ restaurantId }));
      yield put(push('/admin/menu'));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error('Delete menu item saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  getItems = (appState: ApplicationState): MenuItem[] =>
    appState.restaurant.menu.menuItems;

  *_handleSubmitMenuItem(action: Action<MenuItem>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const items: MenuItem[] = yield select(this.getItems);

      const oldItems = items.filter((item) => item.id !== payload.id);

      yield put(updateItems([...oldItems, payload]));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      console.error('submit menu item saga error:', error);
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *_watchHandleGetMenu(): SagaIterator {
    yield takeEvery(getMenu.type, this._handleGetMenu.bind(this));
  }

  *_watchHandleGetRestaurant(): SagaIterator {
    yield takeEvery(getRestaurant.type, this._handleGetRestaurant.bind(this));
  }

  *_watchHandleUpdateRestaurant(): SagaIterator {
    yield takeEvery(
      updateRestaurant.type,
      this._handleUpdateRestaurant.bind(this),
    );
  }

  *_watchHandleUpdateRestaurantStatus(): SagaIterator {
    yield takeEvery(
      updateRestaurantStatus.type,
      this._handleUpdateRestaurantStatus.bind(this),
    );
  }

  *_watchHandleUpdateRestaurantLocation(): SagaIterator {
    yield takeEvery(
      updateRestaurantLocation.type,
      this._handleUpdateRestaurantLocation.bind(this),
    );
  }

  *_watchHandleUpdatePhotos(): SagaIterator {
    yield takeEvery(updatePhotos.type, this._handleUpdatePhotos.bind(this));
  }

  *_watchHandleUploadPhoto(): SagaIterator {
    yield takeEvery(uploadPhoto.type, this._handleUploadPhoto.bind(this));
  }

  *_watchHandleDeleteMenuItem(): SagaIterator {
    yield takeEvery(
      sendDeleteMenuItem.type,
      this._handleDeleteMenuItem.bind(this),
    );
  }

  *_watchHandleSubmitMenuItem(): SagaIterator {
    yield takeEvery(submitMenuItem.type, this._handleSubmitMenuItem.bind(this));
  }

  *_watchHandleSendMenuItem(): SagaIterator {
    yield takeEvery(sendMenuItem.type, this._handleSendMenuItem.bind(this));
  }
}
