import { TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import { Option } from '../../types/Option';

export const renderInput = (
  label: string,
  helperText?: string,
  error?: boolean,
  props?: TextFieldProps,
) =>
  function input(params: TextFieldProps): JSX.Element {
    return (
      <TextField
        {...params}
        {...props}
        helperText={helperText}
        error={error}
        label={label}
      />
    );
  };

export const getOptionSelected = (option: Option, value: Option): boolean =>
  option.code === value.code;

export const renderOption = (option: Option): JSX.Element => (
  <React.Fragment>{option.label}</React.Fragment>
);
