import { Button, Grid, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useGlobalStyles } from '../../App';

export const Unauthenticated = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const globalClasses = useGlobalStyles();
  const handleSignInClick = () => {
    dispatch(push('/sign-in'));
  };
  const handleSignUpClick = () => {
    dispatch(push('/sign-up'));
  };
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={globalClasses.container}
    >
      <Grid item xs md={6}>
        <Typography align="center" variant="h6">
          {t('Unauthenticated.mustSignIn')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={globalClasses.verticalSpacing1} />
      <Grid item xs md={1}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleSignInClick}
        >
          {t('SignUp.signIn')}
        </Button>
      </Grid>
      <Grid item className={globalClasses.horizontalSpacing1} />
      <Grid item xs md={1}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleSignUpClick}
        >
          {t('SignUp.signUp')}
        </Button>
      </Grid>
    </Grid>
  );
};
