/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthState } from '../../store/auth/types';
import { ValueMap } from '../../types/ValueMap';

export const getToken = () => {
  const root = JSON.parse(
    localStorage.getItem('persist:root') ?? '{}',
  ) as ValueMap<string>;
  const auth = JSON.parse(root.auth ?? '{}') as AuthState;
  return auth.token ?? '';
};

export const authPost = <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const token = getToken();
  const authConfig: AxiosRequestConfig = {
    ...config,
    headers: { ...config?.headers, Authorization: `Bearer ${token}` },
  };
  return axios.post<T>(url, data, authConfig);
};

export const authPut = <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const token = getToken();
  const authConfig: AxiosRequestConfig = {
    ...config,
    headers: { ...config?.headers, Authorization: `Bearer ${token}` },
  };
  return axios.put<T>(url, data, authConfig);
};

export const authPatch = <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const token = getToken();
  const authConfig: AxiosRequestConfig = {
    ...config,
    headers: { ...config?.headers, Authorization: `Bearer ${token}` },
  };
  return axios.patch<T>(url, data, authConfig);
};

export const authGet = <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const token = getToken();
  const authConfig: AxiosRequestConfig = {
    ...config,
    headers: { ...config?.headers, Authorization: `Bearer ${token}` },
  };
  return axios.get<T>(url, authConfig);
};

export const post = <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return axios.post<T>(url, data, config);
};

export const put = <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return axios.put<T>(url, data, config);
};

export const patch = <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return axios.patch<T>(url, data, config);
};

export const get = <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return axios.get<T>(url, config);
};
