import { Button, Grid, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalStyles } from '../../App';
import { ApplicationState } from '../../store';

export const Unauthorized = (): JSX.Element => {
  const { isAdmin } = useSelector((appState: ApplicationState) => ({
    isAdmin: !!appState.restaurantUser.email,
  }));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const globalClasses = useGlobalStyles();
  const handleHomeClick = () => {
    const path = isAdmin ? '/admin/home' : '/home';
    dispatch(push(path));
  };
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={globalClasses.container}
    >
      <Grid item xs md={6}>
        <Typography align="center" variant="h6">
          {t('Unauthorized.message')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={globalClasses.verticalSpacing1} />
      <Grid item xs md={1}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleHomeClick}
        >
          {t('Common.home')}
        </Button>
      </Grid>
    </Grid>
  );
};
