import { ReducerBuilder } from 'redux-ts-simple';
import {
  handleBack,
  handleNext,
  handleReset,
  submitRestaurantAccountInfo,
  submitRestaurantContactInfo,
  submitRestaurantLocationInfo,
} from './actions';
import { RestaurantRegistrationState } from './types';

export const initialRestaurantFormState: RestaurantRegistrationState = {
  email: '',
  name: '',
  description: '',
  address1: '',
  address2: '',
  facebook: '',
  phoneNumber: '',
  website: '',
  zipCode: '',
  ownerEmail: '',
  ownerFirstName: '',
  ownerLastName: '',
  ownerPhoneNumber: '',
  password: '',
  confirmPassword: '',
  activeStep: 0,
};

export const reducer = new ReducerBuilder(initialRestaurantFormState)
  .on(submitRestaurantLocationInfo, (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .on(submitRestaurantContactInfo, (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .on(submitRestaurantAccountInfo, (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .on(handleNext, (state) => ({
    ...state,
    activeStep: state.activeStep + 1,
  }))
  .on(handleBack, (state) => ({
    ...state,
    activeStep: state.activeStep - 1,
  }))
  .on(handleReset, (state) => ({
    ...state,
    activeStep: 0,
  }))
  .else((state) => state)
  .build();
