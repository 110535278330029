import 'regenerator-runtime/runtime';

import { Container } from 'inversify';
import { all } from 'redux-saga/effects';
import { RestaurantSaga } from './restaurant-registration/sagas';
import { UserAuthSaga } from './user/sagas';
import { AdminMenuSaga } from './restaurant/sagas';
import { UserHomeSaga } from './user-home/sagas';
import { RestaurantUserSaga } from './restaurant-user/sagas';
import { PagesSaga } from './pages/sagas';

export class RootSaga {
  private restaurantSaga: RestaurantSaga;
  private userAuthSaga: UserAuthSaga;
  private adminMenuSaga: AdminMenuSaga;
  private userHomeSaga: UserHomeSaga;
  private restaurantUserSaga: RestaurantUserSaga;
  private pagesSaga: PagesSaga;

  constructor(container: Container) {
    this.restaurantSaga = container.resolve<RestaurantSaga>(RestaurantSaga);
    this.userAuthSaga = container.resolve<UserAuthSaga>(UserAuthSaga);
    this.adminMenuSaga = container.resolve<AdminMenuSaga>(AdminMenuSaga);
    this.userHomeSaga = container.resolve<UserHomeSaga>(UserHomeSaga);
    this.restaurantUserSaga = container.resolve<RestaurantUserSaga>(
      RestaurantUserSaga,
    );
    this.pagesSaga = container.resolve<PagesSaga>(PagesSaga);
    this.sagas = this.sagas.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  *sagas(): any {
    yield all([
      ...this.restaurantSaga.sagas,
      ...this.userAuthSaga.sagas,
      ...this.adminMenuSaga.sagas,
      ...this.userHomeSaga.sagas,
      ...this.restaurantUserSaga.sagas,
      ...this.pagesSaga.sagas,
    ]);
  }
}
