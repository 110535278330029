import React, { Fragment, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import {
  AutocompletePrediction,
  AutocompleteService,
  PlacesService,
  PlacesServiceStatus,
  PlaceResult,
  GoogleMaps,
} from './GoogleMapsTypes';
import { StringMap } from 'i18next';
import { Coords } from 'google-map-react';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function MapAutocomplete({
  setPlace,
  maps,
  map,
}: {
  setPlace: (place: Coords) => void;
  setCity?: (city: string) => void;
  maps?: GoogleMaps;
  map?: StringMap;
}): JSX.Element {
  const classes = useStyles();
  const [value, setValue] = React.useState<AutocompletePrediction | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<AutocompletePrediction[]>([]);
  const [
    autocompleteService,
    setAutocompleteServcie,
  ] = useState<AutocompleteService>();
  const [placesService, setPlacesService] = useState<PlacesService>();

  const updateOptions = (
    predictions: AutocompletePrediction[] | null,
    status: PlacesServiceStatus,
  ) => {
    console.log('getting predictions', predictions);
    if (status !== PlacesServiceStatus.OK || !predictions) setOptions([]);
    if (predictions) setOptions(predictions);
  };

  const getPlaceDetails = (
    place: PlaceResult | null,
    status: PlacesServiceStatus,
  ) => {
    console.log('getting place details', place);
    if (place && status === PlacesServiceStatus.OK)
      setPlace({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
  };

  useEffect(() => {
    if (!autocompleteService && maps && map) {
      console.log('creating service');
      const autocomplete = new maps.places.AutocompleteService();
      const places = new maps.places.PlacesService(map);
      setAutocompleteServcie(autocomplete);
      setPlacesService(places);
    }
  }, [autocompleteService, maps, map]);

  useEffect(() => {
    if (autocompleteService && inputValue) {
      autocompleteService.getPlacePredictions(
        {
          input: inputValue,
          componentRestrictions: { country: ['us', 'pr'] },
        },
        updateOptions,
      );
    }
  }, [inputValue]);

  useEffect(() => {
    if (value) {
      console.log('Place:', value);
      if (placesService)
        placesService.getDetails(
          {
            placeId: value.place_id,
            fields: ['geometry'],
          },
          getPlaceDetails,
        );
    }
  }, [value]);

  return (
    <Fragment>
      <Autocomplete
        id="google-map-demo"
        style={{ width: 320 }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        getOptionSelected={(option, value) =>
          option.place_id === value.place_id
        }
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search location"
            variant="outlined"
            fullWidth
          />
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </Fragment>
  );
}
