/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError, Resolver } from 'react-hook-form';
import validator from 'validator';
import i18n from '../../../../i18n';
import { OptionError } from '../../../../types/Option';
import { RestaurantContactInfo } from '../../../../types/RestaurantContactInfo';
import {
  validate,
  Validation,
} from '../../../../utils/rhf/validationFunctions';

export const validateEmail = (email: string): FieldError | undefined => {
  if (validate(email, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
  if (!validator.isEmail(email))
    return {
      type: 'invalidEmailFormat',
      message: i18n.t('MenuItem.errorMessages.invalidEmailFormat'),
    };
};

export const validateName = (name: string): FieldError | undefined => {
  if (validate(name, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
};

export const validatePhoneCountry = (
  phoneCountry = 0,
): OptionError | undefined => {
  if (validate(phoneCountry, Validation.required))
    return {
      code: {
        type: 'required',
        message: i18n.t('RestaurantSignUp.errorMessages.required'),
      },
      label: {
        type: 'required',
        message: i18n.t('RestaurantSignUp.errorMessages.required'),
      },
    };
};

export const validatePhoneNumber = (
  phoneNumber: string,
  areaCode = 0,
): FieldError | undefined => {
  if (validate(phoneNumber, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
  if (!validator.isMobilePhone(`${areaCode}${phoneNumber}`))
    return {
      type: 'invalidPhoneFormat',
      message: i18n.t('RestaurantSignUp.errorMessages.invalidPhoneFormat'),
    };
};

export const validateWebsite = (website: string): FieldError | undefined => {
  if (!validate(website, Validation.required) && !validator.isURL(website))
    return {
      type: 'invalidURL',
      message: i18n.t('RestaurantSignUp.errorMessages.invalidURL'),
    };
};

export const validateFacebook = (facebook: string): FieldError | undefined => {
  if (!validate(facebook, Validation.required) && !validator.isURL(facebook))
    return {
      type: 'invalidURL',
      message: i18n.t('RestaurantSignUp.errorMessages.invalidURL'),
    };
};

type RestAccountInfoKeys = keyof Pick<
  RestaurantContactInfo,
  Exclude<
    Exclude<keyof RestaurantContactInfo, 'phoneCountry'>,
    'ownerPhoneCountry'
  >
>;
type RestAccountInfoErrorKeys = {
  [key in RestAccountInfoKeys]?: FieldError;
};
export interface RestaurantContactErrors extends RestAccountInfoErrorKeys {
  phoneCountry?: OptionError;
  ownerPhoneCountry?: OptionError;
}

export const resolver: Resolver<RestaurantContactInfo, any> = async (
  values: RestaurantContactInfo,
) => {
  const errors: RestaurantContactErrors = {};
  errors.email = validateEmail(values.email);
  errors.ownerFirstName = validateName(values.ownerFirstName);
  errors.ownerLastName = validateName(values.ownerLastName);
  errors.ownerPhoneCountry = validatePhoneCountry(
    values.ownerPhoneCountry?.dial,
  );
  errors.ownerPhoneNumber = validatePhoneNumber(
    values.ownerPhoneNumber,
    values.ownerPhoneCountry?.dial,
  );
  errors.phoneCountry = validatePhoneCountry(values.phoneCountry?.dial);
  errors.phoneNumber = validatePhoneNumber(
    values.phoneNumber,
    values.phoneCountry?.dial,
  );
  errors.website = validateWebsite(values.website);
  errors.facebook = validateFacebook(values.facebook);

  const result = {
    values: {},
    errors: {},
  };

  if (
    errors.email ||
    errors.facebook ||
    errors.ownerFirstName ||
    errors.ownerLastName ||
    errors.ownerPhoneCountry ||
    errors.ownerPhoneNumber ||
    errors.phoneCountry ||
    errors.phoneNumber ||
    errors.website
  )
    result.errors = errors;
  else result.values = values;

  return new Promise((resolve) => {
    resolve(result);
  });
};
