import { apply, put, takeEvery } from 'redux-saga/effects';
import {
  editUser,
  restaurantUserEmailLogin,
  restaurantUserLogin,
  restaurantUserSignUp,
  saveUser,
} from './actions';
import { Action } from 'redux-ts-simple';
import { SagaIterator } from 'redux-saga';
import { inject, injectable } from 'inversify';
import { TypesSymbols } from '../../typesSymbols';
import { IRestaurantUserService } from '../../types/IRestaurantUserService';
import { Credentials } from '../../types/Credentials';
import { RestaurantUser } from '../../types/RestaurantUserFormData';
import { push } from 'connected-react-router';
import { startAction, stopAction } from '../loader/actions';
import { getRestaurant } from '../restaurant/actions';
import { AxiosError } from 'axios';
import { addError } from '../errors/actions';
import { handleNext } from '../restaurant-registration/actions';
import { saveToken } from '../auth/actions';

@injectable()
export class RestaurantUserSaga {
  sagas: SagaIterator[] = [
    this.watchHandleEmailLogin(),
    this.watchHandleEmailSignUp(),
    this.watchHandleUpdateRestaurantUser(),
  ];

  @inject(TypesSymbols.IRestaurantUserService)
  private restaurantUserService!: IRestaurantUserService;

  *handleSignUp(action: Action<Credentials>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.restaurantUserService,
        this.restaurantUserService.createAccount,
        [payload],
      );
      console.log('saga', data);
      yield put(saveToken(data.token));
      yield put(restaurantUserLogin({ ...data.user }));
      yield put(handleNext());
      yield put(push('/restaurant-sign-up/about'));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 409) {
        yield put(
          addError({
            code: 'REST_USER_EMAIL_CONFLICT',
            message: 'EmailConflict',
          }),
        );
        // set Wrong credentials error
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      // error state to be updated here when implemented.
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *handleLogin(action: Action<Credentials>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.restaurantUserService,
        this.restaurantUserService.signIn,
        [payload],
      );
      console.log('saga', data);
      yield put(saveToken(data.token));
      yield put(restaurantUserLogin({ ...data.user }));
      yield put(
        getRestaurant({
          id: data.user.restaurantId,
          city: data.user.restaurantCity,
        }),
      );
      yield put(push('/admin/home'));
    } catch (error) {
      yield put(
        addError({
          code: 'UNEXPECTED_ERROR',
          message: 'UnexpectedError',
        }),
      );
      console.error('login saga error:', error);
      // error state to be updated here when implemented.
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *handleUpdateRestaurantUser(action: Action<RestaurantUser>): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const { data } = yield apply(
        this.restaurantUserService,
        this.restaurantUserService.updateRestaurantUser,
        [payload],
      );
      console.log('saga', data);
      yield put(saveUser({ ...data }));
    } catch (err) {
      const error: AxiosError = err;
      if (error.response?.status === 401) {
        yield put(
          addError({
            code: 'UNAUTHORIZED',
            message: 'Unauthorized',
          }),
        );
      } else
        yield put(
          addError({
            code: 'UNEXPECTED_ERROR',
            message: 'UnexpectedError',
          }),
        );
      // error state to be updated here when implemented.
    } finally {
      yield put(stopAction({ ...action }));
    }
  }

  *watchHandleEmailSignUp(): SagaIterator {
    yield takeEvery(restaurantUserSignUp.type, this.handleSignUp.bind(this));
  }

  *watchHandleEmailLogin(): SagaIterator {
    yield takeEvery(restaurantUserEmailLogin.type, this.handleLogin.bind(this));
  }

  *watchHandleUpdateRestaurantUser(): SagaIterator {
    yield takeEvery(editUser.type, this.handleUpdateRestaurantUser.bind(this));
  }
}
