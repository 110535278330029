import React, { Fragment, useEffect } from 'react';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import { Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { useGlobalStyles } from '../../../../App';
import { RestaurantAccountInfo } from '../../../../types/RestaurantAccountInfo';
import { RegistrationStepProps } from '../../../../types/RegistrationStepProps';
import { resolver } from './resolver';
import { PasswordInput } from '../../../../utils/components/PasswordInput';
import { restaurantUserSignUp } from '../../../../store/restaurant-user/actions';
import { getError } from '../../../../store/errors/selectors';
import { ApplicationError } from '../../../../types/Error';
import { clearError } from '../../../../store/errors/actions';

export type OwnerAccountProps = RegistrationStepProps;

function OwnerAccount(): JSX.Element {
  const emailConflictError = useSelector(getError('REST_USER_EMAIL_CONFLICT'));

  const defaultValues: RestaurantAccountInfo = useSelector(
    (appState: ApplicationState) => ({ ...appState.restaurantForm }),
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useGlobalStyles();

  const {
    control,
    handleSubmit,
    register,
    errors,
    setError,
    getValues,
  } = useForm<RestaurantAccountInfo>({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  const onSubmit = (data: RestaurantAccountInfo) => {
    console.log('submitted data:', data);
    dispatch(clearError({ code: 'REST_USER_EMAIL_CONFLICT' }));
    dispatch(
      restaurantUserSignUp({ email: data.ownerEmail, password: data.password }),
    );
  };

  useEffect(() => {
    if (emailConflictError) {
      setError('ownerEmail', {
        message: t(
          `Errors.${(emailConflictError as ApplicationError).message}`,
          {
            email: getValues('ownerEmail'),
          },
        ) as string,
      });
    }
  }, [emailConflictError]);

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.container}
      >
        <Grid container direction="column" item xs={12} sm={8} lg={4}>
          <Typography variant="h4">
            {t('RestaurantSignUp.createAccount')}
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item container direction="column">
              <Grid item xs={12} className={classes.verticalSpacing1}>
                <TextField
                  helperText={errors?.ownerEmail?.message ?? ''}
                  error={!!errors?.ownerEmail}
                  fullWidth
                  id="ownerEmail"
                  name="ownerEmail"
                  type="email"
                  label={t('RestaurantSignUp.ownerEmail')}
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12} className={classes.verticalSpacing1}>
                <PasswordInput
                  helperText={errors?.password?.message ?? ''}
                  error={!!errors?.password}
                  fullWidth
                  id="password"
                  name="password"
                  label={t('SignUp.passwordLabel')}
                  inputRef={register}
                />
              </Grid>

              <Grid item xs={12} className={classes.verticalSpacing1}>
                <PasswordInput
                  helperText={errors?.confirmPassword?.message ?? ''}
                  error={!!errors?.confirmPassword}
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  label={t('SignUp.confirmPasswordLabel')}
                  inputRef={register}
                />
              </Grid>
              <Grid container item xs={12} className={classes.verticalSpacing1}>
                <Grid item xs>
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                  >
                    {t('Common.nextButton')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <DevTool control={control} />
      </Grid>
    </Fragment>
  );
}

export default OwnerAccount;
