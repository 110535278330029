/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError, Resolver } from 'react-hook-form';
import validator from 'validator';
import i18n from '../../../../i18n';
import { OptionError } from '../../../../types/Option';
import { UserFormData } from '../../../../types/UserFormData';
import {
  validate,
  Validation,
} from '../../../../utils/rhf/validationFunctions';
import { validateName } from '../../../admin/auth/about-info/resolver';
import {
  validatePhoneCountry,
  validatePhoneNumber,
} from '../../../admin/auth/contact-info/resolver';

export const validateEmail = (email: string): FieldError | undefined => {
  if (validate(email, Validation.required))
    return {
      type: Validation.required,
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
  if (!validator.isEmail(email))
    return {
      type: 'invalidEmailFormat',
      message: i18n.t('MenuItem.errorMessages.invalidEmailFormat'),
    };
  return undefined;
};

export const validatePassword = (password: string): FieldError | undefined => {
  if (validate(password, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
  if (
    !validator.isStrongPassword(password, { minSymbols: 0 }) ||
    !validate(password, Validation.containsSymbol)
  )
    return {
      type: 'weakPassword',
      message: i18n.t('RestaurantSignUp.errorMessages.weakPassword'),
    };
  return undefined;
};

export const validateConfirmPassword = (
  confirmPassword: string,
  password: string,
): FieldError | undefined => {
  if (validate(confirmPassword, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
  if (confirmPassword !== password)
    return {
      type: 'passwordsDontMatch',
      message: i18n.t('RestaurantSignUp.errorMessages.passwordsDontMatch'),
    };
  return undefined;
};

type UserFormDataKeys = keyof Pick<
  UserFormData,
  Exclude<keyof UserFormData, 'countryAreaCode'>
>;
type UserFormDataErrorKeys = {
  [key in UserFormDataKeys]?: FieldError;
};
export interface UserFormDataErrors extends UserFormDataErrorKeys {
  countryAreaCode?: OptionError;
}

export const resolver: Resolver<UserFormData, any> = async (
  values: UserFormData,
) => {
  const errors: UserFormDataErrors = {};
  errors.email = validateEmail(values.email);
  errors.password = validatePassword(values.password);
  errors.confirmPassword = validateConfirmPassword(
    values.confirmPassword,
    values.password,
  );
  errors.countryAreaCode = validatePhoneCountry(values.countryAreaCode?.dial);
  errors.phoneNumber = validatePhoneNumber(
    values.phoneNumber,
    values.countryAreaCode?.dial,
  );
  errors.firstName = validateName(values.firstName);
  errors.lastName = validateName(values.lastName);

  const result = {
    values: {},
    errors: {},
  };

  if (
    errors.confirmPassword ||
    errors.email ||
    errors.password ||
    errors.phoneNumber ||
    errors.countryAreaCode ||
    errors.firstName ||
    errors.lastName
  )
    result.errors = errors;
  else result.values = values;

  return new Promise((resolve) => {
    resolve(result);
  });
};
