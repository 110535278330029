/* eslint-disable @typescript-eslint/no-empty-function */
import { AxiosResponse } from 'axios';
import { StringMap } from 'i18next';
import { injectable } from 'inversify';
import { categories } from '../../constants';
import { CategoriesList } from '../../types/CategoriesList';
import { IRestaurantService } from '../../types/IRestaurantsService';
import { Restaurant } from '../../types/Restaurant';
import { authPatch, authPost, get } from '../../utils/services/axiosWrappers';

const API_URL = process.env.REACT_APP_API_URL ?? '';

@injectable()
export class RestaurantService implements IRestaurantService {
  async addRestaurant(
    restaurant: Restaurant,
    owner: string,
    ownerEmail: string,
  ): Promise<AxiosResponse<Restaurant>> {
    try {
      console.log('payload:', restaurant);
      return await authPost(`${API_URL}/jartera/restaurants/add`, {
        ...restaurant,
        owner,
        ownerEmail,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateRestaurant(
    id: string,
    city: string,
    payload: StringMap[],
  ): Promise<AxiosResponse<Restaurant>> {
    try {
      console.log('update payload:', id, city, payload);
      return await authPatch(
        `${API_URL}/jartera/restaurants/patch/${id}/${city}`,
        payload,
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private categories: string[] = categories;

  public async getCategoriesListByCity(
    city: string,
  ): Promise<AxiosResponse<CategoriesList>> {
    try {
      console.log('payload get categoriesList by city:', city);
      return await get(`${API_URL}/jartera/categorieslists/${city}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getRestaurantsByCategoryCity(
    category: string,
    city: string,
  ): Promise<AxiosResponse<Restaurant[]>> {
    try {
      console.log(
        'payload get restaurants by city and category:',
        city,
        category,
      );
      return await get(
        `${API_URL}/jartera/restaurants/category/${category}/${city}`,
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getRestaurantById(
    restaurantId: string,
    city = '',
  ): Promise<AxiosResponse<Restaurant>> {
    try {
      console.log('payload:', restaurantId, city);
      return await get(
        `${API_URL}/jartera/restaurants/${restaurantId}/${city}`,
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const restaurantService = new RestaurantService();
export default restaurantService;
