import {
  Card,
  CardContent,
  CardActions,
  Grid,
  CardMedia,
  Button,
  Paper,
  useTheme,
} from '@material-ui/core';
import { Delete, InsertPhoto } from '@material-ui/icons';
import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../../../App';
import { ImageCardInputProps } from '../../../types/ImageCardInputProps';
import {
  ImageDimensions,
  useImageResizer,
} from '../../../hooks/useImageResizer';
import { UploadImage } from '../../upload-input/UploadImage';

const defaultDimensions: ImageDimensions = { height: 250, width: 250 };
export const ImageCardInput = (props: ImageCardInputProps): JSX.Element => {
  const {
    field: { onChange, value },
  } = useController({ ...props });

  const { height, width }: ImageDimensions = useImageResizer(
    value,
    { width: 320, height: 320 },
    defaultDimensions,
  );

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useGlobalStyles();

  const handleDeleteButton = () => {
    onChange('');
  };

  return (
    <Grid item xs={12} className={classes.verticalSpacing1}>
      <Card
        style={{
          height: 'fit-content',
          width: 'fit-content',
        }}
      >
        <Grid
          component={CardContent}
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item>
            {value && (
              <CardMedia
                image={value}
                style={{
                  height: height,
                  width: width,
                }}
              />
            )}
            {!value && (
              <Paper
                elevation={0}
                style={{
                  backgroundColor: theme.palette.grey[300],
                  height: height,
                  width: width,
                }}
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{
                    height: height,
                    width: width,
                  }}
                >
                  <Grid item>
                    <InsertPhoto style={{ fontSize: 48 }} />
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>
        </Grid>
        <CardActions style={{ justifyContent: 'center' }}>
          <UploadImage setSrc={onChange} />

          {value && (
            <Button
              onClick={handleDeleteButton}
              startIcon={<Delete />}
              variant="contained"
              color="secondary"
              size="small"
            >
              {t('MenuItem.deletePhoto')}
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};
