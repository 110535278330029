import React from 'react';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import { Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DevTool } from '@hookform/devtools';
import { RestaurantAboutInfo } from '../../../../types/RestaurantLocationInfo';
import { Option } from '../../../../types/Option';
import { ComboBoxRender } from '../../../../utils/rhf/Controllers';
import {
  handleNext,
  submitRestaurantLocationInfo,
} from '../../../../store/restaurant-registration/actions';
import { AutoCompleteProps } from '../../../../types/AutoCompleteProps';
import { ApplicationState } from '../../../../store';
import { useGlobalStyles } from '../../../../App';
import { RegistrationStepProps } from '../../../../types/RegistrationStepProps';
import { resolver } from './resolver';
import { renderInput } from '../../../../utils/components/AutoCompleteUtils';
import { useAddress } from '../../../../hooks/use-address/useAddress';
import { push } from 'connected-react-router';

export type RestaurantLocationInfoProps = RegistrationStepProps;

function AboutInfo({ handleBack }: RestaurantLocationInfoProps): JSX.Element {
  const {
    cities,
    countries,
    setCountry,
    setSelectedState,
    states,
  } = useAddress();
  const defaultValues: RestaurantAboutInfo = useSelector(
    (appState: ApplicationState) => ({ ...appState.restaurantForm }),
  );
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    register,
    errors,
  } = useForm<RestaurantAboutInfo>({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  const onSubmit = (data: RestaurantAboutInfo) => {
    console.log('submitted data:', data);
    dispatch(submitRestaurantLocationInfo(data));
    dispatch(handleNext());
    dispatch(push('/restaurant-sign-up/contact-info'));
  };
  const { t } = useTranslation();
  const classes = useGlobalStyles();

  const handleBackButton = () => {
    handleBack();
  };

  const stateOptions: Option[] = states.map((code) => ({
    code,
    label: t(`States.${code.toUpperCase()}`) as string,
  }));

  const cityOptions: Option[] = cities?.map((code) => ({
    code,
    label: code,
  }));

  const countryOptions: Option[] = countries.map((code) => ({
    code,
    label: t(`Countries.${code}`) as string,
  }));

  const renderOption = (option: Option) => (
    <React.Fragment>
      {option.label} ({option.code.toUpperCase()})
    </React.Fragment>
  );

  const cityProps: AutoCompleteProps<Option> = {
    id: 'city-select',
    options: cityOptions,
    classes: {
      option: classes.option,
    },
    size: 'small',
    autoHighlight: true,
    getOptionLabel: (option: Option) => option.label,
    renderOption: (option: Option) => option.label,
    renderInput: renderInput(
      t('RestaurantSignUp.city') as string,
      errors.city?.code?.message,
      !!errors.city,
    ),
  };

  const stateProps: AutoCompleteProps<Option> = {
    id: 'state-select',
    options: stateOptions,
    classes: {
      option: classes.option,
    },
    size: 'small',
    autoHighlight: true,
    onChange: (
      event: React.ChangeEvent<unknown>,
      value: string | Option | (string | Option)[] | null,
    ) => {
      const option = value as Option;
      setSelectedState(option?.code ?? '');
    },
    getOptionLabel: (option: Option) =>
      `${option.label} (${option.code.toUpperCase()})`,
    renderOption,
    renderInput: renderInput(
      t('RestaurantSignUp.state') as string,
      errors.state?.code?.message,
      !!errors.state,
    ),
  };

  const countryProps: AutoCompleteProps<Option> = {
    id: 'country-select',
    options: countryOptions,
    classes: {
      option: classes.option,
    },
    onChange: (
      event: React.ChangeEvent<unknown>,
      value: string | Option | (string | Option)[] | null,
    ) => {
      const option = value as Option;
      setCountry(option?.code ?? '');
    },
    size: 'small',
    autoHighlight: true,
    getOptionLabel: (option: Option) =>
      `${option.label} (${option.code.toUpperCase()})`,
    renderOption,
    renderInput: renderInput(
      t('RestaurantSignUp.country') as string,
      errors.country?.code?.message,
      !!errors.country,
    ),
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Grid container direction="column" item xs={12} sm={8} lg={4}>
        <Typography variant="h4">
          {t('RestaurantSignUp.tellUsAboutRest')}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item container direction="column">
            <Grid
              item
              xs={12}
              className={`${classes.verticalSpacing1} ${classes.horizontalSpacing1}`}
            >
              <TextField
                helperText={errors.name?.message}
                error={!!errors.name}
                fullWidth
                id="name"
                label={t('RestaurantSignUp.name')}
                inputRef={register}
                name="name"
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${classes.verticalSpacing1} ${classes.horizontalSpacing1}`}
            >
              <TextField
                helperText={errors.description?.message}
                error={!!errors.description}
                fullWidth
                id="description"
                label={t('RestaurantSignUp.description')}
                inputRef={register}
                name="description"
                multiline
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${classes.verticalSpacing1} ${classes.horizontalSpacing1}`}
            >
              <TextField
                helperText={errors.address1?.message}
                error={!!errors.address1}
                fullWidth
                id="address1"
                name="address1"
                label={t('RestaurantSignUp.address1')}
                inputRef={register}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${classes.verticalSpacing1} ${classes.horizontalSpacing1}`}
            >
              <TextField
                helperText={errors.address2?.message}
                error={!!errors.address2}
                fullWidth
                id="address2"
                name="address2"
                label={t('RestaurantSignUp.address2')}
                inputRef={register}
              />
            </Grid>

            <Grid item container xs={12} className={classes.verticalSpacing1}>
              <Grid item xs className={classes.horizontalSpacing1}>
                <ComboBoxRender
                  {...countryProps}
                  controllerprops={{
                    name: 'country',
                    control: control,
                    defaultValue: countryOptions[0],
                  }}
                />
              </Grid>
              <Grid item xs className={classes.horizontalSpacing1}>
                <ComboBoxRender
                  {...stateProps}
                  controllerprops={{
                    name: 'state',
                    control: control,
                    defaultValue: stateOptions[0],
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} className={classes.verticalSpacing1}>
              <Grid item xs className={classes.horizontalSpacing1}>
                <ComboBoxRender
                  {...cityProps}
                  controllerprops={{
                    name: 'city',
                    control: control,
                    defaultValue: cityOptions[0],
                  }}
                />
                {/* <TextField
                  helperText={errors.city?.message}
                  error={!!errors.city}
                  fullWidth
                  id="city"
                  name="city"
                  label={t('RestaurantSignUp.city')}
                  inputRef={register}
                /> */}
              </Grid>

              <Grid item xs className={classes.horizontalSpacing1}>
                <TextField
                  helperText={errors.zipCode?.message}
                  error={!!errors.zipCode}
                  fullWidth
                  id="zipCode"
                  name="zipCode"
                  label={t('RestaurantSignUp.zipCode')}
                  inputRef={register}
                />
              </Grid>
            </Grid>

            <Grid item container className={classes.verticalSpacing1}>
              <Grid item xs className={classes.horizontalSpacing1}>
                <Button
                  onClick={handleBackButton}
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  {t('Common.backButton')}
                </Button>
              </Grid>

              <Grid item xs className={classes.horizontalSpacing1}>
                <Button
                  type="submit"
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  {t('Common.nextButton')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <DevTool control={control} />
    </Grid>
  );
}

export default AboutInfo;
