import { ReducerBuilder } from 'redux-ts-simple';
import i18n from '../../i18n';
import {
  clearMenuItemForm,
  loadMenuItemForm,
  saveMenu,
  saveRestaurant,
  submitCreateMenu,
  updateItems,
} from './actions';
import { RestaurantState } from './types';

export const initialRestaurantState: RestaurantState = {
  address1: '',
  city: '',
  country: '',
  description: '',
  name: '',
  zipCode: '',
  address2: '',
  status: '',
  applicationStatus: '',
  menu: {
    menuItems: [],
    sections: ['promo', 'main', 'sides', 'desserts', 'drinks'],
    id: '',
  },
  menuItemForm: {
    description: '',
    inStock: false,
    minPrice: 0,
    name: '',
    optionLists: [],
    photo: '',
    menuSection: {
      code: 'promo',
      label: i18n.t('MenuSections.promo') as string,
    },
  },
};

export const restaurantReducer = new ReducerBuilder(initialRestaurantState)
  .on(saveMenu, (state, action) => ({ ...state, menu: action.payload }))
  .on(submitCreateMenu, (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .on(loadMenuItemForm, (state, action) => ({
    ...state,
    menuItemForm: { ...action.payload },
  }))
  .on(clearMenuItemForm, (state) => ({
    ...state,
    menuItemForm: { ...initialRestaurantState.menuItemForm },
  }))
  .on(updateItems, (state, action) => ({ ...state, items: action.payload }))
  .on(saveRestaurant, (state, action) => ({ ...state, ...action.payload }))
  .else((state) => state)
  .build();
