import { DevTool } from '@hookform/devtools';
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  Grid,
  Icon,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalStyles } from '../../../App';
import { ApplicationState } from '../../../store';
import { AutoCompleteProps } from '../../../types/AutoCompleteProps';
import {
  mapRHFObjectToObject,
  RestaurantProfileData,
  RestaurantProfileSubmittedData,
} from '../../../types/RestaurantProfileData';
import { Option } from '../../../types/Option';
import {
  getOptionSelected,
  renderInput,
} from '../../../utils/components/AutoCompleteUtils';
import { ComboBoxRender } from '../../../utils/rhf/Controllers';
import {
  getRestaurant,
  updateRestaurant,
  updateRestaurantLocation,
} from '../../../store/restaurant/actions';
import { resolver } from './resolver';
import { SchedulesFieldArrayContainer } from './SchedulesFieldArrayContainer';
import { useCategories } from '../../../hooks/use-categories/useCategories';
import { RestaurantStatusSwitch } from './RestaurantStatusSwitch';
import { useAreaCodes } from '../../../hooks/use-area-codes/useAreaCodes';
import { parseWeekHours } from '../../../utils/helper-functions/helperFunctions';
import AlertDialog from '../alert-dialog/AlertDialog';
import { GoogleMapsDialog } from '../google-maps-dialog/GoogleMapsDialog';
import { Coords } from 'google-map-react';

const renderOption = (option: Option) => (
  <React.Fragment>{option?.label ?? ''}</React.Fragment>
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carouselGrid: {
      alignSelf: 'center',
    },
    dividerGrid: { width: '100%', alignContent: 'stretch' },
    menuGrid: { width: '100%', alignContent: 'stretch', padding: '0px 8px' },
    menuList: { fontSize: theme.spacing(4), textAlign: 'center' },
    selfStart: { alignSelf: 'flex-start' },
    selfCenter: { alignSelf: 'center' },
    cardMedia: { height: 150, width: '100%' },
  }),
);

const parseLatLng = (latLng?: string): Coords | undefined => {
  if (!latLng) return undefined;
  const lat = parseFloat(latLng.split(',')[0]);
  const lng = parseFloat(latLng.split(',')[1]);

  return {
    lat,
    lng,
  };
};

export const RestaurantProfile = (): JSX.Element => {
  const classes = useStyles();

  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();
  const { areaCodeOptions } = useAreaCodes();
  const { loading, categoriesOptions, defaultCategories } = useCategories();

  const dispatch = useDispatch();

  const { restaurantCity, restaurantId } = useSelector(
    (appState: ApplicationState) => appState.restaurantUser,
  );

  useEffect(() => {
    dispatch(
      getRestaurant({
        id: restaurantId ?? '',
        city: restaurantCity ?? '',
      }),
    );
  }, []);

  const restState = useSelector(
    (appState: ApplicationState) => appState.restaurant,
  );

  const {
    address1,
    address2,
    city,
    country,
    zipCode,
    state,
    qrCode,
    latLng,
  } = restState;

  const [defaultValues, setDefaultValues] = useState<RestaurantProfileData>({
    ...restState,
    phoneCountry: areaCodeOptions.find(
      (code) => code.dial.toString() === restState.phoneNumber,
    ),
    hours: parseWeekHours(restState.hours),
    categories: [],
  });

  const [coordinates, setCoordinates] = useState<Coords | undefined>();

  useEffect(() => {
    setCoordinates(parseLatLng(latLng));
  }, [latLng]);

  const methods = useForm<RestaurantProfileData>({
    mode: 'onTouched',
    resolver,
    defaultValues: {
      ...restState,
      hours: parseWeekHours(restState.hours),
      categories: defaultCategories,
      phoneCountry: areaCodeOptions.find(
        (code) => code.dial.toString() === restState.phoneCountry,
      ),
    },
  });
  const { control, handleSubmit, register, errors, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    setDefaultValues({
      ...restState,
      phoneCountry: areaCodeOptions.find(
        (code) => code.dial.toString() === restState.phoneCountry,
      ),
      hours: parseWeekHours(restState.hours),
      categories: defaultCategories,
    });
  }, [restState]);

  const onSubmit = (data: RestaurantProfileSubmittedData) => {
    console.log('submitted data:', data);
    const mappedData: RestaurantProfileData = {
      ...data,
      hours: data.hours?.map((s) => mapRHFObjectToObject(s)) ?? [],
    };
    console.log('submitted data:', mappedData);
    dispatch(updateRestaurant(mappedData));
  };

  const areaCodesProps: AutoCompleteProps<Option> = {
    id: 'code-select',
    options: areaCodeOptions,
    classes: {
      option: globalClasses.option,
    },
    size: 'small',
    autoHighlight: true,
    getOptionLabel: (option: Option) => option?.label ?? '',
    renderOption,
    getOptionSelected,
    renderInput: renderInput(
      t('RestaurantSignUp.phoneCountry') as string,
      errors.phoneCountry?.code?.message ?? '',
      !!errors.phoneCountry,
    ),
  };

  const categoriesProps: AutoCompleteProps<Option> = {
    id: 'categories-select',
    loading,
    limitTags: 4,
    options: categoriesOptions,
    classes: {
      option: globalClasses.option,
    },
    defaultValue: [],
    multiple: true,
    autoHighlight: true,
    getOptionLabel: (option: Option) => option?.label ?? '',
    renderOption,
    renderInput: renderInput(t('RestaurantProfile.categoryLabel') as string),
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleSaveLocation = () => {
    if (coordinates) {
      const latLng = `${coordinates.lat},${coordinates.lng}`;
      dispatch(updateRestaurantLocation({ latLng }));
    }
    handleClose();
  };

  useEffect(() => {
    console.log('latLng:', latLng, 'coordinates:', coordinates);
  }, [coordinates, latLng]);
  return (
    <Fragment>
      <FormProvider {...methods}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={globalClasses.container}
        >
          <Grid
            item
            xs={12}
            sm={8}
            lg={4}
            className={`${globalClasses.verticalSpacing1}`}
          >
            <Typography variant="h4">{t('RestaurantProfile.title')}</Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={8}
            lg={4}
            className={`${globalClasses.verticalSpacing1}`}
          >
            <Grid item container xs={12} sm={8} lg={6}>
              <Grid
                item
                xs={12}
                className={`${globalClasses.horizontalSpacing1} ${classes.selfStart}`}
              >
                <Typography variant="subtitle1">
                  <strong>{t('RestaurantProfile.address')}</strong>
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                className={`${globalClasses.horizontalSpacing1} ${classes.selfStart}`}
              >
                <Typography variant="subtitle2">{address1}</Typography>
              </Grid>

              {address2 && (
                <Grid
                  item
                  xs={12}
                  className={`${globalClasses.horizontalSpacing1} ${classes.selfStart}`}
                >
                  <Typography variant="subtitle2">{address2}</Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                className={`${globalClasses.horizontalSpacing1} ${classes.selfStart}`}
              >
                <Typography variant="subtitle2">{`${city}, ${
                  state ? `${state},` : ''
                } ${zipCode}, ${country}`}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className={`${globalClasses.horizontalSpacing1} ${classes.selfStart}`}
              >
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<Icon>edit</Icon>}
                  onClick={handleOpen}
                  color="secondary"
                >
                  {t('RestaurantProfile.editLocation')}
                </Button>
              </Grid>
            </Grid>
            <Grid justify="center" item container xs={12} sm={8} lg={6}>
              <Grid item>
                <Typography variant="subtitle1">
                  <strong>{t('RestaurantProfile.qrCode')}</strong>
                </Typography>
              </Grid>

              <Grid item xs={12} />
              <Grid item>
                <img src={qrCode} width={75} />
              </Grid>
              <Grid item xs={12} />
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<Icon>download</Icon>}
                  href={qrCode ?? ''}
                  color="secondary"
                >
                  {t('Common.download')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            lg={4}
            className={globalClasses.dividerGrid}
          >
            <Divider />
          </Grid>

          <Grid container direction="column" item xs={12} sm={8} lg={4}>
            <Grid item container xs={12} justify="center">
              <Grid
                item
                xs={8}
                md
                className={`${globalClasses.verticalSpacing1}`}
              >
                <Typography>
                  {`Application Status: ${t(
                    `RestaurantProfile.Status.${restState.applicationStatus}`,
                  )}`}
                </Typography>
              </Grid>

              <Grid item xs={8} md={4} style={{ justifySelf: 'flex-end' }}>
                <RestaurantStatusSwitch />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  item
                  xs={12}
                  className={`${globalClasses.verticalSpacing2}`}
                >
                  <TextField
                    helperText={errors.name?.message}
                    error={!!errors.name}
                    fullWidth
                    id="name"
                    label={t('RestaurantSignUp.name')}
                    inputRef={register}
                    name="name"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={`${globalClasses.verticalSpacing2}`}
                >
                  <TextField
                    helperText={errors.description?.message}
                    error={!!errors.description}
                    fullWidth
                    id="description"
                    label={t('RestaurantSignUp.description')}
                    inputRef={register}
                    name="description"
                    multiline
                  />
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  className={`${globalClasses.verticalSpacing2} `}
                >
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <ComboBoxRender
                        {...areaCodesProps}
                        controllerprops={{
                          name: 'phoneCountry',
                          control: control,
                          defaultValue: areaCodeOptions[0],
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    className={`${globalClasses.horizontalSpacing1}`}
                  />

                  <Grid item xs>
                    <TextField
                      helperText={errors.phoneNumber?.message ?? ''}
                      error={!!errors.phoneNumber}
                      name="phoneNumber"
                      inputRef={register}
                      fullWidth
                      id="phoneNumber"
                      type="tel"
                      label={t('SignUp.phoneNumber')}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  className={`${globalClasses.verticalSpacing2} `}
                >
                  <Grid item xs>
                    <TextField
                      helperText={errors.website?.message ?? ''}
                      error={!!errors.website}
                      fullWidth
                      id="website"
                      name="website"
                      label={t('RestaurantSignUp.website')}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item className={globalClasses.horizontalSpacing1} />
                  <Grid item xs>
                    <TextField
                      helperText={errors.facebook?.message ?? ''}
                      error={!!errors.facebook}
                      fullWidth
                      id="facebook"
                      name="facebook"
                      label={t('RestaurantSignUp.facebook')}
                      inputRef={register}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={`${globalClasses.verticalSpacing2} `}
                >
                  <FormControl fullWidth>
                    <ComboBoxRender
                      {...categoriesProps}
                      controllerprops={{
                        name: 'categories',
                        control: control,
                        defaultValue: [],
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  className={`${globalClasses.verticalSpacing2} `}
                >
                  <SchedulesFieldArrayContainer />
                </Grid>

                <Grid item container className={globalClasses.verticalSpacing2}>
                  <Grid item xs className={globalClasses.horizontalSpacing1}>
                    <Button
                      type="submit"
                      fullWidth
                      color="primary"
                      variant="contained"
                    >
                      {t('Common.saveButton')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          <DevTool control={control} />
        </Grid>
      </FormProvider>
      <AlertDialog
        cancelText={t('Common.cancel') as string}
        confirmText={t('Common.saveButton') as string}
        title={'Change Google Maps Pin'}
        content={''}
        open={open}
        onClose={handleClose}
        onCancel={handleClose}
        onConfirm={handleSaveLocation}
      >
        <GoogleMapsDialog
          coordinates={coordinates}
          setCoordinates={setCoordinates}
        />
      </AlertDialog>
    </Fragment>
  );
};
