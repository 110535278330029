import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createStyles,
  Grid,
  GridList,
  GridListTile,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { InsertPhoto } from '@material-ui/icons';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGlobalStyles } from '../../../App';
import {
  ImageDimensions,
  useImageResizer,
} from '../../../hooks/useImageResizer';
import { ApplicationState } from '../../../store';
import { getRestaurantsByCategory } from '../../../store/user-home/actions';
import { Restaurant } from '../../../types/Restaurant';
import { RestaurantCategoryProps } from '../../../types/RestaurantCategoryProps';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      height: 92,
      width: 116,
      borderRadius: '0px 0px 4px 4px',
      borderWidth: '0px 1px 1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    },
    cardMedia: { height: 150, width: '100%' },
    cardText: { color: theme.palette.grey[400] },
    cardActionArea: {
      height: 275,
      width: 150,
    },
    gridList: { flexWrap: 'nowrap', margin: 0 },
    gridListTitle: {
      height: 'fit-content',
      width: 'fit-content',
    },
    gridTile: {
      marginRight: theme.spacing(1),
    },
    titleGrid: { textAlign: 'center' },
  }),
);

export const RestaurantCategory = ({
  title,
  city,
}: RestaurantCategoryProps): JSX.Element => {
  const restaurants = useSelector(
    (state: ApplicationState) =>
      state.userHome.restaurantsByCategory[title] ?? [],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRestaurantsByCategory({ category: title, city }));
  }, []);

  useEffect(() => {
    console.log(restaurants);
  }, [restaurants]);

  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const history = useHistory();

  const handleRestaurantClick = (restaurantId: string) => () => {
    history.push(`/restaurant/${city}/${restaurantId}`);
  };

  const handleViewAll = () => {
    history.push(`/categories/${city}/${title}`);
  };

  return (
    <Fragment>
      {restaurants.length > 0 && (
        <Grid container>
          <Grid
            item
            xs={12}
            container
            className={globalClasses.verticalSpacing1}
          >
            <Grid
              item
              xs={9}
              sm={10}
              className={globalClasses.horizontalSpacing1}
            >
              <Typography>{t(`Categories.${title}`)}</Typography>
            </Grid>
            <Grid item xs>
              <Button size="small" onClick={handleViewAll} color="primary">
                {t('Common.viewAllButton')}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <GridList cols={2.5} className={classes.gridList} cellHeight="auto">
              {restaurants.map((restaurant, index) => (
                <RestaurantCard
                  key={`RestCard-${index}-${restaurant.id}`}
                  restaurant={restaurant}
                  handleRestaurantClick={handleRestaurantClick}
                />
              ))}
            </GridList>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export interface RestaurantCardProps {
  restaurant: Restaurant;
  handleRestaurantClick: (restaurantId: string) => () => void;
  rows?: number;
  cols?: number;
}
export function RestaurantCard({
  handleRestaurantClick,
  restaurant: { id, photos, name, city, priceRange },
}: RestaurantCardProps): JSX.Element {
  const { height, width }: ImageDimensions = useImageResizer(
    photos && photos.length > 0 ? photos[0] : '',
    { width: 275, height: 150 },
    { width: 275, height: 150 },
    undefined,
    150,
  );
  const theme = useTheme();
  const classes = useStyles();
  return (
    <GridListTile
      style={{
        height: 'fit-content',
        width: 'fit-content',
      }}
      className={classes.gridTile}
    >
      <Card elevation={0}>
        <CardActionArea
          className={classes.cardActionArea}
          onClick={handleRestaurantClick(id ?? '')}
        >
          <Grid container justify="center">
            {photos && photos.length > 0 && (
              <Grid item>
                <CardMedia image={photos[0]} style={{ height, width }} />
              </Grid>
            )}
            {(!photos || photos.length == 0) && (
              <Paper
                elevation={0}
                style={{
                  backgroundColor: theme.palette.grey[100],
                }}
                className={classes.cardMedia}
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={classes.cardMedia}
                >
                  <Grid item>
                    <InsertPhoto style={{ fontSize: 48 }} />
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>
          <CardContent className={classes.cardContent}>
            <Typography variant="subtitle1">{name}</Typography>
            <Typography variant="subtitle2" className={classes.cardText}>
              {city}
            </Typography>
            <Typography variant="subtitle2" className={classes.cardText}>
              {priceRange}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </GridListTile>
  );
}
