import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getError } from '../../store/errors/selectors';
import { push } from 'connected-react-router';

export default function ErrorDialog(): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const unexpectedError = useSelector(getError('UNEXPECTED_ERROR'));
  const unauthorizedError = useSelector(getError('UNAUTHORIZED'));
  const [errorMessageKey, setErrorMessageKey] = useState(
    unexpectedError?.message ?? '',
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (unexpectedError) {
      setErrorMessageKey(unexpectedError?.message ?? '');
      setOpen(true);
    } else if (unauthorizedError) {
      setErrorMessageKey(unauthorizedError?.message ?? '');
      setOpen(true);
    } else setOpen(false);
  }, [unexpectedError]);

  const handleClose = () => {
    setOpen(false);
    if (unauthorizedError) dispatch(push('/home'));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t(`Errors.${errorMessageKey}Title`)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(`Errors.${errorMessageKey}`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {t('Common.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
