import { Action } from 'redux-ts-simple';

export const START_ACTION = '@@loader/START_ACTION';

export const STOP_ACTION = '@@loader/STOP_ACTION';

export const REFRESH_ACTION_START = '@@loader/REFRESH_ACTION_START';

export const REFRESH_ACTION_STOP = '@@loader/REFRESH_ACTION_STOP';

export interface LoaderState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: Action<any>[];
  refreshing: string[];
}
