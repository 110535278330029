import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { steps } from '../../../constants';
import { ApplicationState } from '../../../store';
import { RegistrationStepper } from '../stepper/Stepper';

export const RegistrationForm = (): JSX.Element => {
  const activeStep = useSelector(
    (appState: ApplicationState) => appState.restaurantForm.activeStep,
  );
  const [activeStepLocal, setActiveStep] = useState(0);
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    console.log('path:', path, steps[activeStepLocal]);
  }, []);

  const handleNext = () => {
    if (activeStepLocal < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      history.push('/restaurantsubmission');
    }
  };

  const handleBack = () => {
    if (activeStepLocal > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      history.push(`${path}${steps[activeStepLocal - 1].path}`);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Fragment>
      <RegistrationStepper
        steps={steps.map((step) => t(step.title) as string)}
        activeStep={activeStep}
      />
      <Switch>
        {steps.map((step) => (
          <Route
            key={`route-${step.title}`}
            exact
            path={`/restaurant-sign-up${step.path}`}
          >
            {<step.component {...{ ...step.props, handleNext, handleBack }} />}
          </Route>
        ))}
        <Route exact path={[`/restaurant-sign-up/*`, '/restaurant-sign-up']}>
          <Redirect to={`/restaurant-sign-up${steps[activeStep].path}`} />
        </Route>
      </Switch>
    </Fragment>
  );
};
