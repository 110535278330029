import {
  Button,
  createStyles,
  Grid,
  Icon,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useGlobalStyles } from '../../../App';
import { AdminMenuButtonProps } from '../../../types/AdminHomeButtonProps';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      flexDirection: 'column',
      justifyItems: 'center',
    },
  }),
);

export const AdminMenuButton = ({
  icon,
  text,
  onClick,
}: AdminMenuButtonProps): JSX.Element => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  return (
    <Grid
      style={{ textAlign: 'center' }}
      item
      className={globalClasses.verticalSpacing1}
      xs
      md={2}
    >
      <Button fullWidth onClick={onClick} classes={{ label: classes.label }}>
        <Icon fontSize="large">{icon}</Icon>
        <Typography
          variant="h6"
          style={{ textAlign: 'center' }}
          className={globalClasses.subtitle}
        >
          {text}
        </Typography>
      </Button>
    </Grid>
  );
};
