/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import { Delete, Save } from '@material-ui/icons';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UploadImage } from '../../upload-input/UploadImage';
import { useGlobalStyles } from '../../../App';
import { TitlebarGridList } from './TitlebarGridList';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import {
  getRestaurant,
  updatePhotos,
  uploadPhoto,
} from '../../../store/restaurant/actions';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      padding: theme.spacing(1),
    },
    gridList: {
      width: 1300,
      height: 800,
    },
    imageTile: {
      marginRight: theme.spacing(1),
    },
    icon: {
      color: theme.palette.secondary.main,

      marginRight: theme.spacing(1),
    },
    titleBar: {
      background: 'rgba(0,0,0,0) 70%',
    },
  }),
);

export const SelectedPhotosContext = React.createContext<{
  selectedPhotos: string[];
  isReordered: boolean;
  setIsReordered: (val: boolean) => void;
  selectPhoto: (src: string) => void;
  deselectPhoto: (src: string) => void;
}>({
  deselectPhoto: () => ({}),
  selectPhoto: () => ({}),
  isReordered: false,
  setIsReordered: () => ({}),
  selectedPhotos: [],
});

export interface DraggableCard {
  type: string;
  index: number;
}

export const Gallery = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { photos } = useSelector(
    (appState: ApplicationState) => appState.restaurant,
  );
  const { restaurantCity, restaurantId } = useSelector(
    (appState: ApplicationState) => appState.restaurantUser,
  );

  useEffect(() => {
    dispatch(
      getRestaurant({
        id: restaurantId ?? '',
        city: restaurantCity ?? '',
      }),
    );
  }, []);
  const dispatch = useDispatch();
  const [localPhotos, setLocalPhotos] = useState<string[]>(photos ?? []);

  useEffect(() => {
    setLocalPhotos(photos ?? []);
    setIsReordered(false);
  }, [photos]);

  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
  const [isReordered, setIsReordered] = useState(false);

  const selectPhoto = (src: string) => {
    setSelectedPhotos((prev) => [src, ...prev]);
  };
  const deselectPhoto = (src: string) => {
    setSelectedPhotos((prev) => prev.filter((p) => p !== src) ?? []);
  };
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const handleSave = () => {
    dispatch(updatePhotos(localPhotos));
  };

  const handleDelete = () => {
    const newPhotos = localPhotos.filter(
      (src) => !selectedPhotos.includes(src),
    );
    setSelectedPhotos([]);
    dispatch(updatePhotos(newPhotos));
  };
  const handleUpload = (newSrc: string) => {
    dispatch(uploadPhoto(newSrc));
  };
  return (
    <Grid container justify="center" className={globalClasses.container}>
      <Grid item container justify="center" xs={12}>
        <Grid item xs={1}>
          <UploadImage setSrc={handleUpload} />
        </Grid>
        {isReordered && (
          <Grid item xs={1}>
            <Button
              onClick={handleSave}
              variant="contained"
              size="small"
              style={{
                backgroundColor: theme.palette.success.main,
                color: theme.palette.common.white,
              }}
              startIcon={<Save />}
            >
              {t('Common.saveButton')}
            </Button>
          </Grid>
        )}
        {selectedPhotos.length > 0 && (
          <Grid item xs={1}>
            <Button
              onClick={handleDelete}
              size="small"
              variant="contained"
              style={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.common.white,
              }}
              startIcon={<Delete />}
            >
              {t('Common.deleteButton')}
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} className={globalClasses.verticalSpacing1} />
      {localPhotos.length === 0 && (
        <Paper elevation={18} variant="outlined" className={classes.paper}>
          <Typography variant="h6">
            {t('RestaurantGallery.noPhotos')}
          </Typography>
        </Paper>
      )}
      {localPhotos.length > 0 && (
        <Grid item container justify="center">
          <Grid item xs={12}>
            <SelectedPhotosContext.Provider
              value={{
                selectPhoto,
                deselectPhoto,
                selectedPhotos,
                isReordered,
                setIsReordered,
              }}
            >
              <TitlebarGridList
                setPhotos={setLocalPhotos}
                photos={localPhotos}
              />
            </SelectedPhotosContext.Provider>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
