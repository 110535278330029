import {
  Button,
  Collapse,
  createStyles,
  Divider,
  Grid,
  List,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  LocationOn,
  PhoneRounded,
  Schedule,
} from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGlobalStyles } from '../../../App';
import { ApplicationState } from '../../../store';
import { getMenu, getRestaurant } from '../../../store/pages/actions';
import { Days } from '../../../types/WeekHours';
import { DayHours } from './DayHours';
import { ItemsList } from './ItemsList';
import { PhotoCard } from './PhotoCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carouselGrid: {
      alignSelf: 'center',
    },
    dividerGrid: { width: '100%', alignContent: 'stretch' },
    menuGrid: { width: '100%', alignContent: 'stretch', padding: '0px 8px' },
    menuList: { fontSize: theme.spacing(4), textAlign: 'center' },
    selfStart: { alignSelf: 'flex-start' },
    selfCenter: { alignSelf: 'center' },
    cardMedia: { height: 150, width: '100%' },
  }),
);

export const RestaurantView = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    menu: { menuItems: items, sections: sectionList, id: menuId },
    restaurant: {
      city: restaurantCity,
      hours,
      address1,
      address2,
      zipCode,
      country,
      photo,
      name,
      state,
      photos,
      phoneNumber,
      latLng,
    },
  } = useSelector(
    (appState: ApplicationState) => appState.pages.restaurantView,
  );

  const { restaurantId, city } = useParams<{
    restaurantId: string;
    city: string;
  }>();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(getMenu({ restaurantId }));
    dispatch(getRestaurant({ restaurantId, city }));
  }, []);

  let allPhotos = [
    ...(photos ?? []),
    ...(items?.map((item) => item.photo) ?? []),
  ];
  allPhotos = photo ? [photo, ...allPhotos] : allPhotos;
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  return (
    <Grid container alignItems="stretch" direction="column">
      <Grid
        item
        xs={12}
        className={`${globalClasses.verticalSpacing1} ${classes.carouselGrid}`}
      >
        {allPhotos.length === 0 && <PhotoCard src={photo ?? ''} />}
        {allPhotos.length > 0 && (
          <Carousel
            indicators={false}
            navButtonsAlwaysVisible={allPhotos.length > 1}
            navButtonsAlwaysInvisible={allPhotos.length <= 1}
            animation="slide"
          >
            {allPhotos.map((photo, index) => (
              <PhotoCard key={index} src={photo ?? ''} />
            ))}
          </Carousel>
        )}
      </Grid>

      <Grid item xs={12} container className={globalClasses.verticalSpacing1}>
        <Grid item xs={6} className={globalClasses.horizontalSpacing1}>
          <Typography variant="subtitle1">
            <strong>{name}</strong>
          </Typography>
          <Typography variant="subtitle2">{`${restaurantCity}, ${country}`}</Typography>
        </Grid>
        {/* <Grid item container xs justify="space-evenly">
          <Grid item container direction="column" xs={7}>
            <Grid item xs>
              <Rating
                name="restaurant-rating"
                defaultValue={4.5}
                readOnly
                precision={0.5}
                size="small"
              />
            </Grid>
            <Grid item xs>
              <Typography variant="body2">5000+ Reviews</Typography>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ marginLeft: 4 }}>
            <Typography variant="subtitle1">
              <strong>4.5</strong> of 5
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>

      <Grid item xs={12} className={classes.dividerGrid}>
        <Divider />
      </Grid>

      <Grid item xs={12} container className={globalClasses.verticalSpacing1}>
        <Grid
          item
          xs={12}
          className={`${globalClasses.horizontalSpacing1} ${classes.selfCenter}`}
        >
          <Button
            fullWidth
            onClick={handleClick}
            endIcon={
              open ? (
                <ExpandLess fontSize="inherit" />
              ) : (
                <ExpandMore fontSize="inherit" />
              )
            }
          >
            <Typography
              style={{ textAlign: 'center' }}
              variant="h5"
            >{`Restaurant Information`}</Typography>
          </Button>
        </Grid>
        <Grid item xs>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container direction="column" alignItems="center">
              <Grid
                item
                container
                alignItems="center"
                className={globalClasses.verticalSpacing1}
              >
                <Grid item className={globalClasses.horizontalSpacing1}>
                  <PhoneRounded fontSize="small" />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    <strong>{`Phone Number`}</strong>
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs
                className={`${globalClasses.horizontalSpacing1} ${classes.selfStart}`}
              >
                <Typography variant="subtitle1">{phoneNumber}</Typography>
              </Grid>

              <Grid
                item
                container
                justify="flex-start"
                className={globalClasses.verticalSpacing1}
              >
                <Grid item className={globalClasses.horizontalSpacing1}>
                  <LocationOn fontSize="small" />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    <strong>{`Address`}</strong>
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs
                className={`${globalClasses.horizontalSpacing1} ${classes.selfStart}`}
                component={latLng ? 'a' : Grid}
                href={
                  latLng
                    ? `https://www.google.com/maps/search/?api=1&query=${latLng}`
                    : ''
                }
              >
                <Grid
                  item
                  xs
                  className={`${globalClasses.horizontalSpacing1} ${classes.selfStart}`}
                >
                  <Typography variant="subtitle2">{address1}</Typography>
                </Grid>
                {address2 && (
                  <Grid
                    item
                    xs
                    className={`${globalClasses.horizontalSpacing1} ${classes.selfStart}`}
                  >
                    <Typography variant="subtitle2">{address2}</Typography>
                  </Grid>
                )}
                <Grid
                  item
                  xs
                  className={`${globalClasses.horizontalSpacing1} ${classes.selfStart}`}
                >
                  <Typography variant="subtitle2">{`${restaurantCity}, ${
                    state ? `${state},` : ''
                  } ${zipCode}, ${country}`}</Typography>
                </Grid>
              </Grid>

              {hours && (
                <Grid
                  item
                  container
                  xs
                  alignItems="center"
                  className={globalClasses.verticalSpacing1}
                >
                  <Grid item className={globalClasses.horizontalSpacing1}>
                    <Schedule />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      <strong>{`Hours`}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {hours &&
                [
                  Days.sun,
                  Days.mon,
                  Days.tue,
                  Days.wed,
                  Days.thu,
                  Days.fri,
                  Days.sat,
                ].map((day, index) => {
                  return (
                    hours &&
                    hours[day].length > 0 && (
                      <DayHours
                        key={`day-${index}`}
                        day={t(`Common.weekDays.${day}`) as string}
                        openHours={hours[day]}
                      />
                    )
                  );
                })}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.dividerGrid}>
        <Divider />
      </Grid>

      <Grid
        item
        xs={12}
        className={`${globalClasses.verticalSpacing1} ${classes.menuGrid}`}
      >
        <List
          subheader={
            <Typography
              variant="h5"
              id="nested-list-subheader"
              className={classes.menuList}
            >
              {t('Menu.menu')}
            </Typography>
          }
        >
          {sectionList?.map((section, index) => {
            const sectionItems = items.filter(
              (item) => item.menuSection === section,
            );
            return (
              <ItemsList
                sectionName={t(`Menu.${section}`, section) as string}
                key={index}
                open={open}
                items={sectionItems}
                menuId={menuId}
              />
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};
