import React from 'react';
import {
  Avatar,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { MenuItemListViewProps } from '../../../types/MenuItemListViewProps';
import { Delete, Photo } from '@material-ui/icons';

function MenuItemListView({
  item,
  showDelete,
  handleOpenDialog,
  handleOpenItem,
}: MenuItemListViewProps): JSX.Element {
  const handleClick = () => {
    handleOpenItem(item);
  };

  const handleDeleteClick = () => {
    handleOpenDialog(item);
  };
  return (
    <Grid item xs>
      <ListItem key={item.id} button divider onClick={handleClick}>
        <ListItemAvatar>
          <Avatar src={item.photo}>
            <Photo />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={item.name}
          secondary={item.description}
          secondaryTypographyProps={{ noWrap: false }}
        />
        <ListItemSecondaryAction>
          <Grid container alignItems="center">
            <Grid item>
              <ListItemText primary={`$${item.minPrice}`} />
            </Grid>
            {showDelete && (
              <Grid item>
                <IconButton
                  onClick={handleDeleteClick}
                  edge="end"
                  aria-label="delete"
                >
                  <Delete />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </ListItemSecondaryAction>
      </ListItem>
    </Grid>
  );
}

export default MenuItemListView;
