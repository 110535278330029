import React from 'react';
import GridList from '@material-ui/core/GridList';
import { Paper } from '@material-ui/core';
import { ImageTile } from './ImageTile';
import { useStyles } from './Gallery';

export function TitlebarGridList({
  photos,
  setPhotos,
}: {
  photos: string[];
  setPhotos: (newPhotos: string[]) => void;
}): JSX.Element {
  const classes = useStyles();
  const swapCards = (from: number, to: number) => {
    const newPhotos = [...photos];
    newPhotos.splice(from, 1);
    const newArr = [
      ...newPhotos.slice(0, to),
      photos[from],
      ...newPhotos.slice(to),
    ];
    setPhotos(newArr);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={18} variant="outlined" className={classes.paper}>
        <GridList cellHeight={300} cols={4} spacing={0}>
          {photos.map((src, index) => (
            <ImageTile
              swap={swapCards}
              index={index}
              key={`tile-${src}`}
              src={src}
            />
          ))}
        </GridList>
      </Paper>
    </div>
  );
}
