/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import {
  getMenu,
  getRestaurant,
  loadMenuItemForm,
  sendDeleteMenuItem,
} from '../../../store/restaurant/actions';
import { MenuItem } from '../../../types/MenuItem';
import { MenuItemSubmitForm } from '../../../types/MenuItemForm';
import AlertDialog from '../alert-dialog/AlertDialog';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

function Menu(): JSX.Element {
  const {
    menu: { id: menuId },
    id: restaurantId,
    city: restaurantCity,
  } = useSelector((appState: ApplicationState) => appState.restaurant);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState<MenuItem>();
  const [open, setOpen] = useState(false);

  const { restaurantCity: city, restaurantId: id } = useSelector(
    (appState: ApplicationState) => appState.restaurantUser,
  );

  useEffect(() => {
    dispatch(
      getRestaurant({
        id: id ?? '',
        city: city ?? '',
      }),
    );
  }, []);

  const handleCloseDialog = () => setOpen(false);

  const handleOpenDialog = (item: MenuItem) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleOpenItem = (item: MenuItem) => {
    const menuItemForm: MenuItemSubmitForm = {
      ...item,
      optionLists: item.optionsLists.map((list) => ({ value: list })),
      menuSection: {
        code: item.menuSection,
        label: t(`MenuSections.${item.menuSection}`) as string,
      },
    };

    dispatch(loadMenuItemForm(menuItemForm));
    history.push('/menu-item');
  };

  const handleDeleteItem = () => {
    if (selectedItem)
      dispatch(
        sendDeleteMenuItem({
          menuItem: selectedItem,
          restaurantId: restaurantId ?? '',
          menuId: menuId ?? '',
          restaurantCity,
        }),
      );
    setOpen(false);
  };

  useEffect(() => {
    console.log('getting menu from rest:', restaurantId);
    if (restaurantId) dispatch(getMenu({ restaurantId }));
  }, [restaurantId]);

  return (
    <div>
      <Hidden smDown>
        <DesktopMenu
          handleOpenDialog={handleOpenDialog}
          handleOpenItem={handleOpenItem}
        />
      </Hidden>
      <Hidden mdUp>
        <MobileMenu
          handleOpenDialog={handleOpenDialog}
          handleOpenItem={handleOpenItem}
        />
      </Hidden>
      <AlertDialog
        cancelText={t('Common.no') as string}
        confirmText={t('Common.yes') as string}
        title={t('MenuItem.deleteItem?') as string}
        content={t('MenuItem.deleteItemText') as string}
        open={open}
        onClose={handleCloseDialog}
        onCancel={handleCloseDialog}
        onConfirm={handleDeleteItem}
      />
    </div>
  );
}

export default Menu;
