import {
  Grid,
  ListSubheader,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddress } from '../../../hooks/use-address/useAddress';
import { ApplicationState } from '../../../store';
import { getCategoriesList } from '../../../store/user-home/actions';
import { RestaurantCategory } from '../restaurant-category/RestaurantCategory';
import { Option } from '../../../types/Option';
import { AutoCompleteProps } from '../../../types/AutoCompleteProps';
import { useGlobalStyles } from '../../../App';
import { useTranslation } from 'react-i18next';
import { renderInput } from '../../../utils/components/AutoCompleteUtils';
import { Autocomplete } from '@material-ui/lab';
import { cities } from '../../../content/countries/us';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,

      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: number) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props, ref) {
    // eslint-disable-next-line react/prop-types
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;
    const ulRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
      if (ulRef.current) ulRef.current.style.padding = '0';
    }, [ulRef]);

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 36;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight()}
            innerRef={ulRef}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

interface CityOption extends Option {
  state: string;
}

export const Home = (): JSX.Element => {
  const categories = useSelector(
    (state: ApplicationState) =>
      state.userHome.categoriesList?.categories ?? [],
  );
  const { t } = useTranslation();
  const { selectedCountry, states } = useAddress('US', 'wa', 'Seattle');

  let allCities: CityOption[] = [];
  states.forEach((state) => {
    allCities = [
      ...allCities,
      ...cities[state].map((c) => ({
        code: c,
        label: `${c}`,
        state: `${t(`States.${state}`)}, ${selectedCountry?.toUpperCase()}`,
      })),
    ];
  });

  const [city, setCity] = useState<CityOption>({
    code: 'Seattle',
    label: 'Seattle',
    state: 'Washington, US',
  });

  const classes = useGlobalStyles();

  const cityProps: AutoCompleteProps<CityOption> = {
    id: 'city-select',
    options: allCities,
    classes: {
      option: classes.option,
      listbox: classes.listbox,
    },
    disableListWrap: true,
    ListboxComponent: ListboxComponent as React.ComponentType<
      React.HTMLAttributes<HTMLElement>
    >,
    renderGroup: (params) => [
      <ListSubheader key={params.key} component="div">
        {params.group}
      </ListSubheader>,
      params.children,
    ],
    groupBy: (option) => option.state.toUpperCase().trim(),
    size: 'small',
    autoHighlight: true,
    value: city,
    onChange: (
      event: React.ChangeEvent<unknown>,
      value: string | CityOption | (string | CityOption)[] | null,
    ) => {
      const option = value as CityOption;
      if (option) setCity(option);
    },
    getOptionLabel: (option: CityOption) => option.label,
    getOptionSelected: (option, value) => option.code === value.code,
    filterOptions: (options, state) =>
      options.filter(
        (option) =>
          option.code.toUpperCase().includes(state.inputValue.toUpperCase()) ||
          option.state.toUpperCase().includes(state.inputValue.toUpperCase()),
      ),
    renderOption: (option: CityOption) => option.label,
    renderInput: renderInput(t('RestaurantSignUp.city') as string),
  };

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('city:', city);
    dispatch(getCategoriesList({ city: city.code }));
  }, [city]);

  return (
    <Fragment>
      <Grid container justify="center" className={classes.container}>
        <Grid
          style={{ justifySelf: 'flex-start' }}
          container
          item
          xs={12}
          sm={6}
          className={classes.verticalSpacing1}
        >
          <Grid
            style={{ justifySelf: 'flex-start' }}
            item
            xs={12}
            md={6}
            className={classes.horizontalSpacing1}
          >
            <Autocomplete {...cityProps} debug />
          </Grid>
        </Grid>
        <Grid item xs={12} />
        <Grid
          container
          item
          xs={12}
          lg={6}
          className={`${classes.verticalSpacing1} ${classes.horizontalSpacing1}`}
        >
          {categories.map((category) => (
            <Grid key={`${category}-category`} item xs={12}>
              <RestaurantCategory city={city.code} title={category} />
            </Grid>
          ))}
          {categories.length === 0 && (
            <Typography variant="h4">
              There are no restaurants for this city yet :(
            </Typography>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
