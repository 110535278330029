/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, createAction } from 'redux-ts-simple';
import {
  REFRESH_ACTION_START,
  REFRESH_ACTION_STOP,
  START_ACTION,
  STOP_ACTION,
} from './types';

export const startAction = createAction<Action<any>>(START_ACTION);

export const stopAction = createAction<{ type: string }>(STOP_ACTION);

export const refreshActionStart = createAction<{ refreshAction: string }>(
  REFRESH_ACTION_START,
);

export const refreshActionStop = createAction<{ refreshAction: string }>(
  REFRESH_ACTION_STOP,
);
