import { RestaurantAboutInfo } from '../../types/RestaurantLocationInfo';
import { createAction } from 'redux-ts-simple';

import {
  HANDLE_BACK,
  HANDLE_NEXT,
  HANDLE_RESET,
  SEND_RESTAURANT_ACCOUNT_INFO,
  SEND_RESTAURANT_CONTACT_INFO,
  SEND_RESTAURANT_LOCATION_INFO,
  SUBMIT_RESTAURANT_ACCOUNT_INFO,
  SUBMIT_RESTAURANT_CONTACT_INFO,
  SUBMIT_RESTAURANT_LOCATION_INFO,
} from './types';
import { RestaurantContactInfo } from '../../types/RestaurantContactInfo';
import { RestaurantAccountInfo } from '../../types/RestaurantAccountInfo';

export const sendRestaurantLocationInfo = createAction<RestaurantAboutInfo>(
  SEND_RESTAURANT_LOCATION_INFO,
);

export const submitRestaurantLocationInfo = createAction<RestaurantAboutInfo>(
  SUBMIT_RESTAURANT_LOCATION_INFO,
);

export const sendRestaurantContactInfo = createAction<RestaurantContactInfo>(
  SEND_RESTAURANT_CONTACT_INFO,
);

export const submitRestaurantContactInfo = createAction<RestaurantContactInfo>(
  SUBMIT_RESTAURANT_CONTACT_INFO,
);

export const sendRestaurantAccountInfo = createAction<RestaurantAccountInfo>(
  SEND_RESTAURANT_ACCOUNT_INFO,
);

export const submitRestaurantAccountInfo = createAction<RestaurantAccountInfo>(
  SUBMIT_RESTAURANT_ACCOUNT_INFO,
);

export const handleNext = createAction(HANDLE_NEXT);

export const handleBack = createAction(HANDLE_BACK);

export const handleReset = createAction(HANDLE_RESET);
