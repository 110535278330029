import { ApplicationError } from '../../types/Error';

export const ADD_ERROR = '@@loader/ADD_ERROR';

export const CLEAR_ERROR = '@@loader/CLEAR_ERROR';

export interface ErrorsState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: ApplicationError[];
  refreshing: string[];
}
