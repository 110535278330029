import { CategoriesList } from '../../types/CategoriesList';
import { ValueMap } from '../../types/ValueMap';
import { Restaurant } from '../../types/Restaurant';

export const GET_RESTAURANTS_BY_CATEGORY =
  '@@userHome/GET_RESTAURANTS_BY_CATEGORY';

export const SAVE_RESTAURANTS_BY_CATEGORY =
  '@@userHome/SAVE_RESTAURANTS_BY_CATEGORY';

export const GET_CATEGORIES_LIST = '@@userHome/GET_CATEGORIES_LIST';

export const SAVE_CATEGORIES_LIST = '@@userHome/SAVE_CATEGORIES_LIST';

export interface UserHomeState {
  categoriesList?: CategoriesList;
  restaurantsByCategory: ValueMap<Restaurant[]>;
}
