/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError, Resolver } from 'react-hook-form';
import validator from 'validator';
import i18n from '../../../../i18n';
import { LoginFormData } from '../../../../types/LoginFormData';
import {
  validate,
  Validation,
} from '../../../../utils/rhf/validationFunctions';

export const validateEmail = (email: string): FieldError | undefined => {
  if (validate(email, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
  if (!validator.isEmail(email))
    return {
      type: 'invalidEmailFormat',
      message: i18n.t('MenuItem.errorMessages.invalidEmailFormat'),
    };
};

export const validatePassword = (password: string): FieldError | undefined => {
  if (validate(password, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
};

type UserFormDataKeys = keyof LoginFormData;
export type UserFormDataErrors = {
  [key in UserFormDataKeys]?: FieldError;
};

export const resolver: Resolver<LoginFormData, any> = async (
  values: LoginFormData,
) => {
  const errors: UserFormDataErrors = {};
  errors.email = validateEmail(values.email);
  errors.password = validatePassword(values.password);

  const result = {
    values: {},
    errors: {},
  };

  if (errors.email || errors.password) result.errors = errors;
  else result.values = values;

  return new Promise((resolve) => {
    resolve(result);
  });
};
