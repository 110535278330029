import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
} from '@material-ui/core';
import React, { ChangeEvent, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useGlobalStyles } from '../../../App';
import { ItemOptionViewProps } from '../../../types/ItemOptionViewProps';
import { MenuItemForm } from '../../../types/MenuItemForm';
import { MenuItemOption } from '../../../types/MenuItemOption';
import { getTypes } from '../option-lists-container/OptionListView';
import { OptionChip } from './OptionChip';

const defaultOption: MenuItemOption = {
  extraCharge: 0,
  name: '',
  isDefault: false,
};

const newOption = () => ({ ...defaultOption, key: uuidv4() });

export const ItemOptionView = ({
  optionList: { options },
  setOptionList,
  currentIndex,
}: ItemOptionViewProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useGlobalStyles();
  const { errors: formErrors } = useFormContext<MenuItemForm>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputRef = useRef<any>();

  const errors = getTypes(currentIndex, formErrors);

  const optionErrorMessage = options
    .map(({ name }) => {
      if (errors && errors[`${name}-option`])
        return errors[`${name}-option`] as string;
    })
    .filter((message) => message !== undefined);

  const [option, setOption] = useState<MenuItemOption>(newOption());

  const changeOptionName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setOption((prevOption) => {
      return {
        ...prevOption,
        name: event.target.value,
      };
    });
  };

  const changeOptionPrice = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setOption((prevOption) => {
      return {
        ...prevOption,
        extraCharge: event.target.value
          ? parseFloat(parseFloat(event.target.value).toFixed(2))
          : 0,
      };
    });
  };

  const changeOptionDefault = (event: ChangeEvent<HTMLInputElement>) => {
    setOption((prevOption) => {
      return {
        ...prevOption,
        isDefault: event.target.checked,
      };
    });
  };

  const addOption = () => {
    if (option.name) {
      const trimmedOption: MenuItemOption = {
        ...option,
        name: option.name.trim(),
      };
      setOptionList((prevList) => {
        let tempList: MenuItemOption[] = prevList.options;
        let oldDefault: MenuItemOption | undefined;
        const found = tempList.find(
          (op) => op.key?.toLowerCase() === trimmedOption.key?.toLowerCase(),
        );
        if (found) {
          tempList = tempList.filter(
            (op) => op.key?.toLowerCase() !== trimmedOption.key?.toLowerCase(),
          );
        }
        if (trimmedOption.isDefault) {
          oldDefault = tempList.find((op) => op.isDefault);
          tempList = tempList.filter((op) => !op.isDefault);
          if (oldDefault) {
            tempList = [...tempList, { ...oldDefault, isDefault: false }];
          }
        }
        const newOptions = [...tempList, trimmedOption];
        return { ...prevList, options: newOptions };
      });
      setOption(newOption());
      inputRef?.current?.focus();
    }
  };

  const deleteOption = (key: string) => () => {
    setOptionList((prevList) => {
      const newOptions = prevList.options.filter(
        (op) => key.toLowerCase() !== op.key?.toLowerCase(),
      );
      return { ...prevList, options: newOptions };
    });
  };

  const selectOption = (option: MenuItemOption) => () => {
    setOption(option);
  };

  return (
    <div>
      <Grid
        container
        item
        alignItems="center"
        xs={12}
        className={classes.verticalSpacing1}
      >
        <Grid item xs={5} className={classes.fieldHorizontalSpacing}>
          <TextField
            inputRef={inputRef}
            size="small"
            id="optionName"
            label={t('MenuItem.optionName')}
            name="optionName"
            value={option.name}
            onChange={changeOptionName}
          />
        </Grid>
        <Grid item xs={4} className={classes.fieldHorizontalSpacing}>
          <TextField
            size="small"
            id="extraCharge"
            type="number"
            inputProps={{
              step: '0.01',
              pattern: /\d+\.\d{2}/,
              min: 0,
            }}
            label={t('MenuItem.extraCharge')}
            name="extraCharge"
            onChange={changeOptionPrice}
            value={option.extraCharge}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                name="default"
                color="primary"
                checked={option.isDefault}
                onChange={changeOptionDefault}
              />
            }
            label={t('MenuItem.defaultOption')}
            labelPlacement="end"
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        xs={12}
        className={classes.verticalSpacing1}
      >
        <Grid item xs>
          <Button color="primary" variant="contained" onClick={addOption}>
            {t('Common.saveButton')}
          </Button>
        </Grid>
      </Grid>
      {options.map((option) => {
        return (
          <OptionChip
            key={`${option.name}`}
            option={option}
            selectOption={selectOption}
            error={!!errors && !!errors[`${option.name}-option`]}
            deleteOption={deleteOption}
          />
        );
      })}
      {optionErrorMessage.map((message, index) => (
        <FormHelperText key={`formhelper-text-${index}`} error>
          {message}
        </FormHelperText>
      ))}
    </div>
  );
};
