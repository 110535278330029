import React from 'react';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import { Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { LoginFormData } from '../../../../types/LoginFormData';
import { useDispatch } from 'react-redux';
import { useGlobalStyles } from '../../../../App';
import { resolver } from './resolver';
import { PasswordInput } from '../../../../utils/components/PasswordInput';
import { restaurantUserEmailLogin } from '../../../../store/restaurant-user/actions';

function SignIn(): JSX.Element {
  const { handleSubmit, register, errors } = useForm<LoginFormData>({
    resolver,
  });
  const dispatch = useDispatch();

  const onSubmit = (data: LoginFormData) => {
    console.log('data:', data);
    dispatch(restaurantUserEmailLogin(data));
  };
  const { t } = useTranslation();
  const classes = useGlobalStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Grid item xs={12} sm={8} lg={4}>
        <Grid item>
          <Typography variant="h4">{t('RestaurantSignUp.login')}</Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} className={classes.verticalSpacing1}>
              <TextField
                helperText={errors?.email?.message ?? ''}
                error={!!errors?.email}
                fullWidth
                size="small"
                id="email"
                name="email"
                type="email"
                label={t('SignUp.emailLabel')}
                inputRef={register}
              />
            </Grid>

            <Grid item xs={12} className={classes.verticalSpacing1}>
              <PasswordInput
                helperText={errors?.password?.message ?? ''}
                error={!!errors?.password}
                fullWidth
                id="password"
                name="password"
                label={t('SignUp.passwordLabel')}
                inputRef={register}
                size="small"
              />
            </Grid>

            <Grid item xs={12} className={classes.verticalSpacing1}>
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
              >
                {t('SignUp.signIn')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default SignIn;
