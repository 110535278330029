import {
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGlobalStyles } from '../../../App';
import { ApplicationState } from '../../../store';
import { getItem } from '../../../store/pages/actions';
import { PhotoCard } from '../restaurant-view/PhotoCard';
import { OptionListDropdown } from './OptionListDropdown';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carouselGrid: {
      alignSelf: 'center',
    },
    dividerGrid: { width: '100%', alignContent: 'stretch' },
    menuGrid: { width: '100%', alignContent: 'stretch', padding: '0px 8px' },
    menuList: { fontSize: theme.spacing(4), textAlign: 'center' },
    selfStart: { alignSelf: 'flex-start' },
    selfCenter: { alignSelf: 'center' },
    cardMedia: { height: 150, width: '100%' },
  }),
);

export const ItemView = (): JSX.Element => {
  const { t } = useTranslation();
  const { itemId, menuId } = useParams<{ menuId: string; itemId: string }>();
  const dispatch = useDispatch();
  const { description, minPrice, name, optionsLists, photo } = useSelector(
    (appState: ApplicationState) => appState.pages.itemView,
  );

  useEffect(() => {
    dispatch(getItem({ menuId, menuItemId: itemId }));
  }, []);

  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  return (
    <Grid container alignItems="center" direction="column">
      <Grid
        item
        xs={12}
        sm={6}
        className={`${globalClasses.verticalSpacing1} ${classes.carouselGrid}`}
      >
        <PhotoCard src={photo ?? ''} />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        container
        className={globalClasses.verticalSpacing1}
      >
        <Grid item xs={12} className={globalClasses.horizontalSpacing1}>
          <Typography variant="h6">
            <strong>{`${name}\t$${minPrice}`}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} className={globalClasses.horizontalSpacing1}>
          <Typography variant="subtitle1">{description}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} className={classes.dividerGrid}>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={6} className={globalClasses.verticalSpacing1}>
        <Typography variant="subtitle1">
          {t('ItemView.chooseOptions')}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} className={classes.dividerGrid}>
        <Divider />
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={6}
        className={globalClasses.verticalSpacing1}
        justify="center"
      >
        {optionsLists.map((optionsList, index) => (
          <OptionListDropdown
            key={optionsList.key ?? index}
            optionsList={optionsList}
          />
        ))}
      </Grid>
    </Grid>
  );
};
