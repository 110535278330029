import { Checkbox, Grid, Typography } from '@material-ui/core';
import React from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ItemComboboxOptionViewProps } from '../../../types/ItemComboboxOptionViewProps';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ItemComboboxOptionView = ({
  option,
  state: { selected },
}: ItemComboboxOptionViewProps): JSX.Element => (
  <Grid container alignItems="center">
    <Grid item xs>
      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
    </Grid>
    <Grid item xs={9}>
      <Typography variant="subtitle1">{option.label} </Typography>
    </Grid>
    <Grid item xs>
      <Typography variant="subtitle1">
        {`+$${option.extraCharge.toFixed(2)}`}{' '}
      </Typography>
    </Grid>
  </Grid>
);
