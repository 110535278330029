import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import {
  Grid,
  TextField,
  FormControl,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { Option } from '../../../../types/Option';
import { ComboBoxRender } from '../../../../utils/rhf/Controllers';
import { sendRestaurantContactInfo } from '../../../../store/restaurant-registration/actions';
import { areaCodes } from '../../../../constants';
import { AutoCompleteProps } from '../../../../types/AutoCompleteProps';
import { RestaurantContactInfo } from '../../../../types/RestaurantContactInfo';
import { ApplicationState } from '../../../../store';
import { useGlobalStyles } from '../../../../App';
import { RegistrationStepProps } from '../../../../types/RegistrationStepProps';
import { resolver } from './resolver';
import {
  getOptionSelected,
  renderInput,
} from '../../../../utils/components/AutoCompleteUtils';
import { AreaCodeOption } from '../../../../types/AreaCodeOption';

export type RestaurantContactInfoProps = RegistrationStepProps;

function ContactInfo({ handleBack }: RestaurantContactInfoProps): JSX.Element {
  const defaultValues: RestaurantContactInfo = useSelector(
    (appState: ApplicationState) => ({ ...appState.restaurantForm }),
  );
  const dispatch = useDispatch();

  const isLoading: boolean = useSelector((appState: ApplicationState) => {
    return !!appState.loader.actions.find(
      (action) => action.type === sendRestaurantContactInfo.type,
    );
  });
  const {
    control,
    handleSubmit,
    register,
    errors,
  } = useForm<RestaurantContactInfo>({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  const handleBackButton = () => {
    handleBack();
  };
  const onSubmit = (data: RestaurantContactInfo) => {
    console.log('submitted data:', data);
    dispatch(sendRestaurantContactInfo(data));
  };
  const { t } = useTranslation();
  const classes = useGlobalStyles();

  const areaCodeOptions: AreaCodeOption[] = areaCodes.map(({ code, dial }) => ({
    code,
    dial,
    label: t(`CountryAreaCodes.${code}`) as string,
  }));

  const renderOption = (option: Option) => (
    <React.Fragment>{option.label}</React.Fragment>
  );

  const areaCodesProps: AutoCompleteProps<Option> = {
    id: 'code-select',
    options: areaCodeOptions,
    classes: {
      option: classes.option,
    },
    size: 'small',
    autoHighlight: true,
    getOptionLabel: (option: Option) => option.label,
    renderOption,
    getOptionSelected,
    renderInput: renderInput(
      t('RestaurantSignUp.phoneCountry') as string,
      errors.phoneCountry?.code?.message ?? '',
      !!errors.phoneCountry,
    ),
  };

  const ownerAreaCodeProps: AutoCompleteProps<Option> = {
    ...areaCodesProps,
    renderInput: renderInput(
      t('RestaurantSignUp.phoneCountry') as string,
      errors.ownerPhoneCountry?.code?.message ?? '',
      !!errors.ownerPhoneCountry,
    ),
  };

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.container}
      >
        <Grid container direction="column" item xs={12} sm={8} lg={4}>
          <Typography variant="h4" className={`${classes.horizontalSpacing1}`}>
            {t('RestaurantSignUp.contactInfo')}
          </Typography>
          <Typography
            variant="h6"
            className={`${classes.subtitle} ${classes.verticalSpacing2} ${classes.horizontalSpacing1}`}
          >
            {t('RestaurantSignUp.restaurant')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item container direction="column">
              <Grid
                item
                xs={12}
                className={`${classes.horizontalSpacing1} ${classes.verticalSpacing1}`}
              >
                <TextField
                  helperText={errors.email?.message ?? ''}
                  error={!!errors.email}
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  label={t('RestaurantSignUp.restaurantEmail')}
                  inputRef={register}
                />
              </Grid>

              <Grid item container xs={12} className={classes.verticalSpacing1}>
                <Grid item xs={3} className={classes.horizontalSpacing1}>
                  <FormControl fullWidth>
                    <ComboBoxRender
                      {...areaCodesProps}
                      controllerprops={{
                        name: 'phoneCountry',
                        control: control,
                        defaultValue: areaCodeOptions[0],
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs className={classes.horizontalSpacing1}>
                  <TextField
                    helperText={errors.phoneNumber?.message ?? ''}
                    error={!!errors.phoneNumber}
                    name="phoneNumber"
                    inputRef={register}
                    fullWidth
                    id="phoneNumber"
                    type="tel"
                    label={t('SignUp.phoneNumber')}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} className={classes.verticalSpacing1}>
                <Grid item xs className={classes.horizontalSpacing1}>
                  <TextField
                    helperText={errors.website?.message ?? ''}
                    error={!!errors.website}
                    fullWidth
                    id="website"
                    name="website"
                    label={t('RestaurantSignUp.website')}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs className={classes.horizontalSpacing1}>
                  <TextField
                    helperText={errors.facebook?.message ?? ''}
                    error={!!errors.facebook}
                    fullWidth
                    id="facebook"
                    name="facebook"
                    label={t('RestaurantSignUp.facebook')}
                    inputRef={register}
                  />
                </Grid>
              </Grid>

              <Typography
                variant="h6"
                className={`${classes.subtitle} ${classes.verticalSpacing2} ${classes.horizontalSpacing1}`}
              >
                {t('RestaurantSignUp.owner')}
              </Typography>

              <Grid container item xs={12} className={classes.verticalSpacing1}>
                <Grid item xs className={classes.horizontalSpacing1}>
                  <TextField
                    helperText={errors.ownerFirstName?.message ?? ''}
                    error={!!errors.ownerFirstName}
                    fullWidth
                    id="ownerFirstName"
                    name="ownerFirstName"
                    label={t('SignUp.firstNameLabel')}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs className={classes.horizontalSpacing1}>
                  <TextField
                    helperText={errors.ownerLastName?.message ?? ''}
                    error={!!errors.ownerLastName}
                    fullWidth
                    id="ownerLastName"
                    name="ownerLastName"
                    label={t('SignUp.lastNameLabel')}
                    inputRef={register}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} className={classes.verticalSpacing1}>
                <Grid item xs={3} className={classes.horizontalSpacing1}>
                  <FormControl fullWidth>
                    <ComboBoxRender
                      {...ownerAreaCodeProps}
                      controllerprops={{
                        name: 'ownerPhoneCountry',
                        control: control,
                        defaultValue: areaCodeOptions[0],
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs className={classes.horizontalSpacing1}>
                  <TextField
                    helperText={errors.ownerPhoneNumber?.message ?? ''}
                    error={!!errors.ownerPhoneNumber}
                    name="ownerPhoneNumber"
                    inputRef={register}
                    fullWidth
                    id="ownerPhoneNumber"
                    type="tel"
                    label={t('RestaurantSignUp.phoneNumber')}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.verticalSpacing1}>
                <Grid item xs className={classes.horizontalSpacing1}>
                  <Button
                    onClick={handleBackButton}
                    fullWidth
                    color="primary"
                    variant="contained"
                  >
                    {t('Common.backButton')}
                  </Button>
                </Grid>
                <Grid item xs className={classes.horizontalSpacing1}>
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                  >
                    {t('Common.submitButton')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <DevTool control={control} />
      </Grid>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default ContactInfo;
