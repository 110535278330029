import { Grid } from '@material-ui/core';
import React, {
  BaseSyntheticEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useGlobalStyles } from '../../../App';
import MapAutocomplete from '../../guest/home/MapsAutocomplete';
import GoogleMap from 'google-map-react';

import { GoogleMaps, Marker } from '../../guest/home/GoogleMapsTypes';
import { StringMap } from 'i18next';

const API_KEY = process.env.REACT_APP_MAPS_API_KEY ?? '';

export const GoogleMapsDialog = ({
  coordinates,
  setCoordinates,
}: {
  coordinates?: GoogleMap.Coords;
  setCoordinates: Dispatch<SetStateAction<GoogleMap.Coords | undefined>>;
}): JSX.Element => {
  const globalClasses = useGlobalStyles();

  const apiIsLoaded = (map: StringMap, maps: GoogleMaps) => {
    console.log(map);
    setMap(map);
    setMaps(maps);
  };
  const _onClick = ({
    x,
    y,
    lat,
    lng,
    event,
  }: {
    y: number;
    x: number;
    lat: number;
    lng: number;
    event: BaseSyntheticEvent;
  }) => {
    console.log(x, y, lat, lng, event);
    setCoordinates({ lat, lng });
    setPlace(undefined);
  };
  const [map, setMap] = useState<StringMap>();
  const [maps, setMaps] = useState<GoogleMaps>();
  const [place, setPlace] = useState<GoogleMap.Coords>();
  const [marker, setMarker] = useState<Marker>();
  useEffect(() => {
    if (place) {
      console.log(place);
      setCoordinates(undefined);
    }
  }, [place]);

  useEffect(() => {
    if (coordinates && marker) {
      marker.setPosition(coordinates);
    } else if (coordinates && maps)
      setMarker(new maps.Marker({ position: coordinates, map }));
    console.log('coordinates:', coordinates, 'marker:', marker);
  }, [coordinates, maps, marker]);

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={globalClasses.container}
      >
        <Grid item xs className={globalClasses.verticalSpacing1}>
          <MapAutocomplete setPlace={setCoordinates} maps={maps} map={map} />
        </Grid>
        <Grid item xs className={globalClasses.verticalSpacing1}>
          <div style={{ width: '320px', height: '320px' }}>
            <GoogleMap
              onClick={_onClick}
              defaultZoom={15}
              defaultCenter={coordinates ?? { lat: 34.0522, lng: -118.2437 }}
              center={coordinates}
              options={{
                clickableIcons: false,
                styles: [
                  {
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [{ visibility: 'on' }],
                  },
                ],
              }}
              bootstrapURLKeys={{
                key: API_KEY,
                language: 'en',
                region: 'us',
                libraries: ['places', 'geometry', 'drawing'],
              }}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            ></GoogleMap>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};
