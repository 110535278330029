import {
  Card,
  CardActionArea,
  CardMedia,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  useTheme,
} from '@material-ui/core';
import { InsertPhoto } from '@material-ui/icons';
import React from 'react';
import {
  ImageDimensions,
  useImageResizer,
} from '../../../hooks/useImageResizer';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      height: 'fit-content',
      width: 'fit-content',
    },
    cardMedia: {
      height: 280,
      width: '100%',
    },

    cardActionArea: {
      height: 280,
      width: 280,
    },
  }),
);

export const PhotoCard = ({ src }: { src: string }): JSX.Element => {
  const { height, width }: ImageDimensions = useImageResizer(
    src,
    { width: 320, height: 320 },
    { width: 280, height: 280 },
  );
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Card className={classes.card} elevation={0}>
      <CardActionArea style={{ height, width }}>
        {src && <CardMedia image={src} style={{ height, width }} />}
        {!src && (
          <Paper
            elevation={0}
            style={{
              backgroundColor: theme.palette.grey[100],
              height,
              width,
            }}
          >
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height, width }}
            >
              <Grid item>
                <InsertPhoto style={{ fontSize: 48 }} />
              </Grid>
            </Grid>
          </Paper>
        )}
      </CardActionArea>
    </Card>
  );
};
