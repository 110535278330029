import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { Option } from '../../types/Option';

export interface UseCategoriesValues {
  defaultCategories: Option[];
  categoriesOptions: Option[];
  loading: boolean;
}

const API_URL = process.env.REACT_APP_API_URL ?? '';

export const useCategories = (): UseCategoriesValues => {
  const { t } = useTranslation();

  const [{ loading, data, error }, fetch] = useAxios({
    url: `${API_URL}/jartera/categories/available/normal`,
  });
  const [availableCategories, setAvailableCategories] = useState<string[]>([]);

  useEffect(() => {
    if (data) setAvailableCategories(data);
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error(error);
      fetch();
    }
  }, [error]);

  const categoriesOptions: Option[] = (
    availableCategories.map((code) => ({
      code,
      label: t(`Categories.${code}`) as string,
    })) ?? []
  ).sort((a, b) => a.code.localeCompare(b.code));

  const restState = useSelector(
    (appState: ApplicationState) => appState.restaurant,
  );

  const defaultCategories =
    categoriesOptions.filter((cat) =>
      (restState.categories?.map((c) => c.id) ?? []).includes(cat.code),
    ) ?? [];

  return { defaultCategories, loading, categoriesOptions };
};
