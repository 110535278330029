import { ReducerBuilder } from 'redux-ts-simple';
import {
  refreshActionStart,
  refreshActionStop,
  startAction,
  stopAction,
} from './actions';
import { LoaderState } from './types';

export const initialLoaderState: LoaderState = {
  actions: [],
  refreshing: [],
};

export const loaderReducer = new ReducerBuilder(initialLoaderState)
  .on(startAction, (state, action) => ({
    ...state,
    actions: [...state.actions, action.payload],
  }))
  .on(stopAction, (state, action) => ({
    ...state,
    actions: state.actions.filter((act) => act.type !== action.payload.type),
  }))
  .on(refreshActionStart, (state, action) => ({ ...state, ...action.payload }))
  .on(refreshActionStop, (state, action) => ({ ...state, ...action.payload }))
  .else((state) => state)
  .build();
