/* eslint-disable @typescript-eslint/no-empty-function */
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IAdminMenuService } from '../../types/IAdminMenuService';
import { Menu } from '../../types/Menu';
import { MenuItem } from '../../types/MenuItem';
import { authPost, get } from '../../utils/services/axiosWrappers';

const API_URL = process.env.REACT_APP_API_URL ?? '';

@injectable()
export class AdminMenuService implements IAdminMenuService {
  public async getMenuByRestaurant(
    restaurantId: string,
  ): Promise<AxiosResponse<Menu>> {
    try {
      console.log('get menu by restaurant:', restaurantId);
      return await get(`${API_URL}/jartera/menus/${restaurantId}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async createMenuItem(
    menuItem: MenuItem,
    restaurantId = '',
    menuId = '',
  ): Promise<AxiosResponse<MenuItem>> {
    try {
      console.log('create menu item', menuItem);
      return await authPost(`${API_URL}/jartera/menuitems/add`, {
        ...menuItem,
        restaurantId,
        menuId,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getMenuItem(
    menuItemId: string,
    menuId: string,
  ): Promise<AxiosResponse<MenuItem>> {
    try {
      console.log('get menu item', menuItemId, menuId);
      return await get(`${API_URL}/jartera/menuitems/${menuItemId}/${menuId}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async updateMenuItem(
    menuItem: MenuItem,
    restaurantId = '',
    menuId = '',
  ): Promise<AxiosResponse<MenuItem>> {
    try {
      console.log('update menu item', menuItem);
      return await authPost(`${API_URL}/jartera/menuitems/update`, {
        ...menuItem,
        restaurantId,
        menuId,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async deleteMenuItem(
    menuItem: MenuItem,
    menuId: string,
    restaurantId: string,
  ): Promise<AxiosResponse<void>> {
    try {
      console.log('delete menu item', menuItem);
      return await authPost(`${API_URL}/jartera/menuitems/delete`, {
        ...menuItem,
        restaurantId,
        menuId,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const adminMenuService = new AdminMenuService();
export default adminMenuService;
