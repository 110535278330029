import { Button, ButtonProps } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { AxiosRequestConfig } from 'axios';
import useAxios from 'axios-hooks';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../utils/services/axiosWrappers';

const API_URL = process.env.REACT_APP_API_URL ?? '';

const config: AxiosRequestConfig = {
  url: `${API_URL}/jartera/menuitems/upload`,
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export const UploadImage = ({
  setSrc,
  buttonProps,
}: {
  setSrc: (src: string) => void;
  buttonProps?: ButtonProps;
}): JSX.Element => {
  const [{ data, error }, execute] = useAxios(config, {
    manual: true,
  });

  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    if (event.target.files && event.target.files.length > 0) {
      formData.append('image', event.target.files[0]);
      config.data = formData;
      const token = getToken();
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
      execute(config);
    }
  };

  useEffect(() => {
    if (data) {
      setSrc(data);
    }
  }, [data]);

  useEffect(() => {
    if (error) console.log('fuck', error);
  }, [error]);

  return (
    <label htmlFor="photo-upload">
      <input
        accept="image/*"
        id="photo-upload"
        type="file"
        name="photo"
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <Button
        component="span"
        startIcon={<CloudUpload />}
        variant="contained"
        color="primary"
        size="small"
        {...buttonProps}
      >
        {t('MenuItem.uploadPhoto')}
      </Button>
    </label>
  );
};
