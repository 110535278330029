/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
export enum Validation {
  required = 'required',
  lessThanZero = 'lessThanZero',
  containsSymbol = 'containsSymbol',
}

export type Validations = Validation;

const symbols = /[\\ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/;

export const validate = (value: any, validation: Validations): boolean => {
  switch (validation) {
    case Validation.required:
      return !value || value.toString().trim() === '';
    case Validation.lessThanZero:
      return value < 0;
    case Validation.containsSymbol:
      return symbols.test(value);
    default:
      return true;
  }
};
