import { createAction } from 'redux-ts-simple';
import { CategoriesList } from '../../types/CategoriesList';
import { Restaurant } from '../../types/Restaurant';
import {
  GET_CATEGORIES_LIST,
  GET_RESTAURANTS_BY_CATEGORY,
  SAVE_CATEGORIES_LIST,
  SAVE_RESTAURANTS_BY_CATEGORY,
} from './types';

export const getRestaurantsByCategory = createAction<{
  city: string;
  category: string;
}>(GET_RESTAURANTS_BY_CATEGORY);

export const saveRestaurantsByCategory = createAction<{
  category: string;
  restaurants: Restaurant[];
}>(SAVE_RESTAURANTS_BY_CATEGORY);

export const getCategoriesList = createAction<{ city: string }>(
  GET_CATEGORIES_LIST,
);

export const saveCategoriesList = createAction<CategoriesList>(
  SAVE_CATEGORIES_LIST,
);
