/* eslint-disable @typescript-eslint/ban-types */
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@material-ui/lab';
import {
  KeyboardTimePicker,
  KeyboardTimePickerProps,
} from '@material-ui/pickers';
import React, { ChangeEvent } from 'react';
import { useController, UseControllerOptions } from 'react-hook-form';
import { AutoCompleteControllerProps } from '../../types/AutoCompleteControllerProps';
import { Option } from '../../types/Option';

export function ComboBoxRender<T>(
  props: AutoCompleteControllerProps<T>,
): JSX.Element {
  const { field } = useController(props.controllerprops);
  const handleChange = (
    event: ChangeEvent<{}>,
    value: string | Option | (string | Option)[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option> | undefined,
  ): void => {
    console.log('change:', event, value, reason, details);
    field.onChange(value);
    if (props.onChange) props.onChange(event, value, reason, details);
  };

  return (
    <Autocomplete
      {...props}
      {...field}
      value={field.value}
      onChange={handleChange}
    />
  );
}

export interface TimePickerRenderProps<T> {
  timePickerProps: Pick<
    KeyboardTimePickerProps,
    Exclude<Exclude<keyof KeyboardTimePickerProps, 'onChange'>, 'value'>
  >;
  controllerProps: UseControllerOptions<T>;
}

export function TimePickerRender<T>(
  props: TimePickerRenderProps<T>,
): JSX.Element {
  const { field } = useController(props.controllerProps);
  return (
    <KeyboardTimePicker
      {...props.timePickerProps}
      {...field}
      value={field.value ?? null}
    />
  );
}
