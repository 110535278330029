import { AxiosError, AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { FBAuthResponse } from '../types/FBAuthResponse';
import { IAuthService } from '../types/IAuthService';
import { LoginFormData } from '../types/LoginFormData';
import { LoginResponse } from '../types/LoginResponse';
import { UserSignUpPayload } from '../types/UserSignUpPayload';
import { post } from '../utils/services/axiosWrappers';

const API_URL = process.env.REACT_APP_API_URL ?? '';

@injectable()
export class AuthService implements IAuthService {
  public async sendGoogleToken(
    tokenId: string,
  ): Promise<AxiosResponse<LoginResponse>> {
    try {
      console.log('payload:', tokenId);
      return await post<LoginResponse>(`${API_URL}/jartera/auth/google`, {
        tokenId,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async sendFacebookToken(
    payload: FBAuthResponse,
  ): Promise<AxiosResponse<LoginResponse>> {
    try {
      console.log('payload:', payload);
      return await post(`${API_URL}/jartera/auth/facebook`, payload);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async sendEmailCredentials(
    credentials: LoginFormData,
  ): Promise<AxiosResponse<LoginResponse>> {
    try {
      console.log('payload:', credentials);
      return await post(`${API_URL}/jartera/auth/email-sign-in`, credentials);
    } catch (error) {
      console.log('code', (error as AxiosError).response);
      throw error;
    }
  }

  public async sendSignUpPayload(
    userData: UserSignUpPayload,
  ): Promise<AxiosResponse<LoginResponse>> {
    try {
      console.log('payload:', userData);
      return await post(`${API_URL}/jartera/auth/email-sign-up`, userData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const authService = new AuthService();
export default authService;
