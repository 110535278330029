import React, { useEffect, useState } from 'react';
import SignUp from './signup/SignUp';
import SignIn from './signin/SignIn';
import SwipeableViews from 'react-swipeable-views';
import { Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { StringMap } from 'i18next';

const paths = ['/sign-up', '/sign-in'];
const pathObj: StringMap = {
  '/sign-up': 0,
  '/sign-in': 1,
};

function Auth(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();
  const [value, setValue] = useState(pathObj[location.pathname] ?? 0);

  useEffect(() => {
    if (location.pathname === '/sign-up') {
      console.log('path:', location.pathname);
      setValue(0);
    } else if (location.pathname === '/sign-in' && value !== 1) {
      setValue(1);
    }
  }, [location]);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
    history.push(paths[newValue]);
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={t('SignUp.signUp')} />
        <Tab label={t('SignUp.signIn')} />
      </Tabs>
      <SwipeableViews
        axis="x"
        index={value}
        onChangeIndex={(index) => setValue(index)}
      >
        <div hidden={value !== 0}>
          <SignUp />
        </div>
        <div hidden={value !== 1}>
          <SignIn />
        </div>
      </SwipeableViews>
    </>
  );
}

export default Auth;
