import React from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import {
  makeStyles,
  Theme,
  createStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { AccountCircle, Home } from '@material-ui/icons';
import { restaurantUserLogout } from '../../store/restaurant-user/actions';
import { userLogout } from '../../store/user/actions';
import { clearToken } from '../../store/auth/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    form: {
      marginLeft: theme.spacing(3),
      flexGrow: 1,
    },
  }),
);

function Header(): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { email, isAdmin } = useSelector((appState: ApplicationState) => ({
    email: appState.restaurantUser.email ?? appState.user.email,
    isAdmin: !!appState.restaurantUser.email,
  }));

  const dispatch = useDispatch();

  const goToSignIn = () => {
    history.push('/sign-in');
  };

  const goToHome = () => {
    const path = isAdmin ? '/admin/home' : '/home';
    history.push(path);
  };

  const logOut = () => {
    setAnchorEl(null);
    if (isAdmin) dispatch(restaurantUserLogout());
    else {
      dispatch(clearToken());
      dispatch(userLogout());
    }
    history.push('/home');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={goToHome}
        >
          <Home />
        </IconButton>

        <div className={classes.title} />

        {email && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>{t('Header.profile')}</MenuItem>
              <MenuItem onClick={logOut}>{t('Header.logOut')}</MenuItem>
            </Menu>
          </div>
        )}

        {!email && (
          <Button onClick={goToSignIn} color="inherit">
            {t('SignUp.signIn')}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
