import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
} from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../../../App';
import { Days, EnumDays } from '../../../types/WeekHours';
import { Schedule } from '../../../types/Schedule';
import { RestaurantScheduleInputProps } from '../../../types/RestaurantScheduleInputProps';

export function RestaurantScheduleInput<T>({
  controllerProps,
  errors,
}: RestaurantScheduleInputProps<T>): JSX.Element {
  const { field } = useController(controllerProps);
  const defaultValue: Schedule = { ...field.value };
  const { t } = useTranslation();
  const classes = useGlobalStyles();
  const [openDate, setOpenDate] = useState<Date | null>(
    defaultValue?.openHours?.open ?? null,
  );
  const [closeDate, setCloseDate] = useState<Date | null>(
    defaultValue?.openHours?.close ?? null,
  );
  const [days, setDays] = useState<EnumDays[]>(defaultValue.days ?? []);

  useEffect(() => {
    field.onChange({ ...field.value, days });
  }, [days]);

  useEffect(() => {
    field.onChange({
      ...field.value,
      openHours: {
        open: openDate,
        close: closeDate,
      },
    });
  }, [openDate, closeDate]);

  const handleCheck = (day: EnumDays) => (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.checked)
      setDays((prev) => prev.filter((d) => d !== day) ?? []);
    else setDays((prev) => [...prev, day]);
  };

  return (
    <Grid container justify="center" className={`${classes.verticalSpacing3}`}>
      <Grid
        item
        container
        justify="center"
        xs={12}
        md={10}
        className={classes.verticalSpacing1}
      >
        {[
          Days.sun,
          Days.mon,
          Days.tue,
          Days.wed,
          Days.thu,
          Days.fri,
          Days.sat,
        ].map((day) => (
          <Grid
            key={`day-${day}`}
            item
            xs={1}
            className={classes.horizontalSpacing1}
          >
            <FormControl error={errors && !!errors[`${day}Repeated`]}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheck(day)}
                    checked={days.includes(day)}
                    color="primary"
                  />
                }
                label={
                  <FormLabel component="legend">
                    {t(`Common.weekDaysAcronyms.${day}`)}
                  </FormLabel>
                }
                labelPlacement="bottom"
                style={{ margin: 0 }}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>

      <Grid
        xs={12}
        md={10}
        item
        container
        justify="center"
        className={classes.horizontalSpacing1}
      >
        <Grid item xs>
          <KeyboardTimePicker
            value={openDate}
            onBlur={field.onBlur}
            onChange={(date) => setOpenDate(date)}
            label="Open"
            placeholder="08:00 AM"
            mask="__:__ _M"
            error={!!errors?.openRequired}
            helperText={errors?.openRequired}
          />
        </Grid>
        <Grid item className={classes.horizontalSpacing1} />
        <Grid item xs>
          <KeyboardTimePicker
            value={closeDate}
            onBlur={field.onBlur}
            onChange={(date) => setCloseDate(date)}
            label="Close"
            placeholder="08:00 AM"
            mask="__:__ _M"
            error={!!errors?.closeRequired}
            helperText={errors?.closeRequired}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
