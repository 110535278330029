import probe, { ProbeResult } from 'probe-image-size';
import { useEffect, useState } from 'react';

export interface ImageDimensions {
  width: number;
  height: number;
}

export const useImageResizer = (
  src: string,
  { width: maxWidth, height: maxHeight }: ImageDimensions,
  defaultDimensions: ImageDimensions,
  fixedWidth?: number,
  fixedHeight?: number,
): ImageDimensions => {
  const [probeResult, setProbeResult] = useState<ProbeResult>();
  const [dimensions, setDimensions] = useState<ImageDimensions>(
    defaultDimensions,
  );

  useEffect(() => {
    if (src)
      probe(src).then((result) => {
        setProbeResult(result);
      });
    else setDimensions(defaultDimensions);
  }, [src]);

  useEffect(() => {
    if (probeResult) {
      let width: number;
      let height: number;
      const aspectRatio = probeResult.height / probeResult.width;
      if (fixedWidth) {
        width = fixedWidth;
        height = width * aspectRatio;
      } else if (fixedHeight) {
        height = fixedHeight;
        width = height / aspectRatio;
      } else {
        if (aspectRatio > 1) {
          height =
            probeResult.height > maxHeight ? maxHeight : probeResult.height;
          width = height / aspectRatio;
        } else if (aspectRatio < 1) {
          width = probeResult.width > maxWidth ? maxWidth : probeResult.width;
          height = width * aspectRatio;
        } else {
          height = probeResult.width > maxWidth ? maxWidth : probeResult.width;
          width = height;
        }
      }
      setDimensions({ height, width });
    }
  }, [probeResult, fixedHeight, fixedWidth]);

  return dimensions;
};
