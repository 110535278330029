import React from 'react';
import Button from '@material-ui/core/Button/Button';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { facebookLogin } from '../../../store/user/actions';
import { FBLoginResponse } from '../../../types/FBLoginResponse';

export const FacebookSignIn = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const loginFB = () => {
    window.FB.login(function (response: FBLoginResponse) {
      if (response.authResponse) {
        console.log('Welcome!  Fetching your information.... ', response);
        console.log(response.authResponse.accessToken);
        dispatch(facebookLogin(response.authResponse));
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    });
  };

  return (
    <Button
      fullWidth
      variant="contained"
      style={{
        color: 'white',
        backgroundColor: theme.palette.FBBlue.main,
      }}
      onClick={loginFB}
      startIcon={<i className="fb-icon" />} //{<img width={30} height={30} src={fbLogo} />}
    >
      {t('SignUp.signInFacebook')}
    </Button>
  );
};
