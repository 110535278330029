import React from 'react';
import './App.css';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core';
import './i18n';
import { Provider } from 'react-redux';
import { history, storePersistor } from './store';
import { ConnectedRouter } from 'connected-react-router';
import { Content } from './components/content/Content';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorDialog from './components/error-dialog/ErrorDialog';
import { DndProvider } from 'react-dnd';
import { isMobile } from 'react-device-detect';

import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

const globalTheme = createMuiTheme({
  palette: {
    FBBlue: {
      main: '#1877f2',
    },
    GBlue: {
      main: '#4285F6',
    },
    primary: {
      main: '#4285F6',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: '2px',
      },
    },
  },
  props: {
    MuiTextField: {
      size: 'small',
      variant: 'outlined',
    },
  },
});

export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    dividerGrid: { width: '100%', alignContent: 'stretch' },
    form: {
      marginLeft: theme.spacing(3),
      flexGrow: 1,
    },
    fieldVerticalSpacing: {
      margin: `${theme.spacing(3)}px 0px`,
    },
    fieldHorizontalSpacing: {
      marginRight: theme.spacing(1),
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    container: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    subtitle: {
      marginTop: `${theme.spacing(4)}px`,
      marginBottom: `${theme.spacing(2)}px`,
    },
    verticalSpacing1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    horizontalSpacing1: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    verticalSpacing2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    horizontalSpacing2: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    verticalSpacing3: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    horizontalSpacing3: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    dataGrid: {
      width: '100%',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    listbox: {
      padding: 0,
    },
  }),
);

const { persistor, store } = storePersistor;
//url names are temporary
function App(): JSX.Element {
  return (
    <ThemeProvider theme={globalTheme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <Content />
              <ErrorDialog />
            </DndProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
