import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { push } from 'connected-react-router';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ItemsListProps } from '../../../types/UserItemsList';
import MenuItemListView from '../../admin/menu/MenuItemListView';

export const ItemsList = ({
  items,
  sectionName,
  menuId,
}: ItemsListProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <ListItem divider button onClick={handleClick}>
        <ListItemText
          primary={
            <Typography variant="button" style={{ fontSize: theme.spacing(3) }}>
              {sectionName}
            </Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item) => (
            <MenuItemListView
              handleOpenItem={() => {
                console.log('open item:', item.id, menuId);
                dispatch(push(`/item/${menuId}/${item.id}`));
              }}
              handleOpenDialog={() => {
                console.log('dialog');
              }}
              key={item.id}
              item={item}
            />
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
};
