import { Grid } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderOptionState } from '@material-ui/lab';
import React, { useState } from 'react';
import { useGlobalStyles } from '../../../App';
import { AutoCompleteProps } from '../../../types/AutoCompleteProps';
import { MenuItemOption } from '../../../types/MenuItemOption';
import { renderInput } from '../../../utils/components/AutoCompleteUtils';
import { ItemComboboxOptionView } from './ItemComboboxOptionView';
import { ItemComboboxOption } from '../../../types/ItemComboboxOption';
import { OptionListDropdownProps } from '../../../types/OptionListDropdownProps';

export const OptionListDropdown = ({
  optionsList: { id, name, isRequired, maxOptions, options },
}: OptionListDropdownProps): JSX.Element => {
  const globalClasses = useGlobalStyles();
  const defaultOption = {
    ...(options.find((option) => option.isDefault) ?? options[0]),
  };
  const defaultSelection: ItemComboboxOption = {
    ...defaultOption,
    code: defaultOption.key ?? '',
    label: defaultOption.name,
  };
  const [selectedOptions, setSelectedOptions] = useState(
    maxOptions > 1 ? [defaultSelection] : defaultSelection,
  );

  const comboBoxOptions: ItemComboboxOption[] = options.map(
    (option: MenuItemOption) => ({
      ...option,
      code: option.key ?? '',
      label: option.name,
    }),
  );
  const renderOption = (
    option: ItemComboboxOption,
    state: AutocompleteRenderOptionState,
  ) => <ItemComboboxOptionView option={option} state={state} />;
  const comboBoxProps: AutoCompleteProps<ItemComboboxOption> = {
    options: comboBoxOptions,
    fullWidth: true,
    multiple: maxOptions > 1,
    onChange: (
      event: React.ChangeEvent<unknown>,
      value:
        | string
        | ItemComboboxOption
        | (string | ItemComboboxOption)[]
        | null,
    ) => {
      if (maxOptions > 1) {
        const options = value as ItemComboboxOption[];
        if (options && options.length <= maxOptions) {
          setSelectedOptions(options);
        }
      } else {
        const option = value as ItemComboboxOption;
        if (option) setSelectedOptions(option);
      }
    },
    value: selectedOptions,

    renderInput: renderInput(name, undefined, undefined, {
      required: isRequired,
    }),
    renderOption,
    getOptionLabel: (option) =>
      `${option.label}  +$${option.extraCharge.toFixed(2)}`,
    getOptionSelected: (option, value) => option.code === value.code,
  };

  const DropDown = <Autocomplete {...comboBoxProps} />;
  return (
    <Grid
      key={id}
      item
      xs={12}
      sm={6}
      className={`${globalClasses.verticalSpacing1} ${globalClasses.horizontalSpacing1}`}
    >
      {DropDown}
    </Grid>
  );
};
