/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ErrorOption,
  FieldError,
  MultipleFieldErrors,
  Resolver,
} from 'react-hook-form';
import i18n from '../../../i18n';
import { MenuItemForm } from '../../../types/MenuItemForm';
import { MenuItemOption } from '../../../types/MenuItemOption';
import { OptionError } from '../../../types/Option';
import { OptionsList } from '../../../types/OptionsList';
import { RHFArrayElement } from '../../../types/RestaurantProfileData';
import { validate, Validation } from '../../../utils/rhf/validationFunctions';

export const validateItemName = (name: string): FieldError | undefined => {
  if (validate(name, Validation.required))
    return {
      type: 'required',
      message: i18n.t('MenuItem.errorMessages.required'),
    };
  return undefined;
};

export const validateDescription = (
  description: string,
): FieldError | undefined => {
  if (validate(description, Validation.required))
    return {
      type: 'required',
      message: i18n.t('MenuItem.errorMessages.required'),
    };
  return undefined;
};

export const validateMinPrice = (minPrice: number): FieldError | undefined => {
  if (validate(minPrice, Validation.required))
    return {
      type: 'required',
      message: i18n.t('MenuItem.errorMessages.required'),
    };
  if (!/^\d+\.\d{2}$|^\d+$/.test(minPrice.toString()))
    return {
      type: 'currencyFormat',
      message: i18n.t('MenuItem.errorMessages.currencyFormat'),
    };
  if (validate(minPrice, Validation.lessThanZero))
    return {
      type: 'negativePrice',
      message: i18n.t('MenuItem.errorMessages.negativePrice'),
    };
  return undefined;
};

export const validateOption = (
  option: MenuItemOption,
  types: MultipleFieldErrors,
): void => {
  if (option.extraCharge < 0)
    types[`${option.name}-option`] = i18n.t(
      'MenuItem.errorMessages.optionNegativePrice',
      {
        name: option.name,
      },
    ) as string;
};

export const validateOptionList = (
  {
    value: { name, maxOptions, minOptions, options },
  }: RHFArrayElement<OptionsList>,
  types: MultipleFieldErrors,
): void => {
  if (validate(name, Validation.required))
    types.required = i18n.t('MenuItem.errorMessages.required') as string;

  if (validate(minOptions, Validation.lessThanZero))
    types.minOptionsLessThanZero = i18n.t(
      'MenuItem.errorMessages.negativeNumber',
    ) as string;

  if (maxOptions < minOptions)
    types.optionsBounds = i18n.t(
      'MenuItem.errorMessages.optionsBounds',
    ) as string;

  if ((options ?? []).length > 0 && maxOptions < 1)
    types.maxOptionsLowerLimit = i18n.t(
      'MenuItem.errorMessages.maxOptionsLowerLimit',
    ) as string;

  if ((options ?? []).length > 0 && maxOptions > (options ?? []).length)
    types.maxOptionsLimit = i18n.t('MenuItem.errorMessages.maxOptionsLimit', {
      limit: (options ?? []).length,
    }) as string;

  if ((options ?? []).length === 0)
    types.noOptions = i18n.t('MenuItem.errorMessages.noOptions', {
      name,
    }) as string;

  if ((options ?? []).length > 0 && minOptions > (options ?? []).length)
    types.minOptionsLimit = i18n.t('MenuItem.errorMessages.minOptionsLimit', {
      limit: (options ?? []).length,
    }) as string;

  options?.forEach((option) => validateOption(option, types));
};

export const validateOptionLists = (
  optionsLists: RHFArrayElement<OptionsList>[],
  errors: MenuItemFormErrors,
): boolean => {
  let isValid = true;
  optionsLists?.forEach((optionList) => {
    const types: MultipleFieldErrors = {};
    validateOptionList(optionList, types);
    if (Object.keys(types).length > 0) {
      isValid = false;
      errors.optionLists.push({ value: { types } });
    } else {
      errors.optionLists.push(undefined);
    }
  });
  return isValid;
};

type MenuItemFormKeys = keyof Pick<
  MenuItemForm,
  Exclude<Exclude<keyof MenuItemForm, 'menuSection'>, 'optionLists'>
>;

export type MenuItemFormErrorKeys = {
  [key in MenuItemFormKeys]?: FieldError;
};

export interface MenuItemFormErrors extends MenuItemFormErrorKeys {
  menuSection?: OptionError;
  optionLists: ({ value: ErrorOption } | undefined)[];
}

export const resolver: Resolver<MenuItemForm, any> = async (
  values: MenuItemForm,
) => {
  const errors: MenuItemFormErrors = { optionLists: [] };
  errors.name = validateItemName(values.name);
  errors.description = validateDescription(values.description);
  errors.minPrice = validateMinPrice(values.minPrice);
  const isValid = validateOptionLists(
    (values.optionLists as unknown) as RHFArrayElement<OptionsList>[],
    errors,
  );

  const result = {
    values: {},
    errors: {},
  };

  if (!isValid || errors.name || errors.description || errors.minPrice)
    result.errors = errors;
  else result.values = values;

  return new Promise((resolve) => {
    resolve(result);
  });
};
