import { ReducerBuilder } from 'redux-ts-simple';
import { restaurantUserLogin, saveUser, restaurantUserLogout } from './actions';
import { RestaurantUserState } from './types';

export const initialRestaurantUserState: RestaurantUserState = {};

export const restaurantUserReducer = new ReducerBuilder(
  initialRestaurantUserState,
)
  .on(restaurantUserLogin, (state, action) => ({ ...state, ...action.payload }))
  .on(restaurantUserLogout, () => initialRestaurantUserState)
  .on(saveUser, (state, action) => ({ ...state, ...action.payload }))
  .else((state) => state)
  .build();
