import { RestaurantUser } from '../../types/RestaurantUserFormData';

export const USER_LOGIN = '@@restaurantUser/USER_LOGIN';

export const EMAIL_LOGIN = '@@restaurantUser/EMAIL_LOGIN';

export const USER_SIGNUP = '@@restaurantUser/USER_SIGNUP';

export const USER_LOGOUT = '@@restaurantUser/USER_LOGOUT';

export const EDIT_USER = '@@restaurantUser/EDIT_USER';

export const SAVE_USER = '@@restaurantUser/SAVE_USER';

export type RestaurantUserState = RestaurantUser;
