/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import {
  CheckCircleRounded,
  RadioButtonUncheckedOutlined,
} from '@material-ui/icons';
import { Card, CardActionArea, CardMedia, Grid } from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import {
  ImageDimensions,
  useImageResizer,
} from '../../../hooks/useImageResizer';
import { useGlobalStyles } from '../../../App';
import { useDrag, useDrop } from 'react-dnd';
import { useStyles, DraggableCard, SelectedPhotosContext } from './Gallery';

export const ImageTile = ({
  src,
  index,
  swap,
}: {
  src: string;
  index: number;
  swap: (from: number, to: number) => void;
}): JSX.Element => {
  const [dimension] = useState(300);
  const {
    deselectPhoto,
    selectPhoto,
    selectedPhotos,
    setIsReordered,
  } = useContext(SelectedPhotosContext);
  const { height, width }: ImageDimensions = useImageResizer(
    src,
    { width: 300, height: 300 },
    { width: 300, height: 300 },
    isMobileOnly ? dimension : undefined,
    !isMobileOnly ? dimension : undefined,
  );
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [{ isDragging }, drag] = useDrag<DraggableCard, any, any>({
    item: { type: 'CARD', index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop<DraggableCard, any, any>({
    accept: 'CARD',
    drop: (item) => {
      if (item.index !== index) {
        swap(item.index, index);
        setIsReordered(true);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [isSelected, setIsSelected] = useState(selectedPhotos.includes(src));

  useEffect(() => {
    setIsSelected(selectedPhotos.includes(src));
  }, [selectedPhotos]);
  const handleSelect = () => {
    if (!isSelected) selectPhoto(src);
    else deselectPhoto(src);
  };

  return (
    <GridListTile
      innerRef={drop}
      style={{
        width: 'fit-content',
        height: 'fit-content',
      }}
      className={`${globalClasses.verticalSpacing1} ${classes.imageTile}`}
    >
      <Card
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,

          justifyItems: 'center',
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        <CardActionArea style={{ width: 'fit-content', height: 'fit-content' }}>
          <Grid container justify="center">
            <Grid item xs />
            <Grid item xs>
              <CardMedia
                image={src}
                style={{
                  width: width,
                  height: height,
                }}
              />
            </Grid>
            <Grid item xs />
          </Grid>
        </CardActionArea>
      </Card>
      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'yellow',
          }}
        />
      )}
      <GridListTileBar
        titlePosition="top"
        actionIcon={
          <IconButton className={classes.icon} onClick={handleSelect}>
            {!isSelected && <RadioButtonUncheckedOutlined />}
            {isSelected && <CheckCircleRounded />}
          </IconButton>
        }
        actionPosition="right"
        className={classes.titleBar}
      />
    </GridListTile>
  );
};
