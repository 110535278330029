import ContactInfo from './components/admin/auth/contact-info/ContactInfo';
import AboutInfo from './components/admin/auth/about-info/AboutInfo';
import OwnerAccount from './components/admin/auth/owner-account/OwnerAccount';
import { RegistrationStep } from './types/RegistrationStep';
import { MenuSection } from './types/MenuItemList';
import { MenuItem } from './types/MenuItem';

export const statess = [
  'al',
  'ak',
  'az',
  'ar',
  'ca',
  'co',
  'ct',
  'de',
  'fl',
  'ga',
  'hi',
  'id',
  'il',
  'in',
  'ia',
  'ks',
  'ky',
  'la',
  'me',
  'md',
  'ma',
  'mi',
  'mn',
  'ms',
  'mo',
  'mt',
  'ne',
  'nv',
  'nh',
  'nj',
  'nm',
  'ny',
  'nc',
  'nd',
  'oh',
  'ok',
  'or',
  'pa',
  'ri',
  'sc',
  'sd',
  'tn',
  'tx',
  'ut',
  'vt',
  'va',
  'wa',
  'wv',
  'wi',
  'wy',
];
export const countries = ['pr', 'us', 'mx'];
export const areaCodes = [
  { code: 'pr', dial: 1 },
  { code: 'us', dial: 1 },
  { code: 'mx', dial: 52 },
];

const defaultItem: MenuItem = {
  name: '',
  description:
    'This is just a description of a menu item.This is just a description of a menu item.This is just a description of a menu item',
  minPrice: 19.99,
  optionsLists: [],
  inStock: true,
  photo: '',
  menuSection: '',
};

const promoItems: MenuItem[] = [
  {
    ...defaultItem,
    name: 'Delicious Promo Item',
    id: '123123',
  },
];
const mainItems: MenuItem[] = [
  {
    ...defaultItem,
    name: 'Delicious Main Item',
    id: '1231113',
  },
];

const sidesItems: MenuItem[] = [
  {
    ...defaultItem,
    name: 'Delicious Side Item',
    id: '123875123',
  },
];

const dessertsItems: MenuItem[] = [
  {
    ...defaultItem,
    name: 'Delicious Dessert Item',
    id: '12312581853',
  },
];

const drinksItems: MenuItem[] = [
  {
    ...defaultItem,
    name: 'Delicious Drink Item',
    id: '1231232225',
  },
];

const promo: MenuSection = {
  items: promoItems,
  title: 'Menu.promoTab',
};

const main: MenuSection = {
  items: mainItems,
  title: 'Menu.mainTab',
};

const sides: MenuSection = {
  items: sidesItems,
  title: 'Menu.sidesTab',
};

const desserts: MenuSection = {
  items: dessertsItems,
  title: 'Menu.dessertsTab',
};

const drinks: MenuSection = {
  items: drinksItems,
  title: 'Menu.drinksTab',
};

export const menuSections: string[] = [
  'promo',
  'main',
  'sides',
  'desserts',
  'drinks',
];

export const tabs: MenuSection[] = [promo, main, sides, desserts, drinks];

export const steps: RegistrationStep[] = [
  {
    title: 'RestaurantSignUp.createAccount',
    path: '/create-account',
    component: OwnerAccount,
  },
  {
    title: 'RestaurantSignUp.tellUsAboutRest',
    path: '/about',
    component: AboutInfo,
  },
  {
    title: 'RestaurantSignUp.contactInfo',
    path: '/contact-info',
    component: ContactInfo,
  },
];
export const categories = ['Popular', 'Fastest', 'Nearby', 'New to Jartera'];
