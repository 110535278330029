import { Button, useTheme } from '@material-ui/core';
import React from 'react';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { googleLogin } from '../../../store/user/actions';

const googleClientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';

export const GoogleSignIn = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const googleResponse = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    console.log(response);
    const loginResponse = response as GoogleLoginResponse;
    if (!loginResponse.tokenId) {
      console.error('Unable to get tokenId from Google', response);
      return;
    }

    console.log(loginResponse.tokenId);
    dispatch(googleLogin({ tokenId: loginResponse.tokenId }));
  };

  const CustomButton = (props: {
    onClick: () => void;
    disabled?: boolean | undefined;
  }) => (
    <Button
      startIcon={<i className="google-icon" />}
      {...props}
      fullWidth
      color="primary"
      variant="contained"
      style={{ backgroundColor: theme.palette.GBlue.main }}
    >
      {t('SignUp.signInGoogle')}
    </Button>
  );

  return (
    <div>
      <GoogleLogin
        clientId={googleClientId}
        onSuccess={googleResponse}
        onFailure={googleResponse}
        render={CustomButton}
      />
    </div>
  );
};

export default GoogleSignIn;
