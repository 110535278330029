import { ReducerBuilder } from 'redux-ts-simple';
import { saveCategoriesList, saveRestaurantsByCategory } from './actions';
import { UserHomeState } from './types';

export const initialUserHomeState: UserHomeState = {
  restaurantsByCategory: {},
};

export const userHomeReducer = new ReducerBuilder(initialUserHomeState)
  .on(saveCategoriesList, (state, action) => ({
    ...state,
    categoriesList: action.payload,
  }))
  .on(saveRestaurantsByCategory, (state, action) => ({
    ...state,
    restaurantsByCategory: {
      ...state.restaurantsByCategory,
      [action.payload.category]: action.payload.restaurants,
    },
  }))
  .else((state) => state)
  .build();
