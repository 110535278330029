import { Fab } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { RestaurantProfileData } from '../../../types/RestaurantProfileData';
import { Schedule } from '../../../types/Schedule';
import { Add } from '@material-ui/icons';
import { ScheduleArrayElement } from './ScheduleArrayElement';

const defaultSchedule: Schedule = {
  days: [],
  openHours: {
    open: null,
    close: null,
  },
};

export const SchedulesFieldArrayContainer = (): JSX.Element => {
  const {
    control,
    errors,
    watch,
    trigger,
  } = useFormContext<RestaurantProfileData>();
  const { append, fields, remove } = useFieldArray<Schedule>({
    control,
    name: 'hours',
  });

  useEffect(() => {
    console.log('errors:', errors);
  }, [watch('schedules'), errors]);

  useEffect(() => {
    trigger(`optionLists`);
  }, [trigger, fields]);

  const handleAddSchedule = () => {
    append(defaultSchedule);
  };
  useEffect(() => {
    if (fields.length === 0) handleAddSchedule();
  }, [fields]);

  const handleDelete = (index: number) => () => {
    remove(index);
  };

  return (
    <Fragment>
      {fields.map((field, index) => (
        <ScheduleArrayElement
          key={field.id}
          controllerProps={{
            control,
            name: `hours[${index}].value`,
            defaultValue: field ?? defaultSchedule,
          }}
          handleDelete={handleDelete(index)}
          field={field}
          index={index}
          canDelete={fields.length > 1}
        />
      ))}
      <Fab
        size="small"
        color="secondary"
        aria-label="add list of options"
        onClick={handleAddSchedule}
      >
        <Add />
      </Fab>
    </Fragment>
  );
};
