import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ApplicationState } from '../../store';

export interface ProtectedRouteProps extends RouteProps {
  isAdminPage?: boolean;
  isAuthRequired?: boolean;
}

export const ProtectedRoute = ({
  children,
  isAdminPage,
  isAuthRequired,
  ...rest
}: ProtectedRouteProps): JSX.Element => {
  const { email, isAdmin } = useSelector((appState: ApplicationState) => ({
    email: appState.restaurantUser.email ?? appState.user.email,
    isAdmin: !!appState.restaurantUser.email,
  }));
  const isAuthenticated = (isAuthRequired && email) || !isAuthRequired;
  const canAccess = (isAdmin && isAdminPage) || (!isAdmin && !isAdminPage);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated && canAccess ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: isAuthenticated ? '/unauthorized' : '/unauthenticated',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
