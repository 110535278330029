/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError, Resolver } from 'react-hook-form';
import i18n from '../../../../i18n';
import { Option, OptionError } from '../../../../types/Option';
import { RestaurantAboutInfo } from '../../../../types/RestaurantLocationInfo';
import {
  validate,
  Validation,
} from '../../../../utils/rhf/validationFunctions';

export const validateAddress1 = (email: string): FieldError | undefined => {
  if (validate(email, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
};

export const validateAddress2 = (): FieldError | undefined => {
  // Possible address validation to be done here
  return undefined;
};

export const validateCity = (city?: Option): OptionError | undefined => {
  if (validate(city, Validation.required))
    return {
      code: {
        type: 'required',
        message: i18n.t('RestaurantSignUp.errorMessages.required'),
      },
      label: {
        type: 'required',
        message: i18n.t('RestaurantSignUp.errorMessages.required'),
      },
    };
};

export const validateCountry = (country?: Option): OptionError | undefined => {
  if (validate(country, Validation.required))
    return {
      code: {
        type: 'required',
        message: i18n.t('RestaurantSignUp.errorMessages.required'),
      },
      label: {
        type: 'required',
        message: i18n.t('RestaurantSignUp.errorMessages.required'),
      },
    };
};

export const validateDescription = (
  description: string,
): FieldError | undefined => {
  if (validate(description, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
};

export const validateName = (name: string): FieldError | undefined => {
  if (validate(name, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
};

export const validateZipCode = (zipCode: string): FieldError | undefined => {
  if (validate(zipCode, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
};

type RestAboutInfoKeys = keyof Pick<
  RestaurantAboutInfo,
  Exclude<
    Exclude<Exclude<keyof RestaurantAboutInfo, 'country'>, 'state'>,
    'city'
  >
>;

export type RestaurantAboutInfoErrorKeys = {
  [key in RestAboutInfoKeys]?: FieldError;
};

export interface RestaurantAboutInfoErrors
  extends RestaurantAboutInfoErrorKeys {
  city?: OptionError;
  state?: OptionError;
  country?: OptionError;
}

export const resolver: Resolver<RestaurantAboutInfo, any> = async (
  values: RestaurantAboutInfo,
) => {
  const errors: RestaurantAboutInfoErrors = {};
  errors.address1 = validateAddress1(values.address1);
  errors.address2 = validateAddress2();
  errors.city = validateCity(values.city);
  errors.country = validateCountry(values.country);
  errors.description = validateDescription(values.description);
  errors.name = validateName(values.name);
  errors.zipCode = validateZipCode(values.zipCode);

  const result = {
    values: {},
    errors: {},
  };

  if (
    errors.address1 ||
    errors.address2 ||
    errors.city ||
    errors.country ||
    errors.description ||
    errors.name ||
    errors.state ||
    errors.zipCode
  )
    result.errors = errors;
  else result.values = values;

  return new Promise((resolve) => {
    resolve(result);
  });
};
