import { ReducerBuilder } from 'redux-ts-simple';
import { clearRestaurant, loadMenu, loadRestaurant, saveItem } from './actions';
import { PagesState } from './types';

export const initialPagesState: PagesState = {
  itemView: {
    description: '',
    inStock: false,
    menuId: '',
    menuSection: '',
    minPrice: 0,
    name: '',
    optionsLists: [],
    photo: '',
  },
  restaurantView: {
    restaurant: {
      status: '',
      description: '',
      address1: '',
      categories: [],
      city: '',
      country: '',
      hours: {
        fri: [{ close: new Date(), open: new Date() }],
        sat: [{ close: new Date(), open: new Date() }],
        sun: [{ close: new Date(), open: new Date() }],
        mon: [{ close: new Date(), open: new Date() }],
        tue: [{ close: new Date(), open: new Date() }],
        wed: [{ close: new Date(), open: new Date() }],
        thu: [{ close: new Date(), open: new Date() }],
      },
      id: '',
      name: '',
      photo: '',
      priceRange: '',
      zipCode: '',
      state: '',
    },
    menu: {
      menuItems: [],
      sections: [],
      id: '',
    },
  },
};

export const pagesReducer = new ReducerBuilder(initialPagesState)
  .on(saveItem, (state, action) => ({
    ...state,
    itemView: action.payload,
  }))
  .on(loadRestaurant, (state, action) => ({
    ...state,
    restaurantView: { ...state.restaurantView, restaurant: action.payload },
  }))
  .on(clearRestaurant, (state) => ({
    ...state,
    restaurantView: initialPagesState.restaurantView,
  }))
  .on(loadMenu, (state, action) => ({
    ...state,
    restaurantView: { ...state.restaurantView, menu: action.payload },
  }))
  .else((state) => state)
  .build();
