import { isBefore, isEqual } from 'date-fns';
import { StringMap } from 'i18next';
import { Category } from '../../types/Category';
import { OpenHours } from '../../types/OpenHours';
import { Schedule } from '../../types/Schedule';
import { EnumDays, WEEK, WeekHours } from '../../types/WeekHours';

export const parseStringToHours = (
  weekHours: { [key in EnumDays]: { open: string; close: string }[] },
): WeekHours => {
  const result: WeekHours = {
    fri: [],
    mon: [],
    sat: [],
    sun: [],
    thu: [],
    tue: [],
    wed: [],
  };
  if (!weekHours) return result;
  WEEK.forEach((d) => {
    result[d] =
      weekHours[d].map((openHours) => ({
        close: new Date(openHours.close),
        open: new Date(openHours.open),
      })) ?? [];
  });
  return result;
};

export const parseSchedules = (schedules: Schedule[]): WeekHours => {
  const weekHours: WeekHours = {
    fri: [],
    mon: [],
    sat: [],
    sun: [],
    thu: [],
    tue: [],
    wed: [],
  };

  schedules.forEach((s) => {
    s.days.forEach((d) => {
      weekHours[d].push(s.openHours);
    });
  });

  WEEK.forEach((d) => {
    weekHours[d].sort((a, b) => (isBefore(a.open ?? 0, b.open ?? 0) ? -1 : 1));
  });

  return weekHours;
};

export const equalHours = (h1: OpenHours, h2: OpenHours): boolean =>
  isEqual(h1.open ?? 0, h2.open ?? 0) && isEqual(h1.close ?? 0, h2.close ?? 0);

const tostring = (hours: OpenHours) =>
  `${hours.open?.toString()}-${hours.close?.toString()}`;

export const parseWeekHours = (weekHours?: WeekHours): Schedule[] => {
  if (!weekHours) return [];
  const map: Map<string, Schedule> = new Map();
  const arr: { id: string; hours: OpenHours; day: EnumDays }[] = [];
  WEEK.forEach((d) => {
    weekHours[d].forEach((h) =>
      arr.push({ id: tostring(h), hours: h, day: d }),
    );
  });
  arr.forEach((v) => {
    const old = map.get(v.id);
    if (old) {
      old.days.push(v.day);
      map.set(v.id, old);
    } else map.set(v.id, { days: [v.day], openHours: v.hours });
  });
  const result: Schedule[] = [];
  map.forEach((v) => {
    result.push(v);
  });

  return result;
};

export const formatPatch = <T>(payload: T): StringMap[] => {
  return (
    (Object.keys(payload) as (keyof T)[]).map((key: keyof T) => ({
      path: `/${key}`,
      op: 'replace',
      value: payload[key],
    })) ?? []
  );
};

export const parseNormalCategory = (id: string, city: string): Category => ({
  id,
  city,
  name: id,
  type: 'NORMAL',
});
