import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper as Card,
  CardContent,
  CardActions,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormHelperText,
} from '@material-ui/core';
import { Delete, ExpandMore } from '@material-ui/icons';
import { StringMap } from 'i18next';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../../../App';
import { ValueMap } from '../../../types/ValueMap';
import { MenuItemForm } from '../../../types/MenuItemForm';
import { OptionsList } from '../../../types/OptionsList';
import { OptionListErrors } from '../../../types/OptionListErrors';
import { OptionListProps } from '../../../types/OptionListProps';
import { ItemOptionView } from '../item-option-view/ItemOptionView';

export const getTypes = (
  currentIndex: number,
  errors?: StringMap,
): OptionListErrors | undefined => {
  if (!errors) return undefined;
  const types = (errors as ValueMap<FieldError | undefined>)[
    `optionLists[${currentIndex}].value`
  ]?.types as OptionListErrors;
  if (types) return types;
  const optionLists = errors?.optionLists;
  if (!optionLists) return undefined;
  return optionLists[currentIndex]?.value?.types;
};

export const OptionListView = (props: OptionListProps): JSX.Element => {
  const { removeOptionList, currentIndex, handleExpand, expanded } = props;
  const { t } = useTranslation();
  const classes = useGlobalStyles();
  const {
    field: { onChange, value, onBlur },
  } = useController({ ...props });
  const [optionList, setOptionList] = useState<OptionsList>(value);
  const { isRequired, maxOptions, minOptions, name: listName } = optionList;

  const { errors: formErrors } = useFormContext<MenuItemForm>();

  const errors = getTypes(currentIndex, formErrors);

  useEffect(() => {
    onChange(optionList);
    onBlur();
  }, [optionList]);

  const changeListName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setOptionList((prevList) => {
      return { ...prevList, name: event.target.value };
    });
  };

  const changeOptional = (event: ChangeEvent<HTMLInputElement>) => {
    setOptionList((prevList) => {
      return {
        ...prevList,
        isRequired: !event.target.checked,
      };
    });
  };

  const changeMinOptions = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setOptionList((prevList) => {
      return {
        ...prevList,
        minOptions: event.target.value ? parseInt(event.target.value) : 0,
      };
    });
  };

  const changeMaxOptions = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setOptionList((prevList) => {
      return {
        ...prevList,
        maxOptions: event.target.value ? parseInt(event.target.value) : 0,
      };
    });
  };

  const deleteOptionList = () => {
    removeOptionList(currentIndex);
  };

  return (
    <Accordion onChange={handleExpand} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography noWrap={false} variant="h5">
          {`${optionList.name} - ${
            optionList.isRequired
              ? t('MenuItem.requiredCardTitle')
              : t('MenuItem.optionalCardTitle')
          } `}

          {errors?.noOptions && (
            <FormHelperText error>
              <Typography variant="h6">{errors?.noOptions}</Typography>
            </FormHelperText>
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container item xs={12} className={classes.verticalSpacing1}>
          <Grid item xs={12}>
            <Card id="chip-paper" elevation={5}>
              <CardContent>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  className={classes.verticalSpacing1}
                >
                  <Grid item xs={12} className={classes.fieldHorizontalSpacing}>
                    <TextField
                      id="listName"
                      size="small"
                      label={t('MenuItem.optionListName')}
                      name="listName"
                      error={!!errors?.required}
                      helperText={errors?.required}
                      onChange={changeListName}
                      onBlur={onBlur}
                      value={listName}
                    />
                  </Grid>

                  <Grid item xs className={classes.fieldHorizontalSpacing}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isRequired"
                          checked={!isRequired}
                          onChange={changeOptional}
                          color="primary"
                        />
                      }
                      label={t('MenuItem.optional')}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.verticalSpacing1}
                >
                  <Grid item xs={4} className={classes.fieldHorizontalSpacing}>
                    <TextField
                      id="minOptions"
                      size="small"
                      type="number"
                      value={minOptions}
                      label={t('MenuItem.minOptions')}
                      name="minOptions"
                      error={
                        !!errors?.minOptionsLimit ||
                        !!errors?.minOptionsLessThanZero ||
                        !!errors?.optionsBounds
                      }
                      helperText={`${errors?.minOptionsLimit ?? ''}\n${
                        errors?.minOptionsLessThanZero ?? ''
                      }\n${errors?.optionsBounds ?? ''}`}
                      onChange={changeMinOptions}
                      onBlur={onBlur}
                    />
                  </Grid>
                  <Grid item xs={4} className={classes.fieldHorizontalSpacing}>
                    <TextField
                      id="name"
                      size="small"
                      type="number"
                      label={t('MenuItem.maxOptions')}
                      name="name"
                      value={maxOptions}
                      error={
                        !!errors?.maxOptionsLessThanZero ||
                        !!errors?.maxOptionsLimit ||
                        !!errors?.maxOptionsLowerLimit
                      }
                      helperText={`\n${errors?.maxOptionsLessThanZero ?? ''}\n${
                        errors?.maxOptionsLimit ?? ''
                      }\n${errors?.maxOptionsLowerLimit ?? ''}`}
                      onChange={changeMaxOptions}
                      onBlur={onBlur}
                    />
                  </Grid>
                </Grid>
                <ItemOptionView
                  currentIndex={currentIndex}
                  setOptionList={setOptionList}
                  optionList={optionList}
                  onBlur={onBlur}
                />
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Delete />}
                  onClick={deleteOptionList}
                >
                  {t('Common.deleteButton')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
