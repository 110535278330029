import React from 'react';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import { Divider, Grid, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../../../App';
import { useHistory } from 'react-router-dom';

function RegistrationSubmission(): JSX.Element {
  const theme = useTheme();

  const history = useHistory();

  const handleClick = () => {
    history.push('/admin/home');
  };

  const { t } = useTranslation();
  const classes = useGlobalStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Grid item xs={12} sm={8} lg={4} className={classes.fieldVerticalSpacing}>
        <Typography variant="h4">
          {t('RestaurantSignUp.registrationSubmitted')}
        </Typography>
      </Grid>
      {/* <Grid item xs={12} sm={8} lg={4} className={classes.fieldVerticalSpacing}> */}
      <Divider
        flexItem
        style={{
          height: 2,
          backgroundColor: theme.palette.primary.main,
        }}
      />
      {/* </Grid> */}
      <Grid item xs={12} sm={8} lg={4} className={classes.fieldVerticalSpacing}>
        <Typography variant="h5">
          {t('RestaurantSignUp.registrationSuccessMessageLine1')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} lg={4} className={classes.fieldVerticalSpacing}>
        <Typography variant="h5">
          {t('RestaurantSignUp.registrationSuccessMessageLine2')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} lg={4} className={classes.fieldVerticalSpacing}>
        <Typography variant="h5">
          {t('RestaurantSignUp.registrationSuccessMessageLine3')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Button
          onClick={handleClick}
          fullWidth
          color="primary"
          variant="contained"
        >
          {t('Common.doneButton')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default RegistrationSubmission;
