import { createAction } from 'redux-ts-simple';
import { FBAuthResponse } from '../../types/FBAuthResponse';
import { LoginFormData } from '../../types/LoginFormData';
import { UserFormData } from '../../types/UserFormData';
import {
  EDIT_USER,
  EMAIL_LOGIN,
  FACEBOOK_LOGIN,
  GOOGLE_LOGIN,
  UserState,
  USER_LOGIN,
  USER_LOGOUT,
  USER_SIGNUP,
} from './types';

export const userLogin = createAction<UserState>(USER_LOGIN);

export const userSignUp = createAction<UserFormData>(USER_SIGNUP);

export const emailLogin = createAction<LoginFormData>(EMAIL_LOGIN);

export const userLogout = createAction(USER_LOGOUT);

export const editUser = createAction<UserState>(EDIT_USER);

export const facebookLogin = createAction<FBAuthResponse>(FACEBOOK_LOGIN);

export const googleLogin = createAction<{ tokenId: string }>(GOOGLE_LOGIN);
