import { Menu } from '../../types/Menu';
import { MenuItem } from '../../types/MenuItem';
import { Restaurant } from '../../types/Restaurant';

export const GET_ITEM = '@@pages/itemView/GET_ITEM';

export const SAVE_ITEM = '@@pages/itemView/SAVE_ITEM';

export const GET_RESTAURANT = '@@pages/GET_RESTAURANT';

export const LOAD_RESTAURANT = '@@pages/LOAD_RESTAURANT';

export const CLEAR_RESTAURANT = '@@pages/CLEAR_RESTAURANT';

export const GET_MENU = '@@pages/GET_MENU';

export const LOAD_MENU = '@@pages/LOAD_MENU';

export interface ItemViewState extends MenuItem {
  menuId: string;
}
export interface RestaurantViewState {
  restaurant: Restaurant;
  menu: Menu;
}

export interface PagesState {
  itemView: ItemViewState;
  restaurantView: RestaurantViewState;
}
