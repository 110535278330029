import { ReducerBuilder } from 'redux-ts-simple';
import { editUser, userLogin, userLogout } from './actions';
import { UserState } from './types';

export const initialUserState: UserState = {
  email: '',
  firstName: '',
  lastName: '',
  role: '',
  pKey: '',
  id: '',
  account: '',
};

export const reducer = new ReducerBuilder(initialUserState)
  .on(userLogin, (state, action) => ({ ...state, ...action.payload }))
  .on(userLogout, () => initialUserState)
  .on(editUser, (state, action) => ({ ...state, ...action.payload }))
  .else((state) => state)
  .build();
