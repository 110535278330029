import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createStyles,
  Grid,
  GridList,
  GridListTile,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { InsertPhoto } from '@material-ui/icons';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalStyles } from '../../../App';
import {
  ImageDimensions,
  useImageResizer,
} from '../../../hooks/useImageResizer';
import { ApplicationState } from '../../../store';
import { getRestaurantsByCategory } from '../../../store/user-home/actions';
import { RestaurantCardProps } from '../restaurant-category/RestaurantCategory';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      borderRadius: '0px 0px 4px 4px',
      borderWidth: '0px 1px 1px',
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    },
    cardMedia: { height: 200 },
    cardText: { color: theme.palette.grey[400] },
    gridList: { margin: 0 },
    titleGrid: { textAlign: 'center' },
    gridTile: {
      marginBottom: theme.spacing(1),
      flex: 'flex-grow',
    },
  }),
);

export const RestaurantCategoryList = (): JSX.Element => {
  const { category, city } = useParams<{ category: string; city: string }>();

  const restaurants = useSelector(
    (state: ApplicationState) =>
      state.userHome.restaurantsByCategory[category] ?? [],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getRestaurantsByCategory({
        category,
        city,
      }),
    );
  }, []);

  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const history = useHistory();
  const { t } = useTranslation();

  const handleRestaurantClick = (restaurantId: string) => () => {
    history.push(`/restaurant/${city}/${restaurantId}`);
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} container className={globalClasses.verticalSpacing1}>
        <Grid item className={classes.titleGrid} xs={12}>
          <Typography variant="h4">{t(`Categories.${category}`)}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <GridList
          spacing={0}
          cols={1}
          className={classes.gridList}
          cellHeight="auto"
        >
          {restaurants.map((restaurant, index) => (
            <GridListTile
              key={`RestCard-${index}-${restaurant.id}`}
              cols={1}
              rows={2}
              className={classes.gridTile}
            >
              <RestaurantCard
                restaurant={restaurant}
                handleRestaurantClick={handleRestaurantClick}
              />
            </GridListTile>
          ))}
        </GridList>
      </Grid>
    </Grid>
  );
};
function RestaurantCard({
  handleRestaurantClick,
  restaurant: { priceRange, city, name, id, photos },
}: RestaurantCardProps): JSX.Element {
  const cardRef = useRef<HTMLElement>(null);

  const { height, width }: ImageDimensions = useImageResizer(
    photos && photos.length > 0 ? photos[0] : '',
    {
      width: 300,
      height: 300,
    },
    {
      width: 300,
      height: 300,
    },
    undefined,
    200,
  );
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Card elevation={0} innerRef={cardRef}>
      <CardActionArea onClick={handleRestaurantClick(id ?? '')}>
        <Grid
          container
          justify="center"
          style={{ backgroundColor: theme.palette.grey[200] }}
        >
          {photos && photos.length > 0 && (
            <Grid item>
              <CardMedia
                image={photos[0]}
                style={{
                  height,
                  width,
                }}
              />
            </Grid>
          )}
          {(!photos || photos.length == 0) && (
            <Grid item xs>
              <Paper
                elevation={0}
                style={{
                  backgroundColor: theme.palette.grey[100],
                }}
                className={classes.cardMedia}
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  className={classes.cardMedia}
                >
                  <Grid item>
                    <InsertPhoto style={{ fontSize: 48 }} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
        <CardContent className={classes.cardContent}>
          <Typography variant="subtitle1">{name}</Typography>
          <Typography variant="subtitle2" className={classes.cardText}>
            {city}
          </Typography>
          <Typography variant="subtitle2" className={classes.cardText}>
            {priceRange}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
