import { Chip, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MenuItemOption } from '../../../types/MenuItemOption';

export interface OptionChipProps {
  error: boolean;
  option: MenuItemOption;
  selectOption: (option: MenuItemOption) => () => void;
  deleteOption: (key: string) => () => void;
}

export const OptionChip = ({
  error,
  option,
  deleteOption,
  selectOption,
}: OptionChipProps): JSX.Element => {
  const theme = useTheme();
  const chipColor = error
    ? theme.palette.error.main
    : option.isDefault
    ? 'primary'
    : 'default';
  const [chipStyles, setChipStyles] = useState({
    margin: 5,
    backgroundColor: chipColor,
  });

  useEffect(() => {
    const newColor = error ? theme.palette.error.main : '';
    setChipStyles({ ...chipStyles, backgroundColor: newColor });
  }, [error, option.isDefault]);

  return (
    <Chip
      key={`${option.name}`}
      clickable
      onClick={selectOption(option)}
      label={`${option.name} - $${option.extraCharge.toFixed(2)}`}
      style={chipStyles}
      color={error ? undefined : option.isDefault ? 'primary' : 'default'}
      onDelete={deleteOption(option.key ?? '')}
    />
  );
};
