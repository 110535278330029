import { createAction } from 'redux-ts-simple';
import { Credentials } from '../../types/Credentials';
import { RestaurantUser } from '../../types/RestaurantUserFormData';
import {
  EDIT_USER,
  EMAIL_LOGIN,
  RestaurantUserState,
  SAVE_USER,
  USER_LOGIN,
  USER_LOGOUT,
  USER_SIGNUP,
} from './types';

export const restaurantUserLogin = createAction<RestaurantUserState>(
  USER_LOGIN,
);

export const restaurantUserSignUp = createAction<Credentials>(USER_SIGNUP);

export const restaurantUserEmailLogin = createAction<Credentials>(EMAIL_LOGIN);

export const restaurantUserLogout = createAction(USER_LOGOUT);

export const editUser = createAction<RestaurantUser>(EDIT_USER);

export const saveUser = createAction<RestaurantUser>(SAVE_USER);
