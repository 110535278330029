export const USER_LOGIN = '@@user/USER_LOGIN';

export const EMAIL_LOGIN = '@@user/EMAIL_LOGIN';

export const USER_SIGNUP = '@@user/USER_SIGNUP';

export const FACEBOOK_LOGIN = '@@user/FACEBOOK_LOGIN';

export const GOOGLE_LOGIN = '@@user/GOOGLE_LOGIN';

export const USER_LOGOUT = '@@user/USER_LOGOUT';

export const EDIT_USER = '@@user/EDIT_USER';

export interface UserState {
  id: string;
  pKey: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  account: string;
}
