import { RestaurantAccountInfo } from '../../types/RestaurantAccountInfo';
import { RestaurantContactInfo } from '../../types/RestaurantContactInfo';
import { RestaurantAboutInfo } from '../../types/RestaurantLocationInfo';

export const SEND_RESTAURANT_LOCATION_INFO =
  '@@restaurantRegistration/SEND_RESTAURANT_LOCATION_INFO';

export const SUBMIT_RESTAURANT_LOCATION_INFO =
  '@@restaurantRegistration/SUBMIT_RESTAURANT_LOCATION_INFO';

export const SEND_RESTAURANT_CONTACT_INFO =
  '@@restaurantRegistration/SEND_RESTAURANT_CONTACT_INFO';

export const SUBMIT_RESTAURANT_CONTACT_INFO =
  '@@restaurantRegistration/SUBMIT_RESTAURANT_CONTACT_INFO';

export const SEND_RESTAURANT_ACCOUNT_INFO =
  '@@restaurantRegistration/SEND_RESTAURANT_ACCOUNT_INFO';

export const SUBMIT_RESTAURANT_ACCOUNT_INFO =
  '@@restaurantRegistration/SUBMIT_RESTAURANT_ACCOUNT_INFO';

export const HANDLE_NEXT = '@@restaurantRegistration/HANDLE_NEXT';

export const HANDLE_BACK = '@@restaurantRegistration/HANDLE_BACK';

export const HANDLE_RESET = '@@restaurantRegistration/HANDLE_RESET';

export interface RestaurantRegistrationState
  extends RestaurantAboutInfo,
    RestaurantContactInfo,
    RestaurantAccountInfo {
  token?: string;
  activeStep: number;
}
