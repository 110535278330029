import {
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { updateRestaurantStatus } from '../../../store/restaurant/actions';
import AlertDialog from '../alert-dialog/AlertDialog';

const useStyles = makeStyles(() =>
  createStyles({
    base: {
      padding: '9px !important',
    },
  }),
);

export const RestaurantStatusSwitch = (): JSX.Element => {
  const { t } = useTranslation();

  const restState = useSelector(
    (appState: ApplicationState) => appState.restaurant,
  );

  const dispatch = useDispatch();

  const [active, setActive] = useState<boolean>(restState.status === 'ACTIVE');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleStatusChange = () => {
    dispatch(
      updateRestaurantStatus({
        status: active ? 'INACTIVE' : 'ACTIVE',
      }),
    );
    setDialogOpen(false);
  };

  useEffect(() => {
    setActive(restState.status === 'ACTIVE');
  }, [restState]);

  const classes = useStyles();

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Grid alignItems="center" justify="center" container>
            <Grid item md={4}>
              <Typography>{t(`RestaurantProfile.Status.INACTIVE`)}</Typography>
            </Grid>
            <Grid item xs md={4}>
              <Switch
                onChange={() => setDialogOpen(true)}
                disabled={
                  !['ACTIVE', 'INACTIVE'].includes(restState.status ?? '')
                }
                checked={active}
                classes={{
                  switchBase: classes.base,
                }}
              />
            </Grid>
            <Grid item md={4}>
              <Typography>{t(`RestaurantProfile.Status.ACTIVE`)}</Typography>
            </Grid>
          </Grid>
        }
        label="Status"
        labelPlacement="top"
      />
      <AlertDialog
        cancelText={t('Common.no') as string}
        title={t('RestaurantProfile.changeStatusTitle') as string}
        confirmText={t('Common.yes') as string}
        content={t('RestaurantProfile.changeStatusContent') as string}
        open={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleStatusChange}
      />
    </Fragment>
  );
};
