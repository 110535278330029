import React, { useEffect, useState } from 'react';
import SignIn from './signin/SignIn';
import { Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { StringMap } from 'i18next';
import { RegistrationForm } from './RegistrationForm';

const paths = ['/restaurant-sign-up', '/restaurant-sign-in'];
const pathObj: StringMap = {
  '/restaurant-sign-up': 0,
  '/restaurant-sign-in': 1,
};

function RestaurantAuth(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();
  const [value, setValue] = useState(pathObj[location.pathname] ?? 0);

  useEffect(() => {
    if (location.pathname.includes('/restaurant-sign-up')) {
      console.log('path:', location.pathname);
      setValue(0);
    } else if (location.pathname === '/restaurant-sign-in' && value !== 1) {
      setValue(1);
    }
  }, [location]);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
    history.push(paths[newValue]);
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={t('SignUp.signUp')} />
        <Tab label={t('SignUp.signIn')} />
      </Tabs>
      {/* <SwipeableViews
        axis="x"
        index={value}
        onChangeIndex={(index) => setValue(index)}
        animateHeight={true}
      > */}
      <div hidden={value !== 0}>
        <RegistrationForm />
      </div>
      <div hidden={value !== 1}>
        <SignIn />
      </div>
      {/* </SwipeableViews> */}
    </>
  );
}

export default RestaurantAuth;
