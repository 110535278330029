/* eslint-disable react/display-name */
import React from 'react';
import {
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Add, Delete, InsertPhoto } from '@material-ui/icons';
import {
  DataGrid,
  ColDef,
  ValueFormatterParams,
  CellParams,
} from '@material-ui/data-grid';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { useGlobalStyles } from '../../../App';
import { MenuItem } from '../../../types/MenuItem';
import { MenuItemRow } from '../../../types/MenuItemRow';
import { MenuProps } from '../../../types/MenuProps';
import {
  ImageDimensions,
  useImageResizer,
} from '../../../hooks/useImageResizer';

const EditButtonCell = (label: string, handleOpen: (row: MenuItem) => void) => (
  params: ValueFormatterParams,
) => {
  const { row } = params;
  return (
    <Button
      style={{ borderRadius: 50 }}
      color="primary"
      variant="contained"
      onClick={() => handleOpen(row.item)}
    >
      {label}
    </Button>
  );
};

const deleteButtonCell = (handleOpenDialog: (row: MenuItem) => void) => (
  params: ValueFormatterParams,
) => {
  const { row } = params;
  return (
    <IconButton
      edge="end"
      color="secondary"
      onClick={() => handleOpenDialog(row.item)}
    >
      <Delete />
    </IconButton>
  );
};

const thumbnailCell = (params: ValueFormatterParams) => {
  const theme = useTheme();
  const { row } = params;
  const item: MenuItem = row.item;
  const { height, width }: ImageDimensions = useImageResizer(
    item.photo ?? '',
    { width: 100, height: 100 },
    { width: 50, height: 50 },
  );

  if (!item.photo)
    return (
      <InsertPhoto
        style={{
          fontSize: 67,
          color: theme.palette.divider,
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
        }}
      />
    );
  return (
    <img
      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
      src={item.photo}
      height={height}
      width={width}
    />
  );
};

const valueFormatter = (params: CellParams) => {
  const item = params.row.item as MenuItem;
  const value = `$${item.minPrice.toFixed(2)}`;
  return value;
};

const itemColumns: ColDef[] = [
  {
    field: 'photo',
    headerName: 'Photo',
    renderCell: thumbnailCell,
    headerAlign: 'center',
    align: 'center',
    width: 150,
  },
  { field: 'name', headerName: 'Menu.name', width: 180 },
  {
    field: 'menuSection',
    headerName: 'Menu.menuSection',
    width: 120,
  },
  {
    field: 'description',
    headerName: 'Menu.description',
    width: 200,
  },
  { field: 'inStock', headerName: 'Menu.inStock', width: 85 },
  {
    field: 'minPrice',
    headerName: 'Menu.minPrice',
    valueFormatter,
    width: 100,
  },
];

function DesktopMenu({
  handleOpenDialog,
  handleOpenItem,
}: MenuProps): JSX.Element {
  const {
    menu: { menuItems: items },
  } = useSelector((appState: ApplicationState) => appState.restaurant);
  const classes = useGlobalStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const columns: ColDef[] = [
    {
      field: 'open',
      headerName: ' ',
      renderCell: EditButtonCell(
        t('Common.editButton') as string,
        handleOpenItem,
      ),
    },
    ...itemColumns.map((column) => ({
      ...column,
      headerName: t(column.headerName ?? '')?.toString(),
    })),
    {
      field: 'remove',
      headerName: ' ',
      renderCell: deleteButtonCell(handleOpenDialog),
      width: 66,
    },
  ];

  const rows: MenuItemRow[] = items.map((item, index) => ({
    ...item,
    menuSection:
      t(`Menu.${item.menuSection}`, item.menuSection)?.toString() ?? '',
    id: index,
    item,
    inStock: item.inStock
      ? (t('Common.yes') as string)
      : (t('Common.no') as string),
  }));

  return (
    <Grid container justify="center">
      <Grid item md={12} lg={12} xl={7}>
        <Box paddingX={3} paddingY={3}>
          <Fab
            size="small"
            color="secondary"
            aria-label="add menu item"
            onClick={() => history.push('/menu-item')}
            className={`${classes.horizontalSpacing1} ${classes.verticalSpacing1}`}
            variant="extended"
          >
            <Add />
            {t('Menu.addNewItem')}
          </Fab>
          <DataGrid
            autoHeight
            rowHeight={75}
            rows={rows}
            columns={columns}
            autoPageSize
            filterMode="client"
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default DesktopMenu;
