import { apply, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux-ts-simple';
import { RestaurantAboutInfo } from '../../types/RestaurantLocationInfo';
import {
  handleReset,
  sendRestaurantContactInfo,
  sendRestaurantLocationInfo,
  submitRestaurantLocationInfo,
} from './actions';
import { SagaIterator } from 'redux-saga';
import { inject, injectable } from 'inversify';
import { RestaurantContactInfo } from '../../types/RestaurantContactInfo';
import { TypesSymbols } from '../../typesSymbols';
import { IRestaurantService } from '../../types/IRestaurantsService';
import { startAction, stopAction } from '../loader/actions';
import { Restaurant } from '../../types/Restaurant';
import { ApplicationState } from '..';
import { saveRestaurant } from '../restaurant/actions';
import { RestaurantUser } from '../../types/RestaurantUserFormData';
import { editUser } from '../restaurant-user/actions';
import { push } from 'connected-react-router';
import { addError } from '../errors/actions';

@injectable()
export class RestaurantSaga {
  sagas: SagaIterator[] = [
    this._watchHandleSendRestaurantLocationInfo(),
    this._watchHandleSendRestaurantContactInfo(),
  ];

  @inject(TypesSymbols.IRestaurantService)
  private restaurantService!: IRestaurantService;

  *_handleSendRestaurantLocationInfo({
    payload,
  }: Action<RestaurantAboutInfo>): SagaIterator {
    try {
      //service api call to be added here.
      yield put(submitRestaurantLocationInfo(payload));
    } catch (error) {
      console.error('saga error:', error);
      // error state to be updated here when implemented.
    }
  }

  *_handleSendRestaurantContactInfo(
    action: Action<RestaurantContactInfo>,
  ): SagaIterator {
    try {
      const { payload } = action;
      yield put(startAction(action));
      const about: RestaurantAboutInfo = yield select(
        (appState: ApplicationState) => ({ ...appState.restaurantForm }),
      );
      const owner: RestaurantUser = yield select(
        (appState: ApplicationState) => appState.restaurantUser,
      );
      const restaurant: Restaurant = {
        ...about,
        ...payload,
        city: about.city?.code ?? '',
        state: about.state?.code,
        country: about.country?.code ?? '',
        phoneCountry: `${payload.phoneCountry?.dial}`,
        status: '',
      };
      const { data } = yield apply(
        this.restaurantService,
        this.restaurantService.addRestaurant,
        [restaurant, owner.id ?? '', owner.email ?? ''],
      );

      yield put(
        editUser({
          ...owner,
          firstName: payload.ownerFirstName,
          lastName: payload.ownerLastName,
          phoneCountry: `${payload.ownerPhoneCountry?.dial}`,
          phoneNumber: payload.ownerPhoneNumber,
          restaurantCity: data.city,
          restaurantId: data.id,
        }),
      );
      console.log('saga', data);

      yield put(saveRestaurant(data));
      yield put(handleReset());
      yield put(push('/restaurantsubmission'));
    } catch (error) {
      yield put(
        addError({
          code: 'UNEXPECTED_ERROR',
          message: 'UnexpectedError',
        }),
      );
      console.error('submit restaurant saga error:', error);
      // error state to be updated here when implemented.
    } finally {
      yield put(stopAction({ type: action.type }));
    }
  }

  *_watchHandleSendRestaurantContactInfo(): SagaIterator {
    yield takeEvery(
      sendRestaurantContactInfo.type,
      this._handleSendRestaurantContactInfo.bind(this),
    );
  }

  *_watchHandleSendRestaurantLocationInfo(): SagaIterator {
    yield takeEvery(
      sendRestaurantLocationInfo.type,
      this._handleSendRestaurantLocationInfo.bind(this),
    );
  }
}
