/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ErrorOption,
  FieldError,
  MultipleFieldErrors,
  Resolver,
} from 'react-hook-form';
import i18n from '../../../i18n';
import { OptionError } from '../../../types/Option';
import { RestaurantProfileData } from '../../../types/RestaurantProfileData';
import { RHFSchedule } from '../../../types/Schedule';
import { validate, Validation } from '../../../utils/rhf/validationFunctions';
import {
  validateFacebook,
  validatePhoneCountry,
  validatePhoneNumber,
  validateWebsite,
} from '../auth/contact-info/resolver';

export const validateDescription = (
  description: string,
): FieldError | undefined => {
  if (validate(description, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
};

export const validateName = (name: string): FieldError | undefined => {
  if (validate(name, Validation.required))
    return {
      type: 'required',
      message: i18n.t('RestaurantSignUp.errorMessages.required'),
    };
};

export const validateSchedule = (
  schedule: RHFSchedule,
  types: MultipleFieldErrors,
): void => {
  if (schedule.value.days.length === 0) {
    types.dayRequired = i18n.t(
      'RestaurantProfile.errorMessages.daysRequired',
    ) as string;
  }
  if (!schedule.value.openHours.open) {
    types.openRequired = i18n.t('MenuItem.errorMessages.required') as string;
  }
  if (!schedule.value.openHours.close) {
    types.closeRequired = i18n.t('MenuItem.errorMessages.required') as string;
  }

  // schedule.value.days.forEach((d) => {
  //   if (days.includes(d)) {
  //     const day = i18n.t(`Common.weekDays.${d}`) as string;
  //     types[`${d}Repeated`] = i18n.t(
  //       'RestaurantProfile.errorMessages.dayRepeated',
  //       {
  //         day,
  //       },
  //     ) as string;
  //   } else days.push(d);
  // });
};

export const validateSchedules = (
  schedules: RHFSchedule[],
  errors: RestaurantProfileErrors,
): boolean => {
  let isValid = true;
  // const days: EnumDays[] = [];
  schedules?.forEach((s) => {
    const types: MultipleFieldErrors = {};
    validateSchedule(s, types);
    if (Object.keys(types).length > 0) {
      isValid = false;
      errors.schedules.push({ value: { types } });
    } else {
      errors.schedules.push(undefined);
    }
  });
  return isValid;
};

type RestProfileKeys = keyof Pick<
  RestaurantProfileData,
  Exclude<Exclude<keyof RestaurantProfileData, 'schedules'>, 'phoneCountry'>
>;

export type RestaurantProfileErrorKeys = {
  [key in RestProfileKeys]?: FieldError;
};

export interface RestaurantProfileErrors extends RestaurantProfileErrorKeys {
  schedules: ({ value: ErrorOption } | undefined)[];
  phoneCountry?: OptionError;
}

export const resolver: Resolver<RestaurantProfileData, any> = async (
  values: RestaurantProfileData,
) => {
  const errors: RestaurantProfileErrors = { schedules: [] };

  errors.description = validateDescription(values.description);
  errors.name = validateName(values.name);
  errors.phoneCountry = validatePhoneCountry(values.phoneCountry?.dial);
  errors.phoneNumber = validatePhoneNumber(
    values.phoneNumber ?? '',
    values.phoneCountry?.dial,
  );
  errors.website = validateWebsite(values.website ?? '');
  errors.facebook = validateFacebook(values.facebook ?? '');
  const isValid = validateSchedules(
    (values.hours as unknown) as RHFSchedule[],
    errors,
  );
  const result = {
    values: {},
    errors: {},
  };

  if (
    errors.description ||
    errors.name ||
    errors.facebook ||
    errors.phoneCountry ||
    errors.phoneNumber ||
    errors.website ||
    errors.categories ||
    !isValid
  )
    result.errors = errors;
  else result.values = values;

  return new Promise((resolve) => {
    resolve(result);
  });
};
