/* eslint-disable @typescript-eslint/no-explicit-any */
import 'reflect-metadata';
import { container } from '../inversify.config';
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  Reducer,
  Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { reducer } from './restaurant-registration/reducer';
import { RestaurantRegistrationState } from './restaurant-registration/types';
import { reducer as userReducer } from './user/reducer';
import { UserState } from './user/types';
import { RootSaga } from './rootSaga';
import { RestaurantState } from './restaurant/types';
import { restaurantReducer } from './restaurant/reducer';
import { UserHomeState } from './user-home/types';
import { userHomeReducer } from './user-home/reducer';
import { LoaderState } from './loader/types';
import { RestaurantUserState } from './restaurant-user/types';
import { loaderReducer } from './loader/reducer';
import { restaurantUserReducer } from './restaurant-user/reducer';
import {
  connectRouter,
  RouterState,
  routerMiddleware,
} from 'connected-react-router';
import { History, createBrowserHistory } from 'history';
import {
  persistStore,
  persistReducer,
  Persistor,
  PersistConfig,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { ErrorsState } from './errors/types';
import { errorsReducer } from './errors/reducer';
import { PagesState } from './pages/types';
import { pagesReducer } from './pages/reducer';
import { AuthState } from './auth/types';
import { authReducer } from './auth/reducer';

export interface ApplicationState {
  user: UserState;
  restaurantForm: RestaurantRegistrationState;
  restaurant: RestaurantState;
  userHome: UserHomeState;
  loader: LoaderState;
  restaurantUser: RestaurantUserState;
  router: RouterState;
  errors: ErrorsState;
  pages: PagesState;
  auth: AuthState;
}

export const history = createBrowserHistory();

// const initialState: ApplicationState = {
//   user: initialUserState,
//   restaurantForm: initialRestaurantFormState,
//   restaurant: initialRestaurantState,
//   userHome: initialUserHomeState,
//   loader: initialLoaderState,
//   restaurantUser: initialRestaurantUserState,
//   router: history,
// };

export const createRootReducer = (
  history: History,
): Reducer<ApplicationState, AnyAction> => {
  return combineReducers<ApplicationState>({
    user: userReducer,
    restaurantForm: reducer,
    restaurant: restaurantReducer,
    userHome: userHomeReducer,
    loader: loaderReducer,
    restaurantUser: restaurantUserReducer,
    router: connectRouter(history),
    errors: errorsReducer,
    pages: pagesReducer,
    auth: authReducer,
  });
};

export const configureStore = (): {
  store: Store<ApplicationState>;
  persistor: Persistor;
} => {
  const composeEnhancers = composeWithDevTools({});
  const rootSaga = new RootSaga(container);
  const sagaMiddleware = createSagaMiddleware();
  const rootReducer = createRootReducer(history);
  const persistConfig: PersistConfig<ApplicationState, any, any, any> = {
    key: 'root',
    storage,
    whitelist: ['user', 'restaurantUser', 'auth'],
  };
  const store = createStore(
    persistReducer(persistConfig, rootReducer),
    composeEnhancers(
      applyMiddleware(sagaMiddleware, routerMiddleware(history)),
    ),
  );
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga.sagas);

  return { store, persistor };
};

export const storePersistor = configureStore();
