import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { countries as countriesContent } from '../../content/countries';
import {
  cities as citiesContent,
  states as statesContent,
} from '../../content/countries/us';

export interface AddressResources {
  countries: string[];
  states: string[];
  cities: string[];
  selectedCountry?: string;
  selectedState?: string;
  selectedCity?: string;
  setSelectedCity: Dispatch<SetStateAction<string | undefined>>;
  setCountry: Dispatch<SetStateAction<string | undefined>>;
  setSelectedState: Dispatch<SetStateAction<string | undefined>>;
}

export const useAddress = (
  defaultCountry?: string,
  defaultState?: string,
  defaultCity?: string,
): AddressResources => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [countries, setCountries] = useState(countriesContent);
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [selectedCountry, setCountry] = useState<string>();
  const [selectedState, setSelectedState] = useState<string>();
  const [selectedCity, setSelectedCity] = useState<string>();

  useEffect(() => {
    if (defaultCountry) setCountry(defaultCountry);
    setCountry(countries[0]);
  }, [countries]);

  useEffect(() => {
    setStates(statesContent);
  }, [selectedCountry]);

  useEffect(() => {
    if (defaultState) setSelectedState(defaultState);
    else setSelectedState(statesContent[0] ?? '');
  }, [states]);

  useEffect(() => {
    setCities(citiesContent[selectedState ?? ''] ?? []);
  }, [selectedState]);

  useEffect(() => {
    if (defaultCity) setSelectedCity(defaultCity);
    else setSelectedCity(cities[0] ?? '');
  }, [cities]);

  return {
    countries,
    setCountry,
    states,
    cities,
    setSelectedState,
    selectedCountry,
    selectedState,
    setSelectedCity,
    selectedCity,
  };
};
