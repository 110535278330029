/* eslint-disable react/display-name */
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Fab, Grid, List, Tab, Tabs, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MenuItemListView from './MenuItemListView';
import { useHistory } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { MenuProps } from '../../../types/MenuProps';

function MobileMenu({
  handleOpenDialog,
  handleOpenItem,
}: MenuProps): JSX.Element {
  const {
    menu: { sections: sectionList, menuItems: items },
  } = useSelector((appState: ApplicationState) => appState.restaurant);
  const theme = useTheme();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const history = useHistory();

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        style={{
          color: 'white',
          backgroundColor: theme.palette.primary.main,
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {sectionList.map((title) => (
          <Tab key={`tab-${title}`} label={t(title, title)} />
        ))}
      </Tabs>
      <SwipeableViews
        axis="x"
        index={value}
        onChangeIndex={(index) => setValue(index)}
        containerStyle={{ height: '100%' }}
        slideStyle={{ height: '100%' }}
        style={{ height: '100%' }}
      >
        {sectionList.map((section, index) => {
          const sectionItems = items.filter(
            (item) => item.menuSection === section,
          );
          return (
            <Grid
              key={`list-${index}`}
              container
              hidden={value !== index}
              style={{ height: '100%' }}
            >
              <Grid item xs>
                <List>
                  {sectionItems.map((item) => (
                    <MenuItemListView
                      showDelete
                      handleOpenItem={handleOpenItem}
                      handleOpenDialog={handleOpenDialog}
                      key={item.id}
                      item={item}
                    />
                  ))}
                </List>
              </Grid>
            </Grid>
          );
        })}
      </SwipeableViews>
      <Fab
        size="small"
        color="secondary"
        aria-label="add menu item"
        onClick={() => history.push('/menu-item')}
        style={{
          right: 20,
          bottom: 20,

          position: 'fixed',
        }}
      >
        <Add />
      </Fab>
    </div>
  );
}

export default MobileMenu;
