import { Menu } from '../../types/Menu';
import { MenuItemSubmitForm } from '../../types/MenuItemForm';
import { Order } from '../../types/Order';
import { Restaurant } from '../../types/Restaurant';

export const GET_MENU = '@@restaurant/GET_MENU';

export const SAVE_MENU = '@@restaurant/SAVE_MENU';

export const SEND_CREATE_MENU = '@@restaurant/SEND_CREATE_MENU';

export const SUBMIT_CREATE_MENU = '@@restaurant/SUBMIT_CREATE_MENU';

export const UPDATE_SECTIONS = '@@restaurant/UPDATE_SECTIONS';

export const UPDATE_ITEMS = '@@restaurant/UPDATE_ITEMS';

export const SEND_MENU_ITEM = '@@restaurant/SEND_MENU_ITEM';

export const SUBMIT_MENU_ITEM = '@@restaurant/SUBMIT_MENU_ITEM';

export const SEND_DELETE_MENU_ITEM = '@@restaurant/SEND_DELETE_MENU_ITEM';

export const SUBMIT_DELETE_MENU_ITEM = '@@restaurant/SUBMIT_DELETE_MENU_ITEM';

export const LOAD_MENU_ITEM_FORM = '@@restaurant/LOAD_MENU_ITEM_FORM';

export const CLEAR_MENU_ITEM_FORM = '@@restaurant/CLEAR_MENU_ITEM_FORM';

export const UPDATE_RESTAURANT = '@@restaurant/UPDATE_RESTAURANT';

export const UPDATE_RESTAURANT_STATUS = '@@restaurant/UPDATE_RESTAURANT_STATUS';

export const UPDATE_RESTAURANT_LOCATION =
  '@@restaurant/UPDATE_RESTAURANT_LOCATION';

export const SAVE_RESTAURANT = '@@restaurant/SAVE_RESTAURANT';

export const GET_RESTAURANT = '@@restaurant/GET_RESTAURANT';

export const UPLOAD_PHOTO = '@@restaurant/UPLOAD_PHOTO';

export const UPDATE_PHOTOS = '@@restaurant/UPDATE_PHOTOS';

export const UPDATE_ORDER = '@@restaurant/UPDATE_ORDER';

export const UPDATE_ORDERS = '@@restaurant/UPDATE_ORDERS';

export const GET_ORDERS = '@@restaurant/GET_ORDERS';

export const SAVE_ORDERS = '@@restaurant/SAVE_ORDERS';

export const ADD_ORDER = '@@restaurant/ADD_ORDER';

export const GET_ORDER = '@@restaurant/GET_ORDER';

export interface OrdersState {
  pending: Order[];
  confirmed: Order[];
  past: Order[];
}

export interface RestaurantState extends Restaurant {
  menu: Menu;
  menuItemForm: MenuItemSubmitForm;
  orders?: OrdersState;
}
