import { AreaCodeOption } from './AreaCodeOption';
import { Category } from './Category';
import { Option } from './Option';
import { Schedule } from './Schedule';
import { WeekHours } from './WeekHours';

export interface RestaurantProfileData {
  name: string;
  description: string;
  categories?: Option[];
  phoneNumber?: string;
  phoneCountry?: AreaCodeOption;
  website?: string;
  facebook?: string;
  hours: Schedule[];
}

export interface RestaurantProfileDataPayload {
  name: string;
  description: string;
  categories?: Category[];
  phoneNumber?: string;
  phoneCountry?: string;
  website?: string;
  facebook?: string;
  hours: WeekHours;
}

export interface RestaurantProfileSubmittedData {
  name: string;
  description: string;
  categories?: Option[];
  phoneNumber?: string;
  phoneCountry?: AreaCodeOption;
  website?: string;
  facebook?: string;
  hours: RHFArrayElement<Schedule>[];
}

export interface RHFArrayElement<T> {
  value: T;
}

export const mapRHFObjectToObject = <T>(rhfObject: RHFArrayElement<T>): T =>
  rhfObject.value;
