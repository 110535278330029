import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGlobalStyles } from '../../../App';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { getRestaurant } from '../../../store/restaurant/actions';
import { AdminMenuButton } from './AdminHomeButton';

function AdminHome(): JSX.Element {
  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { restaurantCity, restaurantId } = useSelector(
    (appState: ApplicationState) => appState.restaurantUser,
  );

  useEffect(() => {
    dispatch(
      getRestaurant({
        id: restaurantId ?? '',
        city: restaurantCity ?? '',
      }),
    );
  }, []);

  const handleMenuClick = () => {
    history.push('/admin/menu');
  };

  const handleGalleryClick = () => {
    history.push('/admin/gallery');
  };

  const handleRestProfileClick = () => {
    history.push(`/admin/profile`);
  };
  return (
    <Grid
      container
      alignItems="flex-start"
      justify="center"
      className={globalClasses.container}
    >
      <Grid item xs={12} className={globalClasses.verticalSpacing3}>
        <Typography style={{ textAlign: 'center' }} variant="h2">
          {t('AdminHome.adminHome')}
        </Typography>
      </Grid>

      <AdminMenuButton
        icon="store"
        text={t('AdminHome.restManager') as string}
      />

      <AdminMenuButton
        icon="restaurant_menu"
        onClick={handleMenuClick}
        text={t('AdminHome.menu') as string}
      />

      <Grid item xs={12} />

      <AdminMenuButton
        icon="schedule"
        text={t('AdminHome.schedule') as string}
      />
      <AdminMenuButton
        icon="shopping_cart"
        text={t('AdminHome.orders') as string}
      />

      <Grid item xs={12} />

      <AdminMenuButton
        icon="build"
        text={t('AdminHome.floorConfiguration') as string}
      />

      <AdminMenuButton
        icon="event"
        text={t('AdminHome.reservations') as string}
      />
      <Grid item xs={12} />
      <AdminMenuButton
        icon="info"
        onClick={handleRestProfileClick}
        text={t('AdminHome.restaurantInfo') as string}
      />

      <AdminMenuButton
        onClick={handleGalleryClick}
        icon="photo_library"
        text={t('AdminHome.gallery') as string}
      />
    </Grid>
  );
}

export default AdminHome;
