import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import { Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { UserFormData } from '../../../../types/UserFormData';
import GoogleSignIn from '../GoogleSignIn';
import { FacebookSignIn } from '../FacebookSignIn';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userSignUp } from '../../../../store/user/actions';
import { useGlobalStyles } from '../../../../App';
import { ComboBoxRender } from '../../../../utils/rhf/Controllers';
import { areaCodes } from '../../../../constants';
import { Option } from '../../../../types/Option';
import { AutoCompleteProps } from '../../../../types/AutoCompleteProps';
import { resolver } from './resolver';
import {
  getOptionSelected,
  renderInput,
} from '../../../../utils/components/AutoCompleteUtils';
import { PasswordInput } from '../../../../utils/components/PasswordInput';
import { AreaCodeOption } from '../../../../types/AreaCodeOption';
import { getError } from '../../../../store/errors/selectors';
import { ApplicationError } from '../../../../types/Error';
import { clearError } from '../../../../store/errors/actions';

function SignUp(): JSX.Element {
  const emailConflictError = useSelector(getError('USER_EMAIL_CONFLICT'));
  const {
    handleSubmit,
    register,
    control,
    errors,
    getValues,
    setError,
  } = useForm<UserFormData>({
    mode: 'onBlur',
    resolver,
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data: UserFormData) => {
    if (emailConflictError)
      dispatch(clearError({ code: 'USER_EMAIL_CONFLICT' }));
    dispatch(userSignUp(data));
  };

  const onSignUpClick = () => {
    history.push('/restaurant-sign-up/create-account');
  };

  const { t } = useTranslation();
  const globalClasses = useGlobalStyles();

  const areaCodeOptions: AreaCodeOption[] = areaCodes.map(({ code, dial }) => ({
    code,
    dial,
    label: t(`CountryAreaCodes.${code}`) as string,
  }));

  const renderOption = (option: Option) => (
    <React.Fragment>{option.label}</React.Fragment>
  );

  const areaCodesProps: AutoCompleteProps<Option> = {
    id: 'code-select',
    options: areaCodeOptions,
    classes: {
      option: globalClasses.option,
    },
    size: 'small',
    getOptionLabel: (option: Option) => option.label,
    renderOption,
    getOptionSelected,
    renderInput: renderInput(
      t('SignUp.countryAreaCodeLabel') as string,
      errors.countryAreaCode?.code?.message,
      !!errors.countryAreaCode,
    ),
  };

  useEffect(() => {
    if (emailConflictError) {
      console.log('email conflict');
      setError('email', {
        message: t(
          `Errors.${(emailConflictError as ApplicationError).message}`,
          {
            email: getValues('email'),
          },
        ) as string,
      });
    }
  }, [emailConflictError]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={globalClasses.container}
    >
      <Grid item xs={12} sm={8} lg={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs className={globalClasses.horizontalSpacing1}>
              <TextField
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                fullWidth
                id="firstName"
                label={t('SignUp.firstNameLabel')}
                inputRef={register}
                name="firstName"
                size="small"
              />
            </Grid>

            <Grid item xs className={globalClasses.horizontalSpacing1}>
              <TextField
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                fullWidth
                name="lastName"
                id="lastName"
                label={t('SignUp.lastNameLabel')}
                inputRef={register}
                size="small"
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${globalClasses.verticalSpacing3} ${globalClasses.horizontalSpacing1}`}
            >
              <TextField
                error={!!errors.email}
                helperText={errors.email?.message}
                fullWidth
                id="email"
                name="email"
                type="email"
                label={t('SignUp.emailLabel')}
                inputRef={register}
                size="small"
              />
            </Grid>

            <Grid item xs={5} className={globalClasses.horizontalSpacing1}>
              <ComboBoxRender
                {...areaCodesProps}
                controllerprops={{
                  name: 'countryAreaCode',
                  control,
                  defaultValue: areaCodeOptions[0],
                }}
              />
            </Grid>

            <Grid item xs className={globalClasses.horizontalSpacing1}>
              <TextField
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                name="phoneNumber"
                inputRef={register}
                fullWidth
                id="phoneNumber"
                type="tel"
                label={t('SignUp.phoneNumber')}
                size="small"
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${globalClasses.verticalSpacing3} ${globalClasses.horizontalSpacing1}`}
            >
              <PasswordInput
                helperText={errors?.password?.message ?? ''}
                error={!!errors?.password}
                fullWidth
                id="password"
                name="password"
                label={t('SignUp.passwordLabel')}
                inputRef={register}
                size="small"
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${globalClasses.horizontalSpacing1}`}
            >
              <PasswordInput
                helperText={errors?.confirmPassword?.message ?? ''}
                error={!!errors?.confirmPassword}
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                label={t('SignUp.confirmPasswordLabel')}
                inputRef={register}
                size="small"
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={`${globalClasses.verticalSpacing3} ${globalClasses.horizontalSpacing1}`}
            >
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
              >
                {t('SignUp.signUp')}
              </Button>
            </Grid>

            <Grid
              style={{ textAlign: 'center' }}
              item
              xs={12}
              className={`${globalClasses.horizontalSpacing1}`}
            >
              <FacebookSignIn />
            </Grid>
            <Grid
              style={{ textAlign: 'center' }}
              item
              xs={12}
              className={`${globalClasses.verticalSpacing3} ${globalClasses.horizontalSpacing1}`}
            >
              <Grid item>
                <GoogleSignIn />
              </Grid>
              <Typography variant="caption">
                {t('SignUp.termsOfService')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${globalClasses.horizontalSpacing1}`}
            >
              <Button
                onClick={onSignUpClick}
                fullWidth
                color="primary"
                variant="contained"
              >
                {t('SignUp.signUpRestaurant')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default SignUp;
