import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { Credentials } from '../../types/Credentials';
import { IRestaurantUserService } from '../../types/IRestaurantUserService';
import { RestaurantUser } from '../../types/RestaurantUserFormData';
import { RestaurantUserLoginResponse } from '../../types/RestaurantUserLoginResponse';
import { authPost, post } from '../../utils/services/axiosWrappers';

const API_URL = process.env.REACT_APP_API_URL ?? '';

@injectable()
export class RestaurantUserService implements IRestaurantUserService {
  async createAccount(
    credentials: Credentials,
  ): Promise<AxiosResponse<RestaurantUserLoginResponse>> {
    try {
      console.log('payload:', credentials);
      return await post(
        `${API_URL}/jartera/auth/restaurant-sign-up`,
        credentials,
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async signIn(
    credentials: Credentials,
  ): Promise<AxiosResponse<RestaurantUserLoginResponse>> {
    try {
      console.log('payload:', credentials);
      return await post(
        `${API_URL}/jartera/auth/restaurant-sign-in`,
        credentials,
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async updateRestaurantUser(
    user: RestaurantUser,
  ): Promise<AxiosResponse<RestaurantUser>> {
    try {
      console.log('payload:', user);
      return await authPost(`${API_URL}/jartera/restaurantusers/update`, user);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const authService = new RestaurantUserService();
export default authService;
