import { createAction } from 'redux-ts-simple';
import {
  SEND_MENU_ITEM,
  SUBMIT_MENU_ITEM,
  SEND_DELETE_MENU_ITEM,
  SUBMIT_DELETE_MENU_ITEM,
  SEND_CREATE_MENU,
  SUBMIT_CREATE_MENU,
  UPDATE_SECTIONS,
  LOAD_MENU_ITEM_FORM,
  CLEAR_MENU_ITEM_FORM,
  GET_MENU,
  SAVE_MENU,
  UPDATE_ITEMS,
  SAVE_RESTAURANT,
  GET_RESTAURANT,
  UPDATE_RESTAURANT,
  UPDATE_RESTAURANT_STATUS,
  UPDATE_PHOTOS,
  UPLOAD_PHOTO,
  UPDATE_RESTAURANT_LOCATION,
} from './types';
import { MenuItem } from '../../types/MenuItem';
import { DeleteMenuItemPayload } from '../../types/DeleteMenuItemPayload';
import { Menu } from '../../types/Menu';
import { MenuSection } from '../../types/MenuItemList';
import { MenuItemSubmitForm } from '../../types/MenuItemForm';
import { Restaurant } from '../../types/Restaurant';
import { RestaurantProfileData } from '../../types/RestaurantProfileData';

export const saveMenu = createAction<Menu>(SAVE_MENU);

export const getMenu = createAction<{ restaurantId: string }>(GET_MENU);

export const sendMenuItem = createAction<MenuItem>(SEND_MENU_ITEM);

export const submitMenuItem = createAction<MenuItem>(SUBMIT_MENU_ITEM);

export const updateSections = createAction<MenuSection[]>(UPDATE_SECTIONS);

export const sendDeleteMenuItem = createAction<DeleteMenuItemPayload>(
  SEND_DELETE_MENU_ITEM,
);

export const updateItems = createAction<MenuItem[]>(UPDATE_ITEMS);

export const submitDeleteMenuItem = createAction<Menu>(SUBMIT_DELETE_MENU_ITEM);

export const sendCreateMenu = createAction<Menu>(SEND_CREATE_MENU);

export const submitCreateMenu = createAction<Menu>(SUBMIT_CREATE_MENU);

export const loadMenuItemForm = createAction<MenuItemSubmitForm>(
  LOAD_MENU_ITEM_FORM,
);

export const clearMenuItemForm = createAction(CLEAR_MENU_ITEM_FORM);

export const saveRestaurant = createAction<Restaurant>(SAVE_RESTAURANT);

export const updateRestaurant = createAction<RestaurantProfileData>(
  UPDATE_RESTAURANT,
);

export const updateRestaurantStatus = createAction<{ status: string }>(
  UPDATE_RESTAURANT_STATUS,
);

export const updateRestaurantLocation = createAction<{ latLng: string }>(
  UPDATE_RESTAURANT_LOCATION,
);

export const getRestaurant = createAction<{ id: string; city: string }>(
  GET_RESTAURANT,
);

export const updatePhotos = createAction<string[]>(UPDATE_PHOTOS);

export const uploadPhoto = createAction<string>(UPLOAD_PHOTO);
